/** @format */

import 'moment-timezone';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  UncontrolledButtonDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
} from 'reactstrap';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from '../utils/axiosInstance';
import * as types from '../static/_types';
import { PROPERTIES_HEADER, propertiesAllRowData } from '../common/ExportCSVData';
import {
  convertPrice,
  filterExportData,
  generateCSV,
  graanaApi,
  handleUnload,
  logPageView,
  setHeader,
} from '../common/actions';
import { CanAdd, CanEdit, CanView } from '../hoc/CanDo';
import {
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  PRIORITY_OPTIONS,
  PROPERTY_SUBTYPES,
} from '../static/_constants';
import SearchBar from '../components/SearchBar';
import Loading from '../components/Loading';

const rejectionMessage =
  'The selected reason and comments will be displayed to end user whose listing is being rejected.';
const loadingMsg = 'Loading Properties...';
const dateFormat = 'MMM DD, HH:mm';
const noMedia = ': No Media';
const withMedia = ': With Media';
const markedHidden = ': Marked Hidden';
const statusNonVerified = 'non-verified';
const mediaExecutiveOption = ': Media Done By Executive';
const propertiesEligibleForRewardOption = ': Properties Eligible For Reward';
const userEligibleForRewardOption = ': Users Eligible For Reward';
const mediaUploaderOption = ': Media done by Uploader';
const manuallyGeotagged = 'manually-geotagged';
const expiredManuallyGeotagged = 'expired-manually-geotagged';
const nonGeotagged = 'non-geotagged';
const propsureGeotagged = 'propsure-geotagged';
const expiredPropsureGeotagged = 'expired-propsure-geotagged';
const expiredGeotag = 'expired-geotagged';
const exclusiveListings = ': Exclusive Listings';
const graanaUrl = graanaApi();
class Properties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responseMessage: loadingMsg,
      loading: true,
      properties: [],
      activePage: 1,
      pages: 1,
      q: null,
      activeButton: 'pending',
      listingType: null,
      pageSize: DEFAULT_PAGE_SIZE,
      type: undefined,
      startDate: null,
      endDate: null,
      focusedInput: null,
      total: '',
      activePurpose: null,
      propertyType: null,
      propertyParams: [],
      priorityUpdated: false,
      colSearch: '',
      modalVisible: false,
      modalAiraLogs: [],
      dateStatus: false,
      rejectModal: false,
      rejectReason: '',
      subStatus: null,
      statusValue: 'pending',
      areagroup: {},
      areagroups: [],
      isLoadingAreagroup: true,
      bulkRejectModal: false,
      loadingStats: true,
      geotagButton: null,
      clearButton: false,
      setClearButton: 0,
    };
    this.dataToExport = [];

    this.endPoint = '/api/property';
    this.statsEndPoint = '/api/property/kpis';
    this.setPriorityEndPoint = '/api/property/set-property-priority';
    this.setStatusEndPoint = '/api/property/bulk-status-update';
    this.transferPropertyEndPoint = '/api/property/transfer';

    this.selectAllRecords = this.selectAllRecords.bind(this);
    this.selectRecord = this.selectRecord.bind(this);
    this.savePriority = this.savePriority.bind(this);
    this.saveStatus = this.saveStatus.bind(this);
    this.transferProperty = this.transferProperty.bind(this);
    this.handlePageSelect = this.handlePageSelect.bind(this);
    this.handleReasonInput = this.handleReasonInput.bind(this);
    this.handleReasonButton = this.handleReasonButton.bind(this);
    this.handleRejection = this.handleRejection.bind(this);
    this.handleBulkRejection = this.handleBulkRejection.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleDateFilter = this.handleDateFilter.bind(this);
    this.getCSV = this.getCSV.bind(this);
    this.getCurrentCSV = this.getCurrentCSV.bind(this);
    this.changePurpose = this.changePurpose.bind(this);
    this.verifyGeotag = this.verifyGeotag.bind(this);
    this.renderStats = this.renderStats.bind(this);
  }

  componentDidMount() {
    this.fetchProperties();
    axios
      .get('/api/zone/subzones', {
        params: {
          all: true,
          active: true,
        },
      })
      .then((response) => {
        this.setState({ areagroups: response.data.items, isLoadingAreagroup: false }, () => {
          let { areagroups } = this.state;
          areagroups = areagroups.map((areagroup) => ({
            ...areagroup,
            info: `${areagroup.name} (ID: ${areagroup.id})`,
          }));
          this.setState({ areagroups, isLoadingAreagroup: false });
        });
      });
    this.fetchStats();
  }

  handlePageSelect(page) {
    this.setState(
      {
        activePage: page.selected + 1,
        loading: true,
        properties: [],
        responseMessage: loadingMsg,
      },
      () => {
        this.fetchProperties();
      }
    );
  }

  handlePageSize(event) {
    this.setState(
      {
        pageSize: event.target.value,
        activePage: 1,
        properties: [],
        loading: true,
        responseMessage: loadingMsg,
      },
      () => {
        this.fetchProperties();
      }
    );
  }

  handleReasonInput(event) {
    const { value } = event.target;
    let { rejectReason } = this.state;
    rejectReason = value;
    this.setState({ rejectReason });
  }

  handleReasonButton(event) {
    let { subStatus } = this.state;
    subStatus = event.target.name;
    this.setState({ subStatus });
  }

  handleRefresh(status) {
    this.setState(
      {
        loading: true,
        properties: [],
        activeButton: status,
        responseMessage: loadingMsg,
        activePage: 1,
        refresh: true,
      },
      () => {
        this.fetchProperties();
      }
    );
  }

  handleDateFilter(event) {
    this.setState({
      colSearch: event && event.target ? event.target.value : null,
    });
  }

  handleSearchType(value) {
    const { setClearButton, clearButton, q } = this.state;
    this.setState(
      {
        type: value ? value.value : null,
        q: '',
        clearButton: false,
      },
      () => {
        if (!value) {
          if (clearButton || (setClearButton === 2 && !q)) {
            this.setState({
              loading: true,
              properties: [],
              activePage: 1,
              responseMessage: loadingMsg,
            });
            this.fetchProperties();
          }
        }
      }
    );
  }

  handleSearch() {
    const { q, type, startDate, endDate, activeButton } = this.state;
    if (startDate || endDate) {
      this.setState({ dateStatus: true });
    } else {
      this.setState({ dateStatus: false });
    }
    if (activeButton === 'refresh') {
      if ((q && type) || startDate || endDate) {
        this.setState(
          {
            loading: true,
            activePage: 1,
            properties: [],
            responseMessage: loadingMsg,
          },
          () => {
            this.fetchProperties();
          }
        );
      }
    } else if ((q && type) || startDate || endDate) {
      this.setState(
        {
          loading: true,
          activePage: 1,
          properties: [],
          responseMessage: loadingMsg,
          geotagMsg: true,
        },
        () => {
          this.fetchProperties();
        }
      );
    } else if (!q && !type) {
      this.setState(
        {
          type: null,
          loading: true,
          activePage: 1,
          properties: [],
          responseMessage: loadingMsg,
          geotagMsg: false,
        },
        () => {
          this.fetchProperties();
        }
      );
    }
    if (q && type) {
      this.setState({ clearButton: true });
    }
    if (!q && !type && (startDate || endDate)) {
      this.setState({ clearButton: false, setClearButton: 0 });
    } else {
      this.setState({ setClearButton: 2 });
    }
  }

  handleBulkRejection(event) {
    event.preventDefault();
    const { selectedStatus, properties, subStatus, rejectReason } = this.state;
    let propertiesIds = properties.map((prop) => (prop.selected ? prop.id : null));
    propertiesIds = propertiesIds.filter((prop) => !!prop);
    this.setState({ loading: true, rejectModal: false });
    axios
      .post(this.setStatusEndPoint, {
        status: selectedStatus,
        propertyIds: propertiesIds,
        status_reason: rejectReason,
        substatus: subStatus,
      })
      .then((response) => {
        if (typeof response.data === 'string') {
          window.alert(response.data);
        } else {
          this.setState({
            loading: false,
            rejectModal: false,
            bulkRejectModal: false,
            rejectReason: '',
            subStatus: null,
            selectedStatus: undefined,
            selectedBulkAction: undefined,
          });
          window.alert(rejectionMessage);
          this.fetchProperties();
        }
      });
  }

  handleRejection(event) {
    event.preventDefault();
    const { rejectReason, rejectStatus, subStatus, rejectId } = this.state;
    this.setState({ loading: true });
    this.setState({ rejectModal: false });
    axios
      .post(`/api/property/${rejectId}/status`, {
        status: rejectStatus,
        status_reason: rejectReason,
        substatus: subStatus,
      })
      .then((response) => {
        if (typeof response.data === 'string') {
          window.alert(response.data);
        } else {
          this.setState({ loading: false });
          this.setState({ rejectModal: false, rejectReason: '', subStatus: null });
          window.alert(rejectionMessage);
          this.fetchProperties();
        }
      });
  }

  handleRefreshStatus(propertyId) {
    this.setState({ loading: true });
    axios.put(`/api/property/refresh/${propertyId}`).then((response) => {
      if (typeof response.data === 'string') {
        alert(response.data);
      } else {
        this.setState({ loading: false });
      }
      this.fetchProperties();
      this.setState({ loadingStats: true });
      this.fetchStats();
    });
  }

  handleStatus(status, propertyId) {
    const { rejectReason } = this.state;
    this.setState({ loading: true });
    axios
      .post(`/api/property/${propertyId}/status`, {
        status,
        status_reason: status === 'rejected' ? rejectReason : null,
      })
      .then((response) => {
        if (response.data === 'Area has to be active before publishing the property') {
          alert(response.data);
        } else if (typeof response.data === 'string') {
          alert(response.data);
        } else {
          this.setState({ rejectModal: false });
          if (status === 'rejected') {
            window.alert(rejectionMessage);
          }
          this.fetchProperties();
        }
        this.setState({ loading: false });
      });
  }

  handleCataloging(propertyId) {
    const propertyIds = [propertyId];
    this.setState({ loading: true });
    axios.post('/api/property/update-catalogue', { propertyIds }).then(() => {
      this.fetchProperties();
    });
  }

  handleReject(status, propertyId) {
    this.setState({ rejectStatus: status, rejectId: propertyId, rejectModal: true });
  }

  handleFilter(status) {
    const { activePurpose, startDate, endDate, colSearch, statusValue } = this.state;
    if (status === 'outdated' && activePurpose !== 'rent') {
      this.setState(
        {
          loading: true,
          properties: [],
          responseMessage: loadingMsg,
          activeButton: status,
          activePage: 1,
          activePurpose: 'sale',
          expired: false,
          colSearch,
          refresh: null,
        },
        () => {
          this.fetchProperties();
        }
      );
    } else if (status === 'onhold') {
      this.setState(
        {
          loading: true,
          properties: [],
          responseMessage: loadingMsg,
          activeButton: status,
          activePage: 1,
          expired: false,
          activePurpose: null,
          colSearch: startDate || endDate ? colSearch : null,
          refresh: null,
          propertyButton: null,
        },
        () => {
          this.fetchProperties();
        }
      );
    } else if (statusValue !== 'rejected') {
      this.setState(
        {
          loading: true,
          properties: [],
          responseMessage: loadingMsg,
          activeButton: status,
          activePage: 1,
          activeReason: null,
          expired: false,
          activePurpose: null,
          colSearch: startDate || endDate ? colSearch : null,
          refresh: null,
        },
        () => {
          this.fetchProperties();
        }
      );
    } else {
      this.setState(
        {
          loading: true,
          properties: [],
          responseMessage: loadingMsg,
          activeButton: status,
          activePage: 1,
          expired: false,
          activePurpose: null,
          colSearch: startDate || endDate ? colSearch : null,
          refresh: null,
        },
        () => {
          this.fetchProperties();
        }
      );
    }
  }

  handleReason(reason) {
    const { activeReason } = this.state;
    if (reason) {
      this.setState(
        {
          activeReason: reason,
          loading: true,
          properties: [],
          responseMessage: loadingMsg,
          activePage: 1,
        },
        () => {
          axios.get(this.endPoint, this.getParams()).then((response) => {
            this.saveResponseToState(response);
          });
        }
      );
    } else if (activeReason) {
      this.setState(
        {
          activeReason: null,
          loading: true,
          properties: [],
          responseMessage: loadingMsg,
          activePage: 1,
        },
        () => {
          this.fetchProperties();
        }
      );
    }
  }

  onDatesChange(startDate, endDate) {
    this.setState({
      startDate,
      endDate,
    });
  }

  setAreagroup(areagroup) {
    this.setState({
      areagroup: {
        id: areagroup ? areagroup.id : null,
        name: areagroup ? areagroup.name : '',
      },
      q: areagroup ? areagroup.id : null,
      clearButton: false,
    });
  }

  getGeotagFilter() {
    const { geotagButton } = this.state;
    if (geotagButton === 'geotagging') {
      return ' and Geotagged';
    }
    if (geotagButton === nonGeotagged) {
      return ' and not Geotagged';
    }
    if (geotagButton === propsureGeotagged) {
      return ' and PropSure Geotagged';
    }
    if (geotagButton === manuallyGeotagged) {
      return ' and manually Geotagged';
    }
    if (geotagButton === expiredGeotag) {
      return ' and having Geotag expired';
    }
    if (geotagButton === expiredPropsureGeotagged) {
      return ' and having PropSure Geotag expired';
    }
    if (geotagButton === expiredManuallyGeotagged) {
      return ' and having manual Geotag expired';
    }
    return '';
  }

  getCSV() {
    const { startDate, endDate, loadingBigData } = this.state;
    let tempParams = this.getParams();
    tempParams = tempParams.params;
    const allParams = {};
    allParams.all = true;
    allParams.startDate = tempParams.startDate;
    allParams.endDate = tempParams.endDate;
    allParams.status = tempParams.status;
    if (tempParams.noImage) {
      allParams.noImage = tempParams.noImage;
    }
    if (!loadingBigData) {
      this.setState({ loadingBigData: true });
      axios
        .get(this.endPoint, {
          params: {
            all: true,
            startDate: moment(startDate).toISOString(),
            endDate: moment(endDate).toISOString(),
            ...allParams,
          },
        })
        .then((response) => {
          const properties = response.data.items;
          const data = [];
          if (properties && properties.length) {
            properties.map((property, index) => {
              let propertyImagesNames = '';
              const features = JSON.parse(property.features || '{}');
              const images = property.property_images;
              if (images && images.length) {
                images.map((image) => {
                  if (image.url) {
                    const arrayLength = image.url.split('/'); // returns length of array
                    const imageNameExtension = image.url.split('/')[arrayLength.length - 1]; // return name with extension
                    const imgName = imageNameExtension.split('.');
                    const imageName = imgName[0]; // returns name only without extension
                    propertyImagesNames =
                      propertyImagesNames === ''
                        ? `${imageName}`
                        : `${propertyImagesNames};${imageName}`;
                    return imageName;
                  }
                  return 'nullFileError';
                });
              }
              let rowData = [];
              rowData = propertiesAllRowData(property, index, features, propertyImagesNames);
              data.push(rowData);
              return rowData;
            });
          }
          let dataToExport = [PROPERTIES_HEADER, ...data];
          dataToExport = filterExportData(dataToExport);
          generateCSV('Properties', dataToExport);
          this.setState({ loadingBigData: false });
        });
    }
  }

  getCurrentCSV() {
    const { loadingBigData } = this.state;
    if (!loadingBigData) {
      this.setState({ loadingBigData: true });
      let dataToExport = [PROPERTIES_HEADER, ...this.dataToExport];
      dataToExport = filterExportData(dataToExport);
      generateCSV('Properties', dataToExport);
      this.setState({ loadingBigData: false });
    }
  }

  getPrevParams() {
    const { propertyParams } = this.props;
    const { history } = this.props;
    if (history.location.state) {
      history.location.state.message = '';
    }
    let statusValue;
    if (propertyParams) {
      if (
        propertyParams.activeButton === 'noImage' ||
        propertyParams.activeButton === 'imageP' ||
        propertyParams.activeButton === 'outdated' ||
        propertyParams.activeButton === 'propsure' ||
        propertyParams.activeButton === 'locate_manually' ||
        propertyParams.activeButton === 'armsPublished' ||
        propertyParams.activeButton === 'expired' ||
        propertyParams.activeButton === 'armsExpired' ||
        propertyParams.activeButton === 'publishedUnpublished' ||
        propertyParams.activeButton === 'publishedMediaDonebyUploader' ||
        propertyParams.activeButton === 'publishedMediaDonebyExecutive' ||
        propertyParams.activeButton === 'expiredMediaDonebyExecutive' ||
        propertyParams.activeButton === 'publishedPropertiesEligibleForReward' ||
        propertyParams.activeButton === 'publishedUsersEligibleForReward' ||
        propertyParams.activeButton === 'publishedExclusiveListings'
      ) {
        statusValue = 'published';
      } else if (
        propertyParams.activeButton === 'no-image' ||
        propertyParams.activeButton === 'image' ||
        propertyParams.activeButton === 'armsPending' ||
        propertyParams.activeButton === 'pendingGeotagged' ||
        propertyParams.activeButton === 'pendingManualGeotagged' ||
        propertyParams.activeButton === 'pendingUnpublished' ||
        propertyParams.activeButton === 'pendingMediaDonebyUploader' ||
        propertyParams.activeButton === 'pendingMediaDonebyExecutive' ||
        propertyParams.activeButton === 'pendingPropertiesEligibleForReward' ||
        propertyParams.activeButton === 'pendingUsersEligibleForReward' ||
        propertyParams.activeButton === 'pendingCatalogingDone' ||
        propertyParams.activeButton === 'pendingExclusiveListings'
      ) {
        statusValue = 'pending';
      } else if (
        propertyParams.activeButton === 'noImageEdited' ||
        propertyParams.activeButton === 'hasImageEdited' ||
        propertyParams.activeButton === 'armsEdited' ||
        propertyParams.activeButton === 'editedGeotagged' ||
        propertyParams.activeButton === 'editedManualGeotagged' ||
        propertyParams.activeButton === 'editedUnpublished' ||
        propertyParams.activeButton === 'editedMediaDonebyUploader' ||
        propertyParams.activeButton === 'editedMediaDonebyExecutive' ||
        propertyParams.activeButton === 'editedPropertiesEligibleForReward' ||
        propertyParams.activeButton === 'editedUsersEligibleForReward' ||
        propertyParams.activeButton === 'editedExclusiveListings'
      ) {
        statusValue = 'edited';
      } else if (
        propertyParams.activeButton === 'noImageRejected' ||
        propertyParams.activeButton === 'hasImageRejected' ||
        propertyParams.activeButton === 'armsRejected' ||
        propertyParams.activeButton === 'rejectedGeotagged' ||
        propertyParams.activeButton === 'rejectedManualGeotagged' ||
        propertyParams.activeButton === 'rejectedUnpublished' ||
        propertyParams.activeButton === 'rejectedMediaDonebyUploader' ||
        propertyParams.activeButton === 'rejectedMediaDonebyExecutive' ||
        propertyParams.activeButton === 'rejectedPropertiesEligibleForReward' ||
        propertyParams.activeButton === 'rejectedUsersEligibleForReward' ||
        propertyParams.activeButton === 'rejectedExclusiveListings'
      ) {
        statusValue = 'rejected';
      } else if (
        propertyParams.activeButton === 'noImageClosed' ||
        propertyParams.activeButton === 'hasImageClosed' ||
        propertyParams.activeButton === 'propSure' ||
        propertyParams.activeButton === 'armsClosed' ||
        propertyParams.activeButton === 'locateManuallyClosed' ||
        propertyParams.activeButton === 'closedUnpublished' ||
        propertyParams.activeButton === 'closedMediaDonebyUploader' ||
        propertyParams.activeButton === 'closedMediaDonebyExecutive' ||
        propertyParams.activeButton === 'closedPropertiesEligibleForReward' ||
        propertyParams.activeButton === 'closedUsersEligibleForReward' ||
        propertyParams.activeButton === 'closedExclusiveListings'
      ) {
        statusValue = 'closed';
      } else if (
        propertyParams.activeButton === 'armsRented' ||
        propertyParams.activeButton === 'rentedGeotagged' ||
        propertyParams.activeButton === 'rentedManualGeotagged' ||
        propertyParams.activeButton === 'rentedUnpublished' ||
        propertyParams.activeButton === 'rentedMediaDonebyUploader' ||
        propertyParams.activeButton === 'rentedMediaDonebyExecutive' ||
        propertyParams.activeButton === 'rentedPropertiesEligibleForReward' ||
        propertyParams.activeButton === 'rentedUsersEligibleForReward' ||
        propertyParams.activeButton === 'rentedExclusiveListings'
      ) {
        statusValue = 'rented';
      } else if (
        propertyParams.activeButton === 'armsSold' ||
        propertyParams.activeButton === 'soldGeotagged' ||
        propertyParams.activeButton === 'soldManualGeotagged' ||
        propertyParams.activeButton === 'soldUnpublished' ||
        propertyParams.activeButton === 'soldMediaDonebyUploader' ||
        propertyParams.activeButton === 'soldMediaDonebyExecutive' ||
        propertyParams.activeButton === 'soldPropertiesEligibleForReward' ||
        propertyParams.activeButton === 'soldUsersEligibleForReward' ||
        propertyParams.activeButton === 'soldExclusiveListings'
      ) {
        statusValue = 'sold';
      } else if (
        propertyParams.activeButton === 'armsOnHold' ||
        propertyParams.activeButton === 'onholdGeotagged' ||
        propertyParams.activeButton === 'onholdManualGeotagged' ||
        propertyParams.activeButton === 'onholdUnpublished' ||
        propertyParams.activeButton === 'onholdMediaDonebyUploader' ||
        propertyParams.activeButton === 'onholdMediaDonebyExecutive' ||
        propertyParams.activeButton === 'onholdPropertiesEligibleForReward' ||
        propertyParams.activeButton === 'onholdUsersEligibleForReward' ||
        propertyParams.activeButton === 'onholdExclusiveListings'
      ) {
        statusValue = 'onhold';
      } else {
        statusValue = propertyParams.activeButton;
      }
      if (propertyParams.activeButton === 'outdated' && propertyParams.activePurpose !== 'rent') {
        propertyParams.activePurpose = 'sale';
      }
      if (propertyParams.activeButton === 'refresh') {
        statusValue = 'published';
      }
      this.setState({
        activePage: propertyParams.activePage,
        pageSize: propertyParams.pageSize,
        activeButton: propertyParams.activeButton,
        geotagButton: propertyParams.geotagButton,
        type: propertyParams.type,
        q: propertyParams.q,
        listingType: propertyParams.listingType,
        agencyType: propertyParams.agencyType,
        startDate: propertyParams.startDate,
        endDate: propertyParams.endDate,
        activePurpose: propertyParams.activePurpose,
        propertyType: propertyParams.propertyType,
        propertySubtype: propertyParams.propertySubtype,
        expired: propertyParams.expired,
        colSearch: propertyParams.colSearch,
        refresh: propertyParams.refresh,
        showFilters: propertyParams.showFilters,
        adminUploader: propertyParams.adminUploader,
        userEligibleForReward: propertyParams.userEligibleForReward,
        propertiesEligibleForReward: propertyParams.propertiesEligibleForReward,
        activeReason: propertyParams.activeReason,
        substatus: propertyParams.activeReason,
        statusValue,
        geotagPlatform: propertyParams.geotagPlatform,
        zone: {
          id: propertyParams.zoneId,
        },
        areagroup: {
          id: propertyParams.areagroupId,
        },
        clearButton: propertyParams.clearButton,
      });
      return {
        params: {
          outdated: propertyParams.activeButton === 'outdated' ? true : null,
          catalogingDone: propertyParams.activeButton === 'pendingCatalogingDone' ? true : null,
          expired:
            propertyParams.activeButton === 'expired' ||
            propertyParams.activeButton === 'armsExpired' ||
            propertyParams.activeButton === 'expiredGeotagged' ||
            propertyParams.activeButton === 'expiredManualGeotagged' ||
            propertyParams.activeButton === 'expiredMediaDonebyUploader' ||
            propertyParams.activeButton === 'expiredMediaDonebyExecutive'
              ? true
              : null,
          page: propertyParams.activePage,
          pageSize: propertyParams.pageSize,
          status:
            !propertyParams.expired &&
            propertyParams.activeButton !== 'expiredGeotagged' &&
            propertyParams.activeButton !== 'expiredManualGeotagged'
              ? statusValue
              : 'published',
          q: propertyParams.type && propertyParams.clearButton ? propertyParams.q : null,
          searchby: propertyParams.q ? propertyParams.type : null,
          listing_type:
            propertyParams.listingType === 'noAgency' ||
            propertyParams.listingType === 'agency' ||
            propertyParams.expired
              ? null
              : propertyParams.listingType,
          colSearch: propertyParams.colSearch === 'status' ? propertyParams.colSearch : null,
          startDate: moment(propertyParams.startDate).toISOString(),
          endDate: moment(propertyParams.endDate).toISOString(),
          noImage:
            propertyParams.activeButton === 'noImage' ||
            propertyParams.activeButton === 'no-image' ||
            propertyParams.activeButton === 'noImageEdited' ||
            propertyParams.activeButton === 'noImageRejected' ||
            propertyParams.activeButton === 'noImageClosed'
              ? true
              : null,
          hasImage:
            propertyParams.activeButton === 'imageP' ||
            propertyParams.activeButton === 'image' ||
            propertyParams.activeButton === 'hasImageEdited' ||
            propertyParams.activeButton === 'hasImageRejected' ||
            propertyParams.activeButton === 'hasImageClosed'
              ? true
              : null,
          arms:
            propertyParams.activeButton === 'armsPending' ||
            propertyParams.activeButton === 'armsPublished' ||
            propertyParams.activeButton === 'armsRejected' ||
            propertyParams.activeButton === 'armsClosed' ||
            propertyParams.activeButton === 'armsExpired' ||
            propertyParams.activeButton === 'armsEdited' ||
            propertyParams.activeButton === 'armsRented' ||
            propertyParams.activeButton === 'armsSold' ||
            propertyParams.activeButton === 'armsOnHold'
              ? true
              : null,
          noAgency:
            propertyParams.listingType === 'noAgency' || propertyParams.agencyType === 'noAgency'
              ? true
              : null,
          agency:
            propertyParams.listingType === 'agency' || propertyParams.agencyType === 'agency'
              ? true
              : null,
          purpose: propertyParams.activePurpose,
          type: propertyParams.propertyType,
          subtype: propertyParams.propertySubtype,
          verified: propertyParams.geotagButton === 'geotagging' ? true : null,
          locateManually:
            propertyParams.geotagButton === manuallyGeotagged ||
            propertyParams.geotagButton === expiredManuallyGeotagged
              ? true
              : null,
          unVerified: propertyParams.geotagButton === nonGeotagged ? true : null,
          propsureTagged:
            propertyParams.geotagButton === propsureGeotagged ||
            propertyParams.geotagButton === expiredPropsureGeotagged
              ? true
              : null,
          expiredGeotag:
            propertyParams.geotagButton === expiredGeotag ||
            propertyParams.geotagButton === expiredPropsureGeotagged ||
            propertyParams.geotagButton === expiredManuallyGeotagged
              ? true
              : null,
          client: propertyParams.propertyButton === 'client' ? true : null,
          myProperties: propertyParams.propertyButton === 'systemUser' ? true : null,
          rider: propertyParams.propertyButton === 'rider' ? true : null,
          wanted: propertyParams.propertyButton === 'wanted' ? true : null,
          refresh: propertyParams.refresh,
          unpublished:
            propertyParams.activeButton === 'publishedUnpublished' ||
            propertyParams.activeButton === 'closedUnpublished' ||
            propertyParams.activeButton === 'pendingUnpublished' ||
            propertyParams.activeButton === 'rejectedUnpublished' ||
            propertyParams.activeButton === 'expiredUnpublished' ||
            propertyParams.activeButton === 'editedUnpublished' ||
            propertyParams.activeButton === 'rentedUnpublished' ||
            propertyParams.activeButton === 'soldUnpublished' ||
            propertyParams.activeButton === 'onholdUnpublished'
              ? true
              : null,
          adminUploader:
            propertyParams.activeButton === 'soldMediaDonebyUploader' ||
            propertyParams.activeButton === 'pendingMediaDonebyUploader' ||
            propertyParams.activeButton === 'publishedMediaDonebyUploader' ||
            propertyParams.activeButton === 'editedMediaDonebyUploader' ||
            propertyParams.activeButton === 'rentedMediaDonebyUploader' ||
            propertyParams.activeButton === 'expiredMediaDonebyUploader' ||
            propertyParams.activeButton === 'closedMediaDonebyUploader' ||
            propertyParams.activeButton === 'rejectedMediaDonebyUploader' ||
            propertyParams.activeButton === 'onholdMediaDonebyUploader'
              ? true
              : null,
          mediaExecutive:
            propertyParams.activeButton === 'soldMediaDonebyExecutive' ||
            propertyParams.activeButton === 'pendingMediaDonebyExecutive' ||
            propertyParams.activeButton === 'publishedMediaDonebyExecutive' ||
            propertyParams.activeButton === 'editedMediaDonebyExecutive' ||
            propertyParams.activeButton === 'rentedMediaDonebyExecutive' ||
            propertyParams.activeButton === 'expiredMediaDonebyExecutive' ||
            propertyParams.activeButton === 'closedMediaDonebyExecutive' ||
            propertyParams.activeButton === 'rejectedMediaDonebyExecutive' ||
            propertyParams.activeButton === 'onholdMediaDonebyExecutive'
              ? true
              : null,
          propertiesEligibleForReward:
            propertyParams.activeButton === 'soldPropertiesEligibleForReward' ||
            propertyParams.activeButton === 'pendingPropertiesEligibleForReward' ||
            propertyParams.activeButton === 'publishedPropertiesEligibleForReward' ||
            propertyParams.activeButton === 'editedPropertiesEligibleForReward' ||
            propertyParams.activeButton === 'rentedPropertiesEligibleForReward' ||
            propertyParams.activeButton === 'expiredPropertiesEligibleForReward' ||
            propertyParams.activeButton === 'closedPropertiesEligibleForReward' ||
            propertyParams.activeButton === 'rejectedPropertiesEligibleForReward' ||
            propertyParams.activeButton === 'onholdPropertiesEligibleForReward'
              ? true
              : null,
          userEligibleForReward:
            propertyParams.activeButton === 'soldUsersEligibleForReward' ||
            propertyParams.activeButton === 'pendingUsersEligibleForReward' ||
            propertyParams.activeButton === 'publishedUsersEligibleForReward' ||
            propertyParams.activeButton === 'editedUsersEligibleForReward' ||
            propertyParams.activeButton === 'rentedUsersEligibleForReward' ||
            propertyParams.activeButton === 'expiredUsersEligibleForReward' ||
            propertyParams.activeButton === 'closedUsersEligibleForReward' ||
            propertyParams.activeButton === 'rejectedUsersEligibleForReward' ||
            propertyParams.activeButton === 'onholdUsersEligibleForReward'
              ? true
              : null,
          exclusiveListings:
            propertyParams.activeButton === 'soldExclusiveListings' ||
            propertyParams.activeButton === 'pendingExclusiveListings' ||
            propertyParams.activeButton === 'publishedExclusiveListings' ||
            propertyParams.activeButton === 'editedExclusiveListings' ||
            propertyParams.activeButton === 'rentedExclusiveListings' ||
            propertyParams.activeButton === 'expiredExclusiveListings' ||
            propertyParams.activeButton === 'closedExclusiveListings' ||
            propertyParams.activeButton === 'rejectedExclusiveListings' ||
            propertyParams.activeButton === 'onholdExclusiveListings'
              ? true
              : null,
          substatus: statusValue === 'rejected' ? propertyParams.activeReason : null,
        },
      };
    }
    return propertyParams;
  }

  getParams() {
    const {
      activePage,
      pageSize,
      activeButton,
      activeReason,
      substatus,
      type,
      q,
      propertiesEligibleForReward,
      userEligibleForReward,
      adminUploader,
      listingType,
      agencyType,
      startDate,
      endDate,
      propertyType,
      propertySubtype,
      expired,
      colSearch,
      propertyButton,
      refresh,
      showFilters,
      geotagButton,
      geotagPlatform,
      zone,
      areagroup,
      clearButton,
    } = this.state;
    let { activePurpose } = this.state;
    this.setState(
      {
        propertyParams: {
          activePage,
          pageSize,
          activeButton,
          activeReason,
          type,
          q,
          listingType,
          substatus,
          agencyType,
          startDate,
          propertyType,
          adminUploader,
          propertiesEligibleForReward,
          userEligibleForReward,
          propertySubtype,
          expired,
          colSearch,
          propertyButton,
          refresh,
          endDate,
          activePurpose,
          showFilters,
          geotagButton,
          geotagPlatform,
          zoneId: zone ? zone.id : null,
          areagroupId: areagroup ? areagroup.id : null,
          clearButton,
        },
      },
      () => {
        const { dispatch } = this.props;
        const { propertyParams } = this.state;
        dispatch({
          type: types.SET_PROPERTY,
          payload: propertyParams,
        });
      }
    );
    let statusValue;
    if (
      activeButton === 'noImage' ||
      activeButton === 'imageP' ||
      activeButton === 'outdated' ||
      activeButton === 'propsure' ||
      activeButton === 'locate_manually' ||
      activeButton === 'armsPublished' ||
      activeButton === 'expired' ||
      activeButton === 'armsExpired' ||
      activeButton === 'publishedUnpublished' ||
      activeButton === 'publishedMediaDonebyUploader' ||
      activeButton === 'publishedMediaDonebyExecutive' ||
      activeButton === 'expiredMediaDonebyExecutive' ||
      activeButton === 'expiredMediaDonebyUploader' ||
      activeButton === 'publishedPropertiesEligibleForReward' ||
      activeButton === 'publishedUsersEligibleForReward' ||
      activeButton === 'publishedExclusiveListings'
    ) {
      statusValue = 'published';
    } else if (
      activeButton === 'no-image' ||
      activeButton === 'image' ||
      activeButton === 'armsPending' ||
      activeButton === 'pendingGeotagged' ||
      activeButton === 'pendingManualGeotagged' ||
      activeButton === 'pendingUnpublished' ||
      activeButton === 'pendingMediaDonebyUploader' ||
      activeButton === 'pendingMediaDonebyExecutive' ||
      activeButton === 'pendingPropertiesEligibleForReward' ||
      activeButton === 'pendingUsersEligibleForReward' ||
      activeButton === 'pendingCatalogingDone' ||
      activeButton === 'pendingExclusiveListings'
    ) {
      statusValue = 'pending';
    } else if (
      activeButton === 'noImageEdited' ||
      activeButton === 'hasImageEdited' ||
      activeButton === 'armsEdited' ||
      activeButton === 'editedGeotagged' ||
      activeButton === 'editedManualGeotagged' ||
      activeButton === 'editedUnpublished' ||
      activeButton === 'editedMediaDonebyUploader' ||
      activeButton === 'editedMediaDonebyExecutive' ||
      activeButton === 'editedPropertiesEligibleForReward' ||
      activeButton === 'editedUsersEligibleForReward' ||
      activeButton === 'editedExclusiveListings'
    ) {
      statusValue = 'edited';
    } else if (
      activeButton === 'noImageRejected' ||
      activeButton === 'hasImageRejected' ||
      activeButton === 'armsRejected' ||
      activeButton === 'rejectedGeotagged' ||
      activeButton === 'rejectedManualGeotagged' ||
      activeButton === 'rejectedUnpublished' ||
      activeButton === 'rejectedMediaDonebyUploader' ||
      activeButton === 'rejectedMediaDonebyExecutive' ||
      activeButton === 'rejectedPropertiesEligibleForReward' ||
      activeButton === 'rejectedUsersEligibleForReward' ||
      activeButton === 'rejectedExclusiveListings'
    ) {
      statusValue = 'rejected';
    } else if (
      activeButton === 'noImageClosed' ||
      activeButton === 'hasImageClosed' ||
      activeButton === 'propSure' ||
      activeButton === 'armsClosed' ||
      activeButton === 'locateManuallyClosed' ||
      activeButton === 'closedUnpublished' ||
      activeButton === 'closedMediaDonebyUploader' ||
      activeButton === 'closedMediaDonebyExecutive' ||
      activeButton === 'closedPropertiesEligibleForReward' ||
      activeButton === 'closedUsersEligibleForReward' ||
      activeButton === 'closedExclusiveListings'
    ) {
      statusValue = 'closed';
    } else if (
      activeButton === 'armsRented' ||
      activeButton === 'rentedGeotagged' ||
      activeButton === 'rentedManualGeotagged' ||
      activeButton === 'rentedUnpublished' ||
      activeButton === 'rentedMediaDonebyUploader' ||
      activeButton === 'rentedMediaDonebyExecutive' ||
      activeButton === 'rentedPropertiesEligibleForReward' ||
      activeButton === 'rentedUsersEligibleForReward' ||
      activeButton === 'rentedExclusiveListings'
    ) {
      statusValue = 'rented';
    } else if (
      activeButton === 'armsSold' ||
      activeButton === 'soldGeotagged' ||
      activeButton === 'soldManualGeotagged' ||
      activeButton === 'soldUnpublished' ||
      activeButton === 'soldMediaDonebyUploader' ||
      activeButton === 'soldMediaDonebyExecutive' ||
      activeButton === 'soldPropertiesEligibleForReward' ||
      activeButton === 'soldUsersEligibleForReward' ||
      activeButton === 'soldExclusiveListings'
    ) {
      statusValue = 'sold';
    } else if (
      activeButton === 'armsOnHold' ||
      activeButton === 'onholdGeotagged' ||
      activeButton === 'onholdManualGeotagged' ||
      activeButton === 'onholdUnpublished' ||
      activeButton === 'onholdMediaDonebyUploader' ||
      activeButton === 'onholdMediaDonebyExecutive' ||
      activeButton === 'onholdPropertiesEligibleForReward' ||
      activeButton === 'onholdUsersEligibleForReward' ||
      activeButton === 'onholdExclusiveListings'
    ) {
      statusValue = 'onhold';
    } else {
      statusValue = activeButton;
    }
    if (activeButton === 'outdated' && activePurpose !== 'rent') {
      activePurpose = 'sale';
    }
    if (activeButton === 'refresh') {
      statusValue = 'published';
    }
    this.setState({ statusValue });
    return {
      params: {
        outdated: activeButton === 'outdated' ? true : null,
        catalogingDone: activeButton === 'pendingCatalogingDone' ? true : null,
        expired:
          activeButton === 'expired' ||
          activeButton === 'armsExpired' ||
          activeButton === 'expiredGeotagged' ||
          activeButton === 'expiredManualGeotagged' ||
          activeButton === 'expiredMediaDonebyExecutive' ||
          activeButton === 'expiredMediaDonebyUploader'
            ? true
            : null,
        page: activePage,
        pageSize,
        status:
          !expired &&
          activeButton !== 'expiredGeotagged' &&
          activeButton !== 'expiredManualGeotagged'
            ? statusValue
            : 'published',
        q: type && clearButton ? q : null,
        searchby: q ? type : null,
        listing_type:
          listingType === 'noAgency' || listingType === 'agency' || expired ? null : listingType,
        colSearch: colSearch === 'status' ? colSearch : null,
        startDate: moment(startDate).toISOString(),
        endDate: moment(endDate).toISOString(),
        noImage:
          activeButton === 'noImage' ||
          activeButton === 'no-image' ||
          activeButton === 'noImageEdited' ||
          activeButton === 'noImageRejected' ||
          activeButton === 'noImageClosed'
            ? true
            : null,
        hasImage:
          activeButton === 'imageP' ||
          activeButton === 'image' ||
          activeButton === 'hasImageEdited' ||
          activeButton === 'hasImageRejected' ||
          activeButton === 'hasImageClosed'
            ? true
            : null,
        arms:
          activeButton === 'armsPending' ||
          activeButton === 'armsPublished' ||
          activeButton === 'armsRejected' ||
          activeButton === 'armsClosed' ||
          activeButton === 'armsExpired' ||
          activeButton === 'armsEdited' ||
          activeButton === 'armsRented' ||
          activeButton === 'armsSold' ||
          activeButton === 'armsOnHold'
            ? true
            : null,
        noAgency: listingType === 'noAgency' || agencyType === 'noAgency' ? true : null,
        agency: listingType === 'agency' || agencyType === 'agency' ? true : null,
        purpose: activePurpose,
        type: propertyType,
        subtype: propertySubtype,
        verified: geotagButton === 'geotagging' ? true : null,
        locateManually:
          geotagButton === manuallyGeotagged || geotagButton === expiredManuallyGeotagged
            ? true
            : null,
        unVerified: geotagButton === nonGeotagged ? true : null,
        propsureTagged:
          geotagButton === propsureGeotagged || geotagButton === expiredPropsureGeotagged
            ? true
            : null,
        expiredGeotag:
          geotagButton === expiredGeotag ||
          geotagButton === expiredPropsureGeotagged ||
          geotagButton === expiredManuallyGeotagged
            ? true
            : null,
        unpublished:
          activeButton === 'publishedUnpublished' ||
          activeButton === 'closedUnpublished' ||
          activeButton === 'pendingUnpublished' ||
          activeButton === 'rejectedUnpublished' ||
          activeButton === 'expiredUnpublished' ||
          activeButton === 'editedUnpublished' ||
          activeButton === 'rentedUnpublished' ||
          activeButton === 'soldUnpublished' ||
          activeButton === 'onholdUnpublished'
            ? true
            : null,
        client: propertyButton === 'client' ? true : null,
        myProperties: propertyButton === 'systemUser' ? true : null,
        rider: propertyButton === 'rider' ? true : null,
        wanted: propertyButton === 'wanted' ? true : null,

        adminUploader:
          activeButton === 'soldMediaDonebyUploader' ||
          activeButton === 'pendingMediaDonebyUploader' ||
          activeButton === 'publishedMediaDonebyUploader' ||
          activeButton === 'editedMediaDonebyUploader' ||
          activeButton === 'rentedMediaDonebyUploader' ||
          activeButton === 'expiredMediaDonebyUploader' ||
          activeButton === 'closedMediaDonebyUploader' ||
          activeButton === 'rejectedMediaDonebyUploader' ||
          activeButton === 'onholdMediaDonebyUploader'
            ? true
            : null,
        mediaExecutive:
          activeButton === 'soldMediaDonebyExecutive' ||
          activeButton === 'pendingMediaDonebyExecutive' ||
          activeButton === 'publishedMediaDonebyExecutive' ||
          activeButton === 'editedMediaDonebyExecutive' ||
          activeButton === 'rentedMediaDonebyExecutive' ||
          activeButton === 'expiredMediaDonebyExecutive' ||
          activeButton === 'closedMediaDonebyExecutive' ||
          activeButton === 'rejectedMediaDonebyExecutive' ||
          activeButton === 'onholdMediaDonebyExecutive'
            ? true
            : null,
        propertiesEligibleForReward:
          activeButton === 'soldPropertiesEligibleForReward' ||
          activeButton === 'pendingPropertiesEligibleForReward' ||
          activeButton === 'publishedPropertiesEligibleForReward' ||
          activeButton === 'editedPropertiesEligibleForReward' ||
          activeButton === 'rentedPropertiesEligibleForReward' ||
          activeButton === 'expiredPropertiesEligibleForReward' ||
          activeButton === 'closedPropertiesEligibleForReward' ||
          activeButton === 'rejectedPropertiesEligibleForReward' ||
          activeButton === 'onholdPropertiesEligibleForReward'
            ? true
            : null,
        userEligibleForReward:
          activeButton === 'soldUsersEligibleForReward' ||
          activeButton === 'pendingUsersEligibleForReward' ||
          activeButton === 'publishedUsersEligibleForReward' ||
          activeButton === 'editedUsersEligibleForReward' ||
          activeButton === 'rentedUsersEligibleForReward' ||
          activeButton === 'expiredUsersEligibleForReward' ||
          activeButton === 'closedUsersEligibleForReward' ||
          activeButton === 'rejectedUsersEligibleForReward' ||
          activeButton === 'onholdUsersEligibleForReward'
            ? true
            : null,
        exclusiveListings:
          activeButton === 'soldExclusiveListings' ||
          activeButton === 'pendingExclusiveListings' ||
          activeButton === 'publishedExclusiveListings' ||
          activeButton === 'editedExclusiveListings' ||
          activeButton === 'rentedExclusiveListings' ||
          activeButton === 'expiredExclusiveListings' ||
          activeButton === 'closedExclusiveListings' ||
          activeButton === 'rejectedExclusiveListings' ||
          activeButton === 'onholdExclusiveListings'
            ? true
            : null,
        substatus: statusValue === 'rejected' ? activeReason : null,
        refresh,
      },
    };
  }

  setZone(zone) {
    this.setState({
      zone: {
        id: zone ? zone.id : null,
        name: zone ? zone.zone_name : '',
      },
      q: zone ? zone.id : null,
      clearButton: false,
    });
  }

  static getCity(/* query */) {
    return axios
      .get('/api/city', {
        params: {
          all: true,
        },
      })
      .then((response) => ({ options: response.data.items }));
  }

  static getZone(/* query */) {
    return axios
      .get('/api/zone/filter', {
        params: {
          all: true,
          status: 'active',
        },
      })
      .then((response) => ({ options: response.data }));
  }

  getPropsureBadge = (arms, wanted) => {
    if (arms) {
      return 'propsure-expired-arms';
    }
    if (wanted) {
      return 'propsure-expired-wanted';
    }
    return 'propsure-expired-admin';
  };

  getGeotagMsg = () => {
    const { geotagPlatform } = this.state;
    if (geotagPlatform === 'admin') {
      return '(Geotagging Platform: Graana Admin)';
    }
    if (geotagPlatform === 'field') {
      return '(Geotagging Platform: ARMS Field App)';
    }
    return null;
  };

  getSearchTypes = () => {
    const { activeButton, statusValue } = this.state;
    const name = { name: 'Id', value: 'id' };
    const agency = { name: 'Agency', value: 'agency' };
    const area = { name: 'Area', value: 'area' };
    const city = { name: 'City', value: 'city' };
    const email = { name: 'Email', value: 'email' };
    const cataloguer = { name: 'Cataloger', value: 'cataloguer' };
    const phone = { name: 'Phone', value: 'phone' };
    const zone = { name: 'Zone', value: 'zone' };
    const subzone = { name: 'Area Group', value: 'subzone' };
    const rider = { name: 'Rider', value: 'rider' };
    const geotaggedBy = { name: 'Geotagged By', value: 'geotaggedBy' };
    const platform = { name: 'Geotagging Platform', value: 'platform' };
    if (statusValue === 'pending') {
      return [
        name,
        agency,
        area,
        city,
        email,
        cataloguer,
        phone,
        zone,
        subzone,
        rider,
        geotaggedBy,
        platform,
      ];
    }
    if (activeButton !== 'refresh') {
      return [name, agency, area, city, email, phone, zone, subzone, rider, geotaggedBy, platform];
    }
    return [name, agency, area, city, email, phone, zone, subzone, geotaggedBy, platform];
  };

  getMarkedHiddenBanner = (activeButton) => {
    if (activeButton === 'publishedUnpublished') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties published but marked hidden from search results as per client
              request{this.renderGeotagFilter()}. These will still be available in ARMS and AIRA
              matching {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'closedUnpublished') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties closed but marked hidden from search results as per client
              request{this.renderGeotagFilter()}. These will still be available in ARMS and AIRA
              matching {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'pendingUnpublished') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties added by end-users but marked hidden from search results as per
              client request{this.renderGeotagFilter()}. These will still be available in ARMS and
              AIRA matching {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'rejectedUnpublished') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties rejected but marked hidden from search results as per client
              request{this.renderGeotagFilter()}. These will still be available in ARMS and AIRA
              matching {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'expiredUnpublished') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties expired but marked hidden from search results as per client
              request{this.renderGeotagFilter()}. These will still be available in ARMS and AIRA
              matching {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'editedUnpublished') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties edited but marked hidden from search results as per client
              request{this.renderGeotagFilter()}. These will still be available in ARMS and AIRA
              matching {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }

    if (activeButton === 'rentedUnpublished') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties rented but marked hidden from search results as per client
              request{this.renderGeotagFilter()}. These will still be available in ARMS and AIRA
              matching {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'soldUnpublished') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties sold but marked hidden from search results as per client request
              {this.renderGeotagFilter()}. These will still be available in ARMS and AIRA matching{' '}
              {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'onholdUnpublished') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties &quot;On-hold&quot; but marked hidden from search results as per
              client request{this.renderGeotagFilter()}. These will still be available in ARMS and
              AIRA matching {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  getUrl = () => `${graanaUrl}property/`;

  getLogHeader() {
    const { modalAiraLogs, logLoading } = this.state;
    if (modalAiraLogs && modalAiraLogs[0]) {
      return `Log History for Property ID: ${modalAiraLogs[0].property_id}`;
    }
    if (logLoading) {
      return 'Loading logs...';
    }
    return 'No Logs Found';
  }

  jazzUsersBadge = (property) => {
    if (property && (property.jazzUser || property.EasyPaisa) && property.user.cnicIssuanceDate) {
      return (
        <div>
          <span
            className="badge rounded-pill bg-primary"
            style={{ padding: '5px', fontSize: '8px' }}
          >
            {' '}
            Eligible for reward
          </span>
        </div>
      );
    }
    return null;
  };

  getWithMediaBanner = (activeButton) => {
    if (activeButton === 'image') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties added by end-users/ approved from &quot;On-hold&quot; properties
              {this.renderGeotagFilter()} with media added {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'imageP') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties published from Admin Portal and visible on Graana.com searches,
              {this.renderGeotagFilter()} with media added {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'hasImageEdited') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties edited by end-user, pending approval by cataloger
              {this.renderGeotagFilter()} with media added {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'hasImageClosed') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties closed from Admin Portal and unavailable in the market
              {this.renderGeotagFilter()} with media added {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'hasImageRejected') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties rejected from Admin Portal{this.renderGeotagFilter()} with media
              added {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  getNoMediaBanner = (activeButton) => {
    if (activeButton === 'no-image') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties added by end-users/ approved from &quot;On-hold&quot; properties
              {this.renderGeotagFilter()} without media added {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'noImage') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties published from Admin Portal and visible on Graana.com searches,
              {this.renderGeotagFilter()} without media added {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'noImageEdited') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties edited by end-user, pending approval by cataloger
              {this.renderGeotagFilter()} without media added {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'noImageClosed') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties closed from Admin Portal and unavailable in the market
              {this.renderGeotagFilter()} without media added {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'noImageRejected') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties rejected from Admin Portal{this.renderGeotagFilter()} without
              media added {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  getMediaExecutiveBanner = (activeButton) => {
    if (activeButton === 'soldMediaDonebyExecutive') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties marked &quot;sold&quot; by end-user{this.renderGeotagFilter()}{' '}
              and media done by media executive {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'pendingMediaDonebyExecutive') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties added by end-users/ approved from &quot;On-hold&quot; properties
              {this.renderGeotagFilter()} and media done by media executive {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'publishedMediaDonebyExecutive') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties published from Admin Portal and visible on Graana.com searches
              {this.renderGeotagFilter()} and media done by media executive {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'editedMediaDonebyExecutive') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties edited by end-user, pending approval by cataloger
              {this.renderGeotagFilter()} and media done by media executive {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'rentedMediaDonebyExecutive') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties marked &quot;rented&quot; by end-user{this.renderGeotagFilter()}{' '}
              and media done by media executive {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'expiredMediaDonebyExecutive') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties automatically expired after 5 months{this.renderGeotagFilter()}{' '}
              and media done by media executive {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'closedMediaDonebyExecutive') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties closed from Admin Portal and unavailable in the market
              {this.renderGeotagFilter()} and media done by media executive {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'rejectedMediaDonebyExecutive') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties rejected from Admin Portal{this.renderGeotagFilter()} and media
              done by media executive {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'onholdMediaDonebyExecutive') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties added from &quot;Riders App&quot;, &quot;Admin Portal&quot; and
              &quot;Wanted Leads&quot;{this.renderGeotagFilter()} and media done by media executive{' '}
              {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  getCatalogingDoneBanner = (activeButton) => {
    if (activeButton === 'pendingCatalogingDone') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties with cataloging marked done.
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  getUserEligibleBanner = (activeButton) => {
    if (activeButton === 'soldUsersEligibleForReward') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties marked &quot;sold&quot; by end-user{this.renderGeotagFilter()}{' '}
              where user profile is eligible for JazzCash/EasyPaisa reward program having complete
              details {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'pendingUsersEligibleForReward') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties added by end-users{this.renderGeotagFilter()} where user profile
              is eligible for JazzCash/EasyPaisa reward program having complete details{' '}
              {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'publishedUsersEligibleForReward') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties published from Admin Portal and visible on Graana.com searches
              {this.renderGeotagFilter()} with user profile eligible for JazzCash/EasyPaisa reward
              program having complete details {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'editedUsersEligibleForReward') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties edited by end-user, pending approval by cataloger
              {this.renderGeotagFilter()} where user profile is eligible for JazzCash/EasyPaisa
              reward program having complete details {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'rentedUsersEligibleForReward') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties marked &quot;rented&quot; by end-user{this.renderGeotagFilter()}{' '}
              where user profile is eligible for JazzCash/EasyPaisa reward program having complete
              details {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'expiredUsersEligibleForReward') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties automatically expired after 5 months{this.renderGeotagFilter()}{' '}
              where user profile is eligible for JazzCash/EasyPaisa reward program having complete
              details {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'closedUsersEligibleForReward') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties closed from Admin Portal and unavailable in the market
              {this.renderGeotagFilter()} where user profile is eligible for JazzCash/EasyPaisa
              reward program having complete details {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'rejectedUsersEligibleForReward') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties rejected from Admin Portal{this.renderGeotagFilter()} where user
              profile is eligible for JazzCash/EasyPaisa reward program having complete details{' '}
              {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'onholdUsersEligibleForReward') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              {' '}
              Displaying properties added from &quot;Riders App&quot;, &quot;Admin Portal&quot; and
              &quot;Wanted Leads&quot;{this.renderGeotagFilter()} where user profile is eligible for
              JazzCash/EasyPaisa reward program having complete details {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  getPropertiesEligibleBanner = (activeButton) => {
    if (activeButton === 'soldPropertiesEligibleForReward') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties marked &quot;sold&quot; by end-user{this.renderGeotagFilter()}{' '}
              and properties marked eligible for JazzCash/EasyPaisa reward program{' '}
              {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'pendingPropertiesEligibleForReward') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties added by end-users/ approved from &quot;On-hold&quot; properties
              {this.renderGeotagFilter()} and properties marked eligible for JazzCash/EasyPaisa
              reward program {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'publishedPropertiesEligibleForReward') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties published from Admin Portal and visible on Graana.com searches
              {this.renderGeotagFilter()} where properties are marked eligible for
              JazzCash/EasyPaisa reward program {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'editedPropertiesEligibleForReward') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties edited by end-user, pending approval by cataloger
              {this.renderGeotagFilter()} and properties marked eligible for JazzCash/EasyPaisa
              reward program {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'rentedPropertiesEligibleForReward') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties marked &quot;rented&quot; by end-user{this.renderGeotagFilter()}{' '}
              and properties marked eligible for JazzCash/EasyPaisa reward program{' '}
              {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'expiredPropertiesEligibleForReward') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties automatically expired after 5 months{this.renderGeotagFilter()}{' '}
              and properties marked eligible for JazzCash/EasyPaisa reward program{' '}
              {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'closedPropertiesEligibleForReward') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties closed from Admin Portal and unavailable in the market
              {this.renderGeotagFilter()} and properties marked eligible for JazzCash/EasyPaisa
              reward program {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'rejectedPropertiesEligibleForReward') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties rejected from Admin Portal{this.renderGeotagFilter()} and
              properties marked eligible for JazzCash/EasyPaisa reward program {this.getGeotagMsg()}
              .
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'onholdPropertiesEligibleForReward') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              {' '}
              Displaying properties added from &quot;Riders App&quot;, &quot;Admin Portal&quot; and
              &quot;Wanted Leads&quot;{this.renderGeotagFilter()} and properties marked eligible for
              JazzCash/EasyPaisa reward program {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  getARMSBanner = (activeButton) => {
    if (activeButton === 'armsSold') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties added by Surveyors (Field App){this.renderGeotagFilter()} and
              approved by Agents (ARMS) and Sold {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'armsPending') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties added by Surveyors (Field App){this.renderGeotagFilter()} and
              approved by Agents (ARMS) {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'armsPublished') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties added by Surveyors (Field App){this.renderGeotagFilter()} and
              approved by Agents (ARMS) and published {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'armsEdited') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties added by Surveyors (Field App){this.renderGeotagFilter()} and
              approved by Agents (ARMS) and edited {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'armsRented') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties added by Surveyors (Field App){this.renderGeotagFilter()} and
              approved by Agents (ARMS) and rented {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'armsExpired') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties added by Surveyors (Field App){this.renderGeotagFilter()} and
              approved by Agents (ARMS) and expired {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'armsClosed') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties added by Surveyors (Field App){this.renderGeotagFilter()} and
              approved by Agents (ARMS) and closed {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'armsRejected') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties added by Surveyors (Field App){this.renderGeotagFilter()} and
              approved by Agents (ARMS) and rejected {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'armsOnHold') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              {' '}
              Displaying properties added from &quot;Riders App&quot; by Surveyors
              {this.renderGeotagFilter()} and pending approval from agents in ARMS{' '}
              {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  getMediaUploaderBanner = (activeButton) => {
    if (activeButton === 'soldMediaDonebyUploader') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties marked &quot;sold&quot; by end-user{this.renderGeotagFilter()}{' '}
              and media done by media uploader {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'pendingMediaDonebyUploader') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties added by end-users/ approved from &quot;On-hold&quot; properties
              {this.renderGeotagFilter()} and media done by media uploader {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'publishedMediaDonebyUploader') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties published from Admin Portal and visible on Graana.com searches
              {this.renderGeotagFilter()} and media done by media uploader {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'editedMediaDonebyUploader') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties edited by end-user, pending approval by cataloger
              {this.renderGeotagFilter()} and media done by media uploader {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'rentedMediaDonebyUploader') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties marked &quot;rented&quot; by end-user{this.renderGeotagFilter()}{' '}
              and media done by media uploader {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'expiredMediaDonebyUploader') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties automatically expired after 5 months{this.renderGeotagFilter()}{' '}
              and media done by media uploader {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'closedMediaDonebyUploader') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties closed from Admin Portal and unavailable in the market
              {this.renderGeotagFilter()} and media done by media uploader {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'rejectedMediaDonebyUploader') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties rejected from Admin Portal{this.renderGeotagFilter()} and media
              done by media uploader {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    if (activeButton === 'onholdMediaDonebyUploader') {
      return (
        <div className="row justify-content-between">
          <div className="col-12 text-center">
            <div className="alert alert-primary" role="alert">
              Displaying properties added from &quot;Riders App&quot;, &quot;Admin Portal&quot; and
              &quot;Wanted Leads&quot;{this.renderGeotagFilter()} and media done by media uploader{' '}
              {this.getGeotagMsg()}.
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  clearSearch() {
    this.setState(
      {
        setClearButton: 1,
        clearButton: false,
        q: '',
        zone: '',
        areagroup: '',
        geotagPlatform: '',
        responseMessage: loadingMsg,
        loading: true,
        properties: [],
        activePage: 1,
      },
      () => {
        this.fetchProperties();
      }
    );
  }

  calculateStats(response) {
    this.setState({
      dpm: response.data.monthlyDesc,
      dpd: response.data.dailyDesc,
      rpm: response.data.monthlyRefresh,
      rpd: response.data.dailyRefresh,
      cpm: response.data.monthlyClosed,
      cpd: response.data.dailyClosed,
      loadingStats: false,
    });
  }

  bringProperties(type) {
    const { agencyType } = this.state;
    if (type) {
      this.setState(
        {
          loading: true,
          properties: [],
          responseMessage: loadingMsg,
          agencyType: type,
          activePage: 1,
        },
        () => {
          this.fetchProperties();
        }
      );
    } else if (agencyType) {
      this.setState(
        {
          loading: true,
          properties: [],
          responseMessage: loadingMsg,
          agencyType: null,
          activePage: 1,
        },
        () => {
          this.fetchProperties();
        }
      );
    }
  }

  changeType(type) {
    this.setState(
      {
        loading: true,
        properties: [],
        responseMessage: loadingMsg,
        propertyType: type,
        propertySubtype: null,
        activePage: 1,
      },
      () => {
        this.fetchProperties();
      }
    );
  }

  showLogs(propertyId) {
    this.setState({ logLoading: true, modalVisible: true, modalAiraLogs: [] });
    axios.get(`/api/dhoondo/aira-log/property/${propertyId}`).then((response) => {
      this.setState({
        modalAiraLogs: response.data,
        logLoading: false,
      });
    });
  }

  showGeotaggedProperties(status) {
    const { geotagButton } = this.state;
    if (status) {
      this.setState(
        {
          geotagButton: status,
          loading: true,
          properties: [],
          responseMessage: loadingMsg,
          activePage: 1,
        },
        () => {
          this.fetchProperties();
        }
      );
    } else if (geotagButton) {
      this.setState(
        {
          geotagButton: null,
          loading: true,
          properties: [],
          responseMessage: loadingMsg,
          activePage: 1,
        },
        () => {
          this.fetchProperties();
        }
      );
    }
  }

  showClientProperties(status) {
    const { propertyButton } = this.state;
    if (
      status === 'client' ||
      status === 'systemUser' ||
      status === 'rider' ||
      status === 'wanted'
    ) {
      this.setState(
        {
          propertyButton: status,
          loading: true,
          properties: [],
          responseMessage: loadingMsg,
          activePage: 1,
        },
        () => {
          this.fetchProperties();
        }
      );
    } else if (propertyButton) {
      this.setState(
        {
          propertyButton: null,
          loading: true,
          properties: [],
          responseMessage: loadingMsg,
          activePage: 1,
        },
        () => {
          this.fetchProperties();
        }
      );
    }
  }

  showProperties(type) {
    this.setState(
      {
        loading: true,
        properties: [],
        responseMessage: loadingMsg,
        listingType: type,
        activePage: 1,
      },
      () => {
        this.fetchProperties();
      }
    );
  }

  saveResponseToState(response) {
    return this.setState(
      (prevState) => ({
        loading: false,
        total: response.data.total,
        properties: response.data.items,
        responseMessage: 'No Properties Found',
        pages: Math.ceil(response.data.total / prevState.pageSize),
      }),
      () => {
        if (this.myRef && this.myRef.offsetTop) {
          window.scrollTo(0, this.myRef.offsetTop);
        }
      }
    );
  }

  fetchProperties() {
    const { back, dispatch, history } = this.props;
    setHeader(dispatch, 'Cataloging');
    logPageView('Cataloging');
    this.setState({ loading: true });
    if (
      (back && history.action === 'POP') ||
      (history.location.state && history.location.state.message === 'submit') ||
      (history.location.state && history.location.state.message === 'breadcrumbs')
    ) {
      dispatch({
        type: types.CLEAR_BACK,
      });
      axios.get(this.endPoint, this.getPrevParams()).then((response) => {
        this.saveResponseToState(response);
      });
    } else {
      axios.get(this.endPoint, this.getParams()).then((response) => {
        this.saveResponseToState(response);
      });
    }
  }

  fetchStats() {
    axios.get(this.statsEndPoint).then((response) => {
      this.calculateStats(response);
    });
  }

  showExpired(expired, status) {
    this.setState(
      {
        loading: true,
        expired,
        properties: [],
        responseMessage: loadingMsg,
        activePage: 1,
        activeButton: status,
      },
      () => {
        this.fetchProperties();
      }
    );
  }

  selectAllRecords(e) {
    let { properties: allProperties } = this.state;

    allProperties = allProperties.map((property) => {
      const newProperty = property;
      newProperty.selected = e.target.checked;
      return newProperty;
    });
    this.setState({ properties: allProperties });
  }

  selectRecord(e, selectedProperty) {
    let { properties: allProperties } = this.state;

    allProperties = allProperties.map((property) => {
      const newProperty = property;
      if (newProperty.id === selectedProperty.id) {
        newProperty.selected = e.target.checked;
      }
      return newProperty;
    });
    this.setState({ properties: allProperties });
  }

  savePriority(e) {
    e.preventDefault();
    const { properties, selectedPriority } = this.state;

    let propertiesIds = properties.map((prop) => (prop.selected ? prop.id : null));

    propertiesIds = propertiesIds.filter((prop) => !!prop);

    if (Number(selectedPriority) && propertiesIds && propertiesIds.length) {
      if (propertiesIds.length === 1) {
        this.setState({ loading: true });
        axios
          .post(this.setPriorityEndPoint, {
            priority: selectedPriority,
            propertyIds: propertiesIds,
          })
          .then((/* response */) => {
            this.setState({
              loading: false,
              selectedPriority: undefined,
              selectedBulkAction: undefined,
            });
            this.setState({ priorityUpdated: true }, () => {
              window.scrollTo(0, this.priorityUpdatedRef.offsetTop);
              setTimeout(() => {
                this.setState({ priorityUpdated: false });
              }, 2000);
            });
            const event = { target: { value: false } };
            this.selectAllRecords(event);
          });
      } else if (
        window.confirm('Are you sure you want to update priority for multiple properties?')
      ) {
        this.setState({ loading: true });
        axios
          .post(this.setPriorityEndPoint, {
            priority: selectedPriority,
            propertyIds: propertiesIds,
          })
          .then((/* response */) => {
            this.setState({
              loading: false,
              selectedPriority: undefined,
              selectedBulkAction: undefined,
            });
            this.setState({ priorityPropertiesUpdated: true }, () => {
              window.scrollTo(0, this.priorityPropertiesUpdatedRef.offsetTop);
              setTimeout(() => {
                this.setState({ priorityPropertiesUpdated: false });
              }, 2000);
            });
            const event = { target: { value: false } };
            this.selectAllRecords(event);
          });
      }
    }
  }

  saveStatus(e) {
    e.preventDefault();
    const { properties, selectedStatus, activeButton } = this.state;
    if (selectedStatus !== 'rejected' && selectedStatus !== 'cataloging') {
      let propertiesIds = properties.map((prop) => (prop.selected ? prop.id : null));
      let propertiesRider = properties.map((prop) =>
        prop.selected && prop.rider ? prop.rider.surveyor : null
      );
      propertiesRider = propertiesRider.filter((prop) => !!prop);

      if (propertiesRider.length >= 1) {
        if (propertiesRider[0]) {
          propertiesRider = true;
        } else {
          propertiesRider = false;
        }
      } else {
        propertiesRider = false;
      }
      propertiesIds = propertiesIds.filter((prop) => !!prop);

      if (selectedStatus && selectedStatus === 'refresh') {
        this.setState({ loading: true });
        axios
          .post('/api/property/bulk-refresh', {
            propertyIds: propertiesIds,
          })
          .then((response) => {
            if (typeof response.data === 'string') {
              alert(response.data);
              this.setState({ loading: false });
            } else {
              this.setState({
                loading: false,
                selectedStatus: undefined,
                selectedBulkAction: undefined,
              });
              const event = { target: { value: false } };
              this.selectAllRecords(event);
              this.fetchProperties();
              this.setState({ loadingStats: true });
              this.fetchStats();
            }
          });
      } else if (
        selectedStatus &&
        propertiesIds &&
        propertiesIds.length &&
        selectedStatus !== 'refresh' &&
        (!propertiesRider || activeButton !== 'onhold')
      ) {
        if (propertiesIds.length === 1) {
          this.setState({ loading: true });
          axios
            .post(this.setStatusEndPoint, {
              status: selectedStatus,
              propertyIds: propertiesIds,
            })
            .then((response) => {
              if (
                response.data.inactivePropertiesMsg &&
                typeof response.data.inactivePropertiesMsg === 'string'
              ) {
                alert(response.data.inactivePropertiesMsg);
                this.setState({ loading: false });
              } else {
                this.setState({
                  loading: false,
                  selectedStatus: undefined,
                  selectedBulkAction: undefined,
                });
                this.setState({ statusUpdated: true }, () => {
                  window.scrollTo(0, this.statusUpdatedRef.offsetTop);
                  setTimeout(() => {
                    this.setState({ statusUpdated: false });
                  }, 2000);
                });
                const event = { target: { value: false } };
                this.selectAllRecords(event);
                this.fetchProperties();
              }
            });
        } else if (
          window.confirm('Are you sure you want to update status for multiple properties?')
        ) {
          this.setState({ loading: true });
          axios
            .post(this.setStatusEndPoint, {
              status: selectedStatus,
              propertyIds: propertiesIds,
            })
            .then((response) => {
              if (
                response.data.inactivePropertiesMsg &&
                typeof response.data.inactivePropertiesMsg === 'string'
              ) {
                alert(response.data.inactivePropertiesMsg);
                this.setState({ loading: false });
              } else {
                this.setState({
                  loading: false,
                  selectedStatus: undefined,
                  selectedBulkAction: undefined,
                });
                this.setState({ statusPropertiesUpdated: true }, () => {
                  window.scrollTo(0, this.statusPropertiesUpdatedRef.offsetTop);
                  setTimeout(() => {
                    this.setState({ statusPropertiesUpdated: false });
                  }, 2000);
                });
                const event = { target: { value: false } };
                this.selectAllRecords(event);
                this.fetchProperties();
              }
            });
        }
      } else {
        window.alert('Cannot change status');
      }
    } else if (selectedStatus === 'cataloging') {
      let propertiesIds = properties.map((prop) => (prop.selected ? prop.id : null));
      propertiesIds = propertiesIds.filter((prop) => !!prop);
      this.setState({ loading: true });
      axios.post('/api/property/update-catalogue', { propertyIds: propertiesIds }).then(() => {
        this.fetchProperties();
      });
    } else {
      this.setState({ bulkRejectModal: true, rejectModal: true });
    }
  }

  transferProperty(e) {
    e.preventDefault();
    const { properties, transferId } = this.state;

    let propertiesIds = properties.map((prop) => (prop.selected ? prop.id : null));

    propertiesIds = propertiesIds.filter((prop) => !!prop);

    if (Number(transferId) && propertiesIds && propertiesIds.length) {
      if (propertiesIds.length === 1) {
        this.setState({ loading: true });
        axios
          .post(this.transferPropertyEndPoint, {
            transferId: parseInt(transferId, 10),
            properties: propertiesIds,
          })
          .then((/* response */) => {
            this.setState({
              loading: false,
              transferId: undefined,
              selectedBulkAction: undefined,
            });
            this.setState({ transferUpdated: true }, () => {
              window.scrollTo(0, this.transferUpdatedRef.offsetTop);
              setTimeout(() => {
                this.setState({ transferUpdated: false });
              }, 2000);
            });
            const event = { target: { value: false } };
            this.selectAllRecords(event);
          })
          .catch((error) => {
            this.setState({ loading: false });
            if (typeof error.response.data === 'string') {
              this.setState(
                {
                  transferUpdatedError: true,
                  transferErrorMessage: error.response.data,
                },
                () => {
                  window.scrollTo(0, this.transferUpdatedRef.offsetTop);
                  setTimeout(() => {
                    this.setState({ transferUpdatedError: false });
                  }, 3000);
                }
              );
            }
          });
      } else if (window.confirm('Are you sure you want to transfer multiple properties?')) {
        this.setState({ loading: true });
        axios
          .post(this.transferPropertyEndPoint, {
            transferId: parseInt(transferId, 10),
            properties: propertiesIds,
          })
          .then((/* response */) => {
            this.setState({
              loading: false,
              transferId: undefined,
              selectedBulkAction: undefined,
            });
            this.setState({ transferPropertiesUpdated: true }, () => {
              window.scrollTo(0, this.transferPropertiesUpdatedRef.offsetTop);
              setTimeout(() => {
                this.setState({ transferPropertiesUpdated: false });
              }, 2000);
            });
            const event = { target: { value: false } };
            this.selectAllRecords(event);
          })
          .catch((error) => {
            this.setState({ loading: false });
            if (typeof error.response.data === 'string') {
              this.setState(
                {
                  transferPropertiesUpdatedError: true,
                  transferErrorMessage: error.response.data,
                },
                () => {
                  window.scrollTo(0, this.transferPropertiesUpdatedRef.offsetTop);
                  setTimeout(() => {
                    this.setState({ transferPropertiesUpdatedError: false });
                  }, 3000);
                }
              );
            }
          });
      }
    }
  }

  changePurpose(purpose) {
    const { activePurpose } = this.state;
    if (activePurpose !== purpose) {
      this.setState(
        {
          activePurpose: purpose,
          activePage: 1,
          properties: [],
          loading: true,
          responseMessage: loadingMsg,
        },
        () => this.fetchProperties()
      );
    }
  }

  verifyGeotag(event) {
    event.preventDefault();
    const { propsureId } = this.state;
    axios.put(`/api/property/reverify/${propsureId}`).then(() => {
      window.alert('Property reverified');
      window.location.reload();
    });
  }

  changeSubtype(subtype) {
    this.setState(
      {
        loading: true,
        properties: [],
        responseMessage: loadingMsg,
        propertySubtype: subtype,
        activePage: 1,
      },
      () => {
        this.fetchProperties();
      }
    );
  }

  renderGeotagFilter() {
    const { geotagButton } = this.state;
    if (geotagButton === 'geotagging') {
      return ' and Geotagged';
    }
    if (geotagButton === nonGeotagged) {
      return ' and not Geotagged';
    }
    if (geotagButton === propsureGeotagged) {
      return ' and PropSure Geotagged';
    }
    if (geotagButton === manuallyGeotagged) {
      return ' and manually Geotagged';
    }
    if (geotagButton === expiredGeotag) {
      return ' and Geotag expired';
    }
    if (geotagButton === expiredPropsureGeotagged) {
      return ' with PropSure Geotag expired';
    }
    if (geotagButton === expiredManuallyGeotagged) {
      return ' with manual Geotag expired';
    }
    return '';
  }

  renderStats() {
    const { dpm, dpd, rpm, rpd, cpm, cpd, loadingStats } = this.state;
    return (
      <div className="col-md-4 col-sm-4 text-end">
        <button
          id="dpm"
          style={{
            marginRight: 2,
            cursor: 'default',
            width: '107px',
            whiteSpace: 'normal',
          }}
          className="btn btn-outline-danger button1"
          type="button"
        >
          <p style={{ color: 'black', fontWeight: 'bold', marginBottom: 0 }}>
            {loadingStats ? (
              <i className="fa fa-spinner fa-pulse" style={{ fontSize: '25px' }} />
            ) : (
              dpm
            )}
          </p>
          Desc/mo
          <UncontrolledTooltip placement="bottom" target="dpm">
            Descriptions per month
          </UncontrolledTooltip>
        </button>
        <button
          id="dpd"
          style={{
            marginRight: 2,
            cursor: 'default',
            width: '107px',
            whiteSpace: 'normal',
          }}
          className="btn btn-outline-danger button1"
          type="button"
        >
          <p style={{ color: 'black', fontWeight: 'bold', marginBottom: 0 }}>
            {loadingStats ? (
              <i className="fa fa-spinner fa-pulse" style={{ fontSize: '25px' }} />
            ) : (
              dpd
            )}
          </p>
          Desc/d
          <UncontrolledTooltip placement="bottom" target="dpd">
            Descriptions per day
          </UncontrolledTooltip>
        </button>
        <button
          id="rpm"
          style={{
            marginRight: 2,
            cursor: 'default',
            width: '107px',
            whiteSpace: 'normal',
          }}
          className="btn btn-outline-danger button1"
          type="button"
        >
          <p style={{ color: 'black', fontWeight: 'bold', marginBottom: 0 }}>
            {loadingStats ? (
              <i className="fa fa-spinner fa-pulse" style={{ fontSize: '25px' }} />
            ) : (
              rpm
            )}
          </p>
          Refresh/mo
          <UncontrolledTooltip placement="bottom" target="rpm">
            Refreshings per month
          </UncontrolledTooltip>
        </button>
        <button
          id="rpd"
          style={{
            marginRight: 2,
            marginTop: 2,
            cursor: 'default',
            width: '107px',
            whiteSpace: 'normal',
          }}
          className="btn btn-outline-danger button1"
          type="button"
        >
          <p style={{ color: 'black', fontWeight: 'bold', marginBottom: 0 }}>
            {loadingStats ? (
              <i className="fa fa-spinner fa-pulse" style={{ fontSize: '25px' }} />
            ) : (
              rpd
            )}
          </p>
          Refresh/d
          <UncontrolledTooltip placement="bottom" target="rpd">
            Refreshings per day
          </UncontrolledTooltip>
        </button>
        <button
          id="cpm"
          style={{
            marginRight: 2,
            marginTop: 2,
            cursor: 'default',
            width: '107px',
            whiteSpace: 'normal',
          }}
          className="btn btn-outline-danger button1"
          type="button"
        >
          <p style={{ color: 'black', fontWeight: 'bold', marginBottom: 0 }}>
            {loadingStats ? (
              <i className="fa fa-spinner fa-pulse" style={{ fontSize: '25px' }} />
            ) : (
              cpm
            )}
          </p>
          Closed/mo
          <UncontrolledTooltip placement="bottom" target="cpm">
            Closed Listings per month
          </UncontrolledTooltip>
        </button>
        <button
          id="cpd"
          style={{
            marginRight: 2,
            marginTop: 2,
            cursor: 'default',
            width: '107px',
            whiteSpace: 'normal',
          }}
          className="btn btn-outline-danger button1"
          type="button"
        >
          <p style={{ color: 'black', fontWeight: 'bold', marginBottom: 0 }}>
            {loadingStats ? (
              <i className="fa fa-spinner fa-pulse" style={{ fontSize: '25px' }} />
            ) : (
              cpd
            )}
          </p>
          Closed/d
          <UncontrolledTooltip placement="bottom" target="cpd">
            Closed Listings per day
          </UncontrolledTooltip>
        </button>
      </div>
    );
  }

  renderLoading() {
    const { loading } = this.state;
    return <Loading loading={loading} />;
  }

  renderSearch() {
    const { q, type, zone, isLoadingAreagroup, areagroup, areagroups, geotagPlatform } = this.state;
    const geotagPlatforms = [
      { label: 'Graana Admin', value: 'admin' },
      { label: 'ARMS Field App', value: 'field' },
    ];
    if (type === 'zone') {
      return (
        <div>
          <Select.Async
            name="zone"
            multi={false}
            value={zone ? zone.id : null}
            onChange={(value) => this.setZone(value)}
            loadOptions={(input) => Properties.getZone(input)}
            valueKey="id"
            labelKey="zone_name"
            clearable={false}
            placeholder="Select Zone"
            required
            style={{ borderRadius: '5px' }}
          />
        </div>
      );
    }
    if (type === 'subzone') {
      return (
        <div>
          <Select
            style={{ borderRadius: 5 }}
            name="areagroup"
            isLoading={isLoadingAreagroup}
            multi={false}
            value={areagroup ? areagroup.id : null}
            onChange={(value) => this.setAreagroup(value)}
            options={areagroups}
            valueKey="id"
            labelKey="info"
            clearable={false}
            required
          />
        </div>
      );
    }
    if (type === 'platform') {
      return (
        <div>
          <Select
            style={{ borderRadius: 5 }}
            name="platform"
            value={geotagPlatform}
            onChange={(value) =>
              this.setState({
                q: value ? value.value : null,
                geotagPlatform: value ? value.value : null,
                clearButton: false,
              })
            }
            options={geotagPlatforms}
            valueKey="value"
            labelKey="label"
            clearable={false}
          />
        </div>
      );
    }
    return (
      <div>
        <input
          className="form-control"
          type={type === 'id' ? 'number' : 'text'}
          name="search"
          placeholder="Search"
          value={q}
          onChange={(event) =>
            this.setState({
              q: event.target.value ? event.target.value : undefined,
              clearButton: false,
            })
          }
          onKeyPress={(event) => {
            if (event.key === 'Enter' && type && q) {
              this.handleSearch();
            }
          }}
        />
      </div>
    );
  }

  renderDateBanner(geoTagMessage) {
    const { type, q, geotagMsg } = this.state;
    if (type === 'platform' && q && geotagMsg) {
      return `Displaying listings Geotagged through and added/status updated in the selected period ${geoTagMessage}.`;
    }
    return `Displaying listings added/status updated in the selected period.`;
  }

  renderExpiredBanner(geoTagMessage) {
    const { type, q, geotagMsg } = this.state;
    if (type === 'platform' && q && geotagMsg) {
      return `Displaying properties and automatically expired after 5 months${this.getGeotagFilter()} ${geoTagMessage}.`;
    }
    return `Displaying properties automatically expired after 5 months${this.getGeotagFilter()}.`;
  }

  renderPublishedBanner = (geoTagMessage) => {
    const { type, q, geotagMsg } = this.state;
    if (type === 'platform' && q && geotagMsg) {
      return `Displaying properties published from Admin Portal and visible on Graana.com searches${this.getGeotagFilter()} ${geoTagMessage}.`;
    }
    return `Displaying properties published from Admin Portal and visible on Graana.com searches${this.getGeotagFilter()}.`;
  };

  renderRejectedBanner = (geoTagMessage) => {
    const { type, q, geotagMsg } = this.state;
    if (type === 'platform' && q && geotagMsg) {
      return `Displaying properties rejected from Admin Portal by Catalogers and not visible on Graana.com searches${this.getGeotagFilter()} ${geoTagMessage}.`;
    }
    return `Displaying properties rejected from Admin Portal by Catalogers and not visible on Graana.com searches${this.getGeotagFilter()}.`;
  };

  renderClosedBanner = (geoTagMessage) => {
    const { type, q, geotagMsg } = this.state;
    if (type === 'platform' && q && geotagMsg) {
      return `Displaying properties closed from Admin Portal and unavailable in the market${this.getGeotagFilter()} ${geoTagMessage}.`;
    }
    return `Displaying properties closed from Admin Portal and unavailable in the market${this.getGeotagFilter()}.`;
  };

  renderRefreshedBanner = (geoTagMessage) => {
    const { type, q, geotagMsg } = this.state;
    if (type === 'platform' && q && geotagMsg) {
      return `Displaying properties refreshed from Admin Portal or by end-user and is still available in the market${this.getGeotagFilter()} ${geoTagMessage}.`;
    }
    return `Displaying properties refreshed from Admin Portal or by end-user and is still available in the market${this.getGeotagFilter()}.`;
  };

  renderEditedBanner = (geoTagMessage) => {
    const { type, q, geotagMsg } = this.state;
    if (type === 'platform' && q && geotagMsg) {
      return `Displaying properties edited by end-user and pending approval by cataloger${this.getGeotagFilter()} ${geoTagMessage}.`;
    }
    return `Displaying properties edited by end-user and pending approval by cataloger${this.getGeotagFilter()}.`;
  };

  renderRentedBanner = (geoTagMessage) => {
    const { type, q, geotagMsg } = this.state;
    if (type === 'platform' && q && geotagMsg) {
      return `Displaying properties marked "rented" by end-user${this.getGeotagFilter()} ${geoTagMessage}.`;
    }
    return `Displaying properties marked "rented" by end-user${this.getGeotagFilter()}.`;
  };

  renderSoldBanner = (geoTagMessage) => {
    const { type, q, geotagMsg } = this.state;
    if (type === 'platform' && q && geotagMsg) {
      return `Displaying properties marked "sold" by end-user${this.getGeotagFilter()} ${geoTagMessage}.`;
    }
    return `Displaying properties marked "sold" by end-user${this.getGeotagFilter()}.`;
  };

  renderPendingBanner(geoTagMessage) {
    const { type, q, geotagMsg } = this.state;
    if (type === 'platform' && q && geotagMsg) {
      return `Displaying properties and added by end-users/ approved from "On-hold" properties${this.getGeotagFilter()} ${geoTagMessage}.`;
    }
    return `Displaying properties added by end-users/ approved from "On-hold" properties${this.getGeotagFilter()}.`;
  }

  renderOnHoldBanner(geoTagMessage) {
    const { type, q, geotagMsg } = this.state;
    if (type === 'platform' && q && geotagMsg) {
      return `Displaying properties and added from “Riders App”, “Admin Portal” and “Wanted Leads”${this.getGeotagFilter()} ${geoTagMessage}.`;
    }
    return `Displaying properties added from "Riders App", "Admin Portal"
                  and "Wanted Leads"${this.getGeotagFilter()}.`;
  }

  renderGeotaggingBanner(geoTagMessage) {
    const { type, q, geotagMsg } = this.state;
    if (type === 'platform' && q && geotagMsg) {
      return `Displaying properties and shown as verified listings on Graana.com. Reverify
                  Geotag after 15 days for rent and 1 month for Sale to show badge again ${geoTagMessage}.`;
    }
    return `Displaying geotagged properties shown as verified listings on Graana.com. Reverify
                  Geotag after 15 days for rent and 1 month for Sale to show badge again.`;
  }

  renderOutdatedRentBanner(geoTagMessage) {
    const { type, q, geotagMsg } = this.state;
    if (type === 'platform' && q && geotagMsg) {
      return `Displaying properties outdated after one month for Rent ${geoTagMessage} .`;
    }
    return `Displaying properties outdated after one month for Rent.`;
  }

  renderAgencyDropdown(agencyValue) {
    const { agencyType } = this.state;
    return (
      <UncontrolledButtonDropdown style={{ marginLeft: 5, marginBottom: 5 }}>
        <Button
          id="caret"
          color={agencyType === 'noAgency' || agencyType === 'agency' ? 'primary' : 'default'}
          onClick={() => this.bringProperties(null)}
        >
          {agencyValue}
        </Button>
        <DropdownToggle
          caret
          color={agencyType === 'noAgency' || agencyType === 'agency' ? 'primary' : 'default'}
        />
        <DropdownMenu>
          <DropdownItem onClick={() => this.bringProperties('noAgency')}>No Agency</DropdownItem>
          <DropdownItem onClick={() => this.bringProperties('agency')}>Agency</DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  }

  renderGeotagDropdown(geotagValue) {
    const { geotagButton } = this.state;
    return (
      <UncontrolledButtonDropdown style={{ marginLeft: 5, marginBottom: 5 }}>
        <Button
          id="caret"
          style={{ textTransform: 'capitalize' }}
          color={`${geotagButton ? 'primary' : ''} default`}
          onClick={() => this.showGeotaggedProperties(null)}
        >
          {geotagValue}
        </Button>
        <DropdownToggle caret color={`${geotagButton ? 'primary' : ''} default`} />
        <DropdownMenu>
          <DropdownItem
            onClick={() => this.showGeotaggedProperties('geotagging')}
            color={`${geotagButton === 'geotagging' ? 'primary' : ''} default`}
          >
            Geotagged
          </DropdownItem>
          <DropdownItem
            onClick={() => this.showGeotaggedProperties(nonGeotagged)}
            color={`${geotagButton === nonGeotagged ? 'primary' : ''} default`}
          >
            Not Geotagged
          </DropdownItem>
          <DropdownItem
            onClick={() => this.showGeotaggedProperties(propsureGeotagged)}
            color={`${geotagButton === propsureGeotagged ? 'primary' : ''} default`}
          >
            PropSure Geotagged
          </DropdownItem>
          <DropdownItem
            onClick={() => this.showGeotaggedProperties(manuallyGeotagged)}
            color={`${geotagButton === manuallyGeotagged ? 'primary' : ''} default`}
          >
            Manually Geotagged
          </DropdownItem>
          <DropdownItem
            onClick={() => this.showGeotaggedProperties(expiredGeotag)}
            color={`${geotagButton === expiredGeotag ? 'primary' : ''} default`}
          >
            Geotag Expired
          </DropdownItem>
          <DropdownItem
            onClick={() => this.showGeotaggedProperties(expiredPropsureGeotagged)}
            color={`${geotagButton === expiredPropsureGeotagged ? 'primary' : ''} default`}
          >
            Expired PropSure Geotag
          </DropdownItem>
          <DropdownItem
            onClick={() => this.showGeotaggedProperties(expiredManuallyGeotagged)}
            color={`${geotagButton === expiredManuallyGeotagged ? 'primary' : ''} default`}
          >
            Expired Manual Geotag
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  }

  renderPropertySourceDropdown(propertyValue) {
    const { propertyButton, statusValue } = this.state;
    return (
      <UncontrolledButtonDropdown
        style={{
          marginLeft: 5,
          marginBottom: 5,
        }}
      >
        <Button
          id="caret"
          style={{ textTransform: 'capitalize' }}
          color={`${propertyButton ? 'primary' : ''} default`}
          onClick={() => this.showClientProperties(null)}
        >
          {propertyValue}
        </Button>
        <DropdownToggle caret color={`${propertyButton ? 'primary' : ''} default`} />
        <DropdownMenu>
          {statusValue !== 'onhold' && (
            <DropdownItem
              onClick={() => this.showClientProperties('client')}
              color={`${propertyButton === 'client' ? 'primary' : ''} default`}
            >
              Client Properties
            </DropdownItem>
          )}
          <DropdownItem
            onClick={() => this.showClientProperties('systemUser')}
            color={`${propertyButton === 'systemUser' ? 'primary' : ''} default`}
          >
            Admin Properties
          </DropdownItem>
          <DropdownItem
            onClick={() => this.showClientProperties('rider')}
            color={`${propertyButton === 'rider' ? 'primary' : ''} default`}
          >
            Rider Properties
          </DropdownItem>
          <DropdownItem
            onClick={() => this.showClientProperties('wanted')}
            color={`${propertyButton === 'wanted' ? 'primary' : ''} default`}
          >
            Wanted Properties
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  }

  render() {
    const { user, propertyID } = this.props;
    this.dataToExport = [];
    const {
      loadingBigData,
      type,
      subStatus,
      q,
      startDate,
      endDate,
      activeButton,
      activeReason,
      loading,
      properties,
      selectedPriority,
      activePage,
      pages,
      responseMessage,
      focusedInput,
      selectedBulkAction,
      selectedStatus,
      total,
      pageSize,
      activePurpose,
      transferId,
      propertyType,
      propertySubtype,
      priorityUpdated,
      priorityPropertiesUpdated,
      statusUpdated,
      statusPropertiesUpdated,
      transferUpdated,
      transferPropertiesUpdated,
      transferUpdatedError,
      transferErrorMessage,
      transferPropertiesUpdatedError,
      colSearch,
      propertyButton,
      modalVisible,
      modalAiraLogs,
      showFilters,
      dateStatus,
      rejectModal,
      rejectReason,
      statusValue,
      bulkRejectModal,
      geotagMsg,
      geotagButton,
      agencyType,
      clearButton,
      logLoading,
    } = this.state;
    let publishedButtonColor = 'default';
    let pendingButtonColor = 'default';
    let editedButtonColor = 'default';
    let rejectedButtonColor = 'default';
    let closedButtonColor = 'default';
    let expiredButtonColor = 'default';
    let rentedButtonColor = 'default';
    let soldButtonColor = 'default';
    let onholdButtonColor = 'default';
    let agencyValue = 'All Agencies';
    let propertyValue = 'All Sources';
    let geotagValue = 'Geotag Status';
    let reasonValue = 'Rejection Reason';
    if (activeReason === 'Duplicate Ad') {
      reasonValue = 'Duplicate Ad';
    }
    if (activeReason === 'Duplicate Media') {
      reasonValue = 'Duplicate Media';
    }
    if (activeReason === 'Inappropriate Media') {
      reasonValue = 'Inappropriate Media';
    }
    if (activeReason === 'Client Not Answering') {
      reasonValue = 'Client Unresponsive';
    }
    if (activeReason === 'Rejected as per Client Request') {
      reasonValue = 'Rejected for Client';
    }
    if (activeReason === 'Images not Available/Provided') {
      reasonValue = 'Images Unavailable';
    }
    if (activeReason === '3D Images are not allowed') {
      reasonValue = 'Images Prohibited';
    }
    if (activeReason === 'Territory not falling under city boundary') {
      reasonValue = 'Out of city';
    }
    if (activeReason === 'Others') {
      reasonValue = 'Others';
    }
    if (
      activeButton === 'noImage' ||
      activeButton === 'imageP' ||
      activeButton === 'outdated' ||
      activeButton === 'propsure' ||
      activeButton === 'locate_manually' ||
      activeButton === 'armsPublished' ||
      activeButton === 'publishedUnpublished' ||
      activeButton === 'publishedMediaDonebyUploader' ||
      activeButton === 'publishedMediaDonebyExecutive' ||
      activeButton === 'publishedPropertiesEligibleForReward' ||
      activeButton === 'publishedUsersEligibleForReward' ||
      activeButton === 'publishedExclusiveListings'
    ) {
      publishedButtonColor = 'danger';
    }
    if (activeButton === 'published') {
      publishedButtonColor = 'primary';
    }
    if (
      activeButton === 'no-image' ||
      activeButton === 'image' ||
      activeButton === 'armsPending' ||
      activeButton === 'pendingGeotagged' ||
      activeButton === 'pendingManualGeotagged' ||
      activeButton === 'pendingUnpublished' ||
      activeButton === 'pendingMediaDonebyUploader' ||
      activeButton === 'pendingMediaDonebyExecutive' ||
      activeButton === 'pendingPropertiesEligibleForReward' ||
      activeButton === 'pendingUsersEligibleForReward' ||
      activeButton === 'pendingCatalogingDone' ||
      activeButton === 'pendingExclusiveListings'
    ) {
      pendingButtonColor = 'danger';
    }
    if (activeButton === 'pending') {
      pendingButtonColor = 'primary';
    }
    if (
      activeButton === 'noImageEdited' ||
      activeButton === 'hasImageEdited' ||
      activeButton === 'armsEdited' ||
      activeButton === 'editedGeotagged' ||
      activeButton === 'editedManualGeotagged' ||
      activeButton === 'editedUnpublished' ||
      activeButton === 'editedMediaDonebyUploader' ||
      activeButton === 'editedMediaDonebyExecutive' ||
      activeButton === 'editedPropertiesEligibleForReward' ||
      activeButton === 'editedUsersEligibleForReward' ||
      activeButton === 'editedExclusiveListings'
    ) {
      editedButtonColor = 'danger';
    }
    if (
      activeButton === 'noImageRejected' ||
      activeButton === 'hasImageRejected' ||
      activeButton === 'armsRejected' ||
      activeButton === 'rejectedGeotagged' ||
      activeButton === 'rejectedManualGeotagged' ||
      activeButton === 'rejectedUnpublished' ||
      activeButton === 'rejectedMediaDonebyUploader' ||
      activeButton === 'rejectedMediaDonebyExecutive' ||
      activeButton === 'rejectedPropertiesEligibleForReward' ||
      activeButton === 'rejectedUsersEligibleForReward' ||
      activeButton === 'rejectedExclusiveListings'
    ) {
      rejectedButtonColor = 'danger';
    }
    if (
      activeButton === 'noImageClosed' ||
      activeButton === 'hasImageClosed' ||
      activeButton === 'propSure' ||
      activeButton === 'armsClosed' ||
      activeButton === 'locateManuallyClosed' ||
      activeButton === 'closedUnpublished' ||
      activeButton === 'closedMediaDonebyUploader' ||
      activeButton === 'closedMediaDonebyExecutive' ||
      activeButton === 'closedPropertiesEligibleForReward' ||
      activeButton === 'closedUsersEligibleForReward' ||
      activeButton === 'closedExclusiveListings'
    ) {
      closedButtonColor = 'danger';
    }
    if (
      activeButton === 'armsRented' ||
      activeButton === 'rentedGeotagged' ||
      activeButton === 'rentedManualGeotagged' ||
      activeButton === 'rentedUnpublished' ||
      activeButton === 'rentedMediaDonebyUploader' ||
      activeButton === 'rentedMediaDonebyExecutive' ||
      activeButton === 'rentedPropertiesEligibleForReward' ||
      activeButton === 'rentedUsersEligibleForReward' ||
      activeButton === 'rentedExclusiveListings'
    ) {
      rentedButtonColor = 'danger';
    }
    if (
      activeButton === 'armsExpired' ||
      activeButton === 'expiredGeotagged' ||
      activeButton === 'expiredManualGeotagged' ||
      activeButton === 'expiredUnpublished' ||
      activeButton === 'expiredMediaDonebyUploader' ||
      activeButton === 'expiredMediaDonebyExecutive' ||
      activeButton === 'expiredPropertiesEligibleForReward' ||
      activeButton === 'expiredUsersEligibleForReward' ||
      activeButton === 'expiredExclusiveListings'
    ) {
      expiredButtonColor = 'danger';
    }
    if (
      activeButton === 'armsSold' ||
      activeButton === 'soldGeotagged' ||
      activeButton === 'soldManualGeotagged' ||
      activeButton === 'soldUnpublished' ||
      activeButton === 'soldMediaDonebyUploader' ||
      activeButton === 'soldMediaDonebyExecutive' ||
      activeButton === 'soldPropertiesEligibleForReward' ||
      activeButton === 'soldUsersEligibleForReward' ||
      activeButton === 'soldExclusiveListings'
    ) {
      soldButtonColor = 'danger';
    }
    if (
      activeButton === 'armsOnHold' ||
      activeButton === 'onholdGeotagged' ||
      activeButton === 'onholdManualGeotagged' ||
      activeButton === 'onholdUnpublished' ||
      activeButton === 'onholdMediaDonebyUploader' ||
      activeButton === 'onholdMediaDonebyExecutive' ||
      activeButton === 'onholdPropertiesEligibleForReward' ||
      activeButton === 'onholdUsersEligibleForReward' ||
      activeButton === 'onholdExclusiveListings'
    ) {
      onholdButtonColor = 'danger';
    }
    if (activeButton === 'edited') {
      editedButtonColor = 'primary';
    }
    if (activeButton === 'rejected') {
      rejectedButtonColor = 'primary';
    }
    if (activeButton === 'closed') {
      closedButtonColor = 'primary';
    }
    if (activeButton === 'expired') {
      expiredButtonColor = 'primary';
    }
    if (activeButton === 'rented') {
      rentedButtonColor = 'primary';
    }
    if (activeButton === 'sold') {
      soldButtonColor = 'primary';
    }
    if (activeButton === 'onhold') {
      onholdButtonColor = 'primary';
    }
    if (geotagButton === 'geotagging') {
      geotagValue = 'Geotagged';
    } else if (geotagButton === nonGeotagged) {
      geotagValue = 'Not Geotagged';
    } else if (geotagButton === propsureGeotagged) {
      geotagValue = 'PropSure Geotagged';
    } else if (geotagButton === manuallyGeotagged) {
      geotagValue = 'Manually Geotagged';
    } else if (geotagButton === expiredGeotag) {
      geotagValue = 'Geotag Expired';
    } else if (geotagButton === expiredPropsureGeotagged) {
      geotagValue = 'Expired PropSure Geotag';
    } else if (geotagButton === expiredManuallyGeotagged) {
      geotagValue = 'Expired Manual Geotag';
    }
    if (agencyType === 'noAgency') {
      agencyValue = 'All Agencies: No Agency';
    } else if (agencyType === 'agency') {
      agencyValue = 'All Agencies: Agency';
    }
    if (propertyButton === 'client') {
      propertyValue = 'Client Properties';
    } else if (propertyButton === 'systemUser') {
      propertyValue = 'Admin Properties';
    } else if (propertyButton === 'rider') {
      propertyValue = 'Rider Properties';
    } else if (propertyButton === 'wanted') {
      propertyValue = 'Wanted Properties';
    }
    const geoTagMessage = this.getGeotagMsg();
    const searchTypes = this.getSearchTypes();
    return (
      <div className="row animated fadeIn">
        {window.removeEventListener('beforeunload', handleUnload)}
        <Modal isOpen={rejectModal} contentClassName="custom-modal-content">
          <ModalHeader
            toggle={() => {
              this.setState({ rejectModal: false, subStatus: null, rejectReason: '' });
            }}
          >
            <div className="grey">Reason for Rejection</div>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={bulkRejectModal ? this.handleBulkRejection : this.handleRejection}>
              <div className="modalInput">
                <textarea
                  className="form-control"
                  onChange={this.handleReasonInput}
                  row={6}
                  placeholder="Comments*"
                  required
                >
                  {rejectReason}
                </textarea>
                <label style={{ marginRight: 5, marginTop: 5 }} className="control-label">
                  Reasons<span style={{ color: 'red' }}>*</span>
                </label>
                <div>
                  <button
                    className={`btn btn-outline-primary ${
                      subStatus === 'Duplicate Ad' ? 'active' : ''
                    }`}
                    type="button"
                    name="Duplicate Ad"
                    style={{
                      borderRadius: 20,
                      marginLeft: 5,
                      marginTop: 5,
                      marginBottom: 5,
                      fontSize: 10,
                    }}
                    onClick={(e) => this.handleReasonButton(e)}
                  >
                    Duplicate Ad
                  </button>
                  <button
                    className={`btn btn-outline-primary ${
                      subStatus === 'Duplicate Media' ? 'active' : ''
                    }`}
                    type="button"
                    name="Duplicate Media"
                    style={{
                      borderRadius: 20,
                      marginLeft: 5,
                      marginTop: 5,
                      marginBottom: 5,
                      fontSize: 10,
                    }}
                    onClick={(e) => this.handleReasonButton(e)}
                  >
                    Duplicate Media
                  </button>
                  <button
                    className={`btn btn-outline-primary ${
                      subStatus === 'Inappropriate Media' ? 'active' : ''
                    }`}
                    type="button"
                    name="Inappropriate Media"
                    onClick={(e) => this.handleReasonButton(e)}
                    style={{
                      borderRadius: 20,
                      marginLeft: 5,
                      marginBottom: 5,
                      marginTop: 5,
                      fontSize: 10,
                    }}
                  >
                    Inappropriate Media
                  </button>
                  <button
                    className={`btn btn-outline-primary ${
                      subStatus === 'Client Not Answering' ? 'active' : ''
                    }`}
                    type="button"
                    name="Client Not Answering"
                    onClick={(e) => this.handleReasonButton(e)}
                    style={{
                      borderRadius: 20,
                      marginLeft: 5,
                      marginTop: 5,
                      marginBottom: 5,
                      fontSize: 10,
                    }}
                  >
                    Client Not Answering
                  </button>
                  <button
                    className={`btn btn-outline-primary ${
                      subStatus === 'Rejected as per Client Request' ? 'active' : ''
                    }`}
                    type="button"
                    name="Rejected as per Client Request"
                    onClick={(e) => this.handleReasonButton(e)}
                    style={{
                      borderRadius: 20,
                      marginLeft: 5,
                      marginTop: 5,
                      marginBottom: 5,
                      fontSize: 10,
                    }}
                  >
                    Rejected as per Client Request
                  </button>
                  <button
                    className={`btn btn-outline-primary ${
                      subStatus === 'Images not Available/Provided' ? 'active' : ''
                    }`}
                    type="button"
                    name="Images not Available/Provided"
                    onClick={(e) => this.handleReasonButton(e)}
                    style={{
                      borderRadius: 20,
                      marginLeft: 5,
                      marginBottom: 5,
                      marginTop: 5,
                      fontSize: 10,
                    }}
                  >
                    Images not Available/Provided
                  </button>
                  <button
                    className={`btn btn-outline-primary ${
                      subStatus === '3D Images are not allowed' ? 'active' : ''
                    }`}
                    type="button"
                    name="3D Images are not allowed"
                    onClick={(e) => this.handleReasonButton(e)}
                    style={{
                      borderRadius: 20,
                      marginLeft: 5,
                      marginBottom: 5,
                      marginTop: 5,
                      fontSize: 10,
                    }}
                  >
                    3D Images are not allowed
                  </button>
                  <button
                    className={`btn btn-outline-primary ${
                      subStatus === 'Territory not falling under city boundary' ? 'active' : ''
                    }`}
                    type="button"
                    name="Territory not falling under city boundary"
                    onClick={(e) => this.handleReasonButton(e)}
                    style={{
                      borderRadius: 20,
                      marginLeft: 5,
                      marginBottom: 5,
                      marginTop: 5,
                      fontSize: 10,
                    }}
                  >
                    Territory not falling under city boundary
                  </button>
                  <button
                    className={`btn btn-outline-primary ${subStatus === 'Others' ? 'active' : ''}`}
                    type="button"
                    name="Others"
                    onClick={(e) => this.handleReasonButton(e)}
                    style={{
                      borderRadius: 20,
                      marginLeft: 5,
                      marginBottom: 5,
                      marginTop: 5,
                      fontSize: 10,
                    }}
                  >
                    Others
                  </button>
                </div>
              </div>
              <div className="text-end">
                {' '}
                <Button className="btn btn-danger" disabled={!subStatus || !rejectReason}>
                  Reject
                </Button>
              </div>
            </form>
          </ModalBody>
        </Modal>
        <Modal size="lg" isOpen={modalVisible}>
          <ModalHeader
            toggle={() => {
              this.setState({ modalVisible: false });
            }}
          >
            <div className="grey">{this.getLogHeader()}</div>
          </ModalHeader>
          <ModalBody>
            <div className="modalInput">
              <div className="table-responsive" style={{ borderTopLeftRadius: 10 }}>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Action</th>
                      <th>System User</th>
                      <th>Previous Status</th>
                      <th>New Status</th>
                      <th>Date Updated</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modalAiraLogs && modalAiraLogs.length >= 1
                      ? modalAiraLogs.map((modalAira) => {
                          let prevData;
                          let newData;
                          if (modalAira.prevData) {
                            prevData = JSON.parse(modalAira.prevData);
                          }
                          if (modalAira.newData) {
                            newData = JSON.parse(modalAira.newData);
                          }
                          return (
                            <tr key={modalAira.id}>
                              <td style={{ textTransform: 'capitalize' }}>
                                {modalAira.activity.replace(/-/g, ' ')}
                              </td>
                              <td style={{ textTransform: 'capitalize' }}>
                                {modalAira.system_user
                                  ? `${modalAira.system_user.first_name} ${modalAira.system_user.last_name}`
                                  : ''}
                              </td>
                              <td>{prevData ? prevData.status : ''}</td>
                              <td>{newData ? newData.status : ''}</td>
                              <td style={{ whiteSpace: 'nowrap' }}>
                                {modalAira.updatedAt
                                  ? moment(modalAira.updatedAt).format(dateFormat)
                                  : ''}
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
              <Loading loading={logLoading} />
            </div>
          </ModalBody>
        </Modal>
        <div className="col-12">
          <div className="row space-1">
            <SearchBar
              type={type}
              searchTypes={searchTypes}
              q={q}
              clearButton={clearButton}
              handleSearchType={(value) => this.handleSearchType(value)}
              renderSearch={() => this.renderSearch()}
              handleSearch={() => this.handleSearch()}
              clearSearch={() => this.clearSearch()}
            />
            {this.renderStats()}

            <div className="col-sm-4 text-end">
              <CanAdd>
                {/* <Link to="/cataloging/AddProperties">
                  <button type="button" className="btn btn-success ">
                    Add Property
                  </button>
                </Link> */}
                <Link to="/cataloging/add-property">
                  <button type="button" className="btn btn-success ">
                    Add Property
                  </button>
                </Link>
              </CanAdd>
              <button
                type="button"
                className={`btn btn-default ms-2 ${showFilters ? 'btn-primary' : ''}`}
                onClick={() =>
                  this.setState((prevState) => ({ showFilters: !prevState.showFilters }))
                }
              >
                Filters
                <span className={`ms-2 ${showFilters ? 'fa fa-caret-up' : 'fa fa-caret-down'}`} />
              </button>
            </div>
          </div>

          {showFilters && (
            <div className="row space-1">
              <div className="col-sm-12 text-end">
                <select
                  className="form-select"
                  style={{
                    width: '15%',
                    display: 'inline',
                    marginRight: 10,
                  }}
                  value={colSearch}
                  onChange={(event) => this.handleDateFilter(event)}
                >
                  <option value="">Date Added</option>
                  <option value="status">Date Updated</option>
                </select>
                <strong> From: </strong> &nbsp;
                <DateRangePicker
                  isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
                  startDate={startDate}
                  endDate={endDate}
                  displayFormat={() => 'DD/MM/YYYY'}
                  startDateId="date_input_start"
                  endDateId="date_input_end"
                  onDatesChange={({ startDate: dateStart, endDate: dateEnd }) =>
                    this.onDatesChange(dateStart, dateEnd)
                  }
                  focusedInput={focusedInput}
                  onFocusChange={(input) => this.setState({ focusedInput: input })}
                  showClearDates
                  minimumNights={0}
                />
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.handleSearch()}
                >
                  Apply
                </button>
              </div>
            </div>
          )}
          {showFilters && (
            <div className="row space-1 justify-content-between">
              <div className="col-sm-5">
                <div>
                  {this.renderAgencyDropdown(agencyValue)}
                  {this.renderPropertySourceDropdown(propertyValue)}
                  {this.renderGeotagDropdown(geotagValue)}
                </div>
              </div>
              <div className="col-sm-7 text-end no-padding" style={{ paddingRight: '11px' }}>
                <UncontrolledButtonDropdown
                  style={{
                    marginRight: 5,
                    marginBottom: 5,
                  }}
                >
                  <Button
                    id="caret"
                    color={
                      propertyType === 'residential' ||
                      propertyType === 'commercial' ||
                      propertyType === 'plot'
                        ? 'primary'
                        : 'default'
                    }
                    onClick={() => this.changeType(null)}
                  >
                    Type
                    {propertyType === 'residential' ? ':Residential' : ''}
                    {propertyType === 'commercial' ? ':Commercial' : ''}
                    {propertyType === 'plot' ? ':Plots' : ''}
                  </Button>
                  <DropdownToggle
                    caret
                    color={
                      propertyType === 'residential' ||
                      propertyType === 'commercial' ||
                      propertyType === 'plot'
                        ? 'primary'
                        : 'default'
                    }
                  />
                  <DropdownMenu>
                    <DropdownItem onClick={() => this.changeType('residential')}>
                      Residential
                    </DropdownItem>
                    <DropdownItem onClick={() => this.changeType('commercial')}>
                      Commercial
                    </DropdownItem>
                    <DropdownItem onClick={() => this.changeType('plot')}>Plot</DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
                <UncontrolledButtonDropdown
                  style={{
                    marginRight: 5,
                    marginBottom: 5,
                  }}
                >
                  <Button
                    id="caret"
                    color={propertySubtype ? 'primary' : 'default'}
                    onClick={() => this.changeSubtype(null)}
                  >
                    {`Subtype: ${propertySubtype || 'Any'}`}
                  </Button>
                  <DropdownToggle caret color={propertySubtype ? 'primary' : 'default'} />
                  <DropdownMenu
                    style={{
                      zIndex: 10000,
                    }}
                  >
                    {PROPERTY_SUBTYPES[propertyType].length ? (
                      PROPERTY_SUBTYPES[propertyType].map((item) => (
                        <DropdownItem onClick={() => this.changeSubtype(item.value)}>
                          {item.name}
                        </DropdownItem>
                      ))
                    ) : (
                      <DropdownItem>Select a type first</DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
                <UncontrolledButtonDropdown
                  style={{
                    marginRight: 5,
                    marginBottom: 5,
                  }}
                >
                  <Button
                    id="caret"
                    color={pendingButtonColor}
                    onClick={() => this.handleFilter('pending')}
                  >
                    Pending
                    {activeButton === 'no-image' ? ': No Media' : ''}
                    {activeButton === 'image' ? ': With Media' : ''}
                    {activeButton === 'armsPending' ? ': Arms' : ''}
                    {activeButton === 'pendingUnpublished' ? ': Marked Hidden' : ''}
                    {activeButton === 'pendingMediaDonebyUploader' ? mediaUploaderOption : ''}
                    {activeButton === 'pendingMediaDonebyExecutive' ? mediaExecutiveOption : ''}
                    {activeButton === 'pendingPropertiesEligibleForReward'
                      ? propertiesEligibleForRewardOption
                      : ''}
                    {activeButton === 'pendingUsersEligibleForReward'
                      ? userEligibleForRewardOption
                      : ''}
                    {activeButton === 'pendingCatalogingDone' ? ': Cataloging Done' : ''}
                    {activeButton === 'pendingExclusiveListings' ? exclusiveListings : ''}
                  </Button>
                  <DropdownToggle caret color={pendingButtonColor} />
                  <DropdownMenu>
                    <DropdownItem onClick={() => this.handleFilter('no-image')}>
                      No Media
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('image')}>
                      With Media
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('armsPending')}>
                      ARMS
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('pendingUnpublished')}>
                      Marked Hidden
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('pendingMediaDonebyUploader')}>
                      Media Done By Uploader
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('pendingMediaDonebyExecutive')}>
                      Media Done By Executive
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.handleFilter('pendingPropertiesEligibleForReward')}
                    >
                      Properties Eligible For Reward
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.handleFilter('pendingUsersEligibleForReward')}
                    >
                      Users Eligible For Reward
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('pendingCatalogingDone')}>
                      Cataloging Done
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('pendingExclusiveListings')}>
                      Exclusive Listings
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
                <UncontrolledButtonDropdown
                  style={{
                    marginRight: 5,
                    marginBottom: 5,
                  }}
                >
                  <Button
                    id="caret"
                    color={publishedButtonColor}
                    onClick={() => this.handleFilter('published')}
                  >
                    Published
                    {activeButton === 'noImage' ? noMedia : ''}
                    {activeButton === 'imageP' ? withMedia : ''}
                    {activeButton === 'outdated' ? ': Outdated' : ''}
                    {activeButton === 'publishedUnpublished' ? markedHidden : ''}
                    {activeButton === 'armsPublished' ? ': ARMS' : ''}
                    {activeButton === 'publishedMediaDonebyUploader' ? mediaUploaderOption : ''}
                    {activeButton === 'publishedMediaDonebyExecutive' ? mediaExecutiveOption : ''}
                    {activeButton === 'publishedPropertiesEligibleForReward'
                      ? propertiesEligibleForRewardOption
                      : ''}
                    {activeButton === 'publishedUsersEligibleForReward'
                      ? userEligibleForRewardOption
                      : ''}
                    {activeButton === 'publishedExclusiveListings' ? exclusiveListings : ''}
                  </Button>
                  <DropdownToggle caret color={publishedButtonColor} />
                  <DropdownMenu>
                    <DropdownItem onClick={() => this.handleFilter('noImage')}>
                      No Media
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('imageP')}>
                      With Media
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('outdated')}>
                      Outdated
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('publishedUnpublished')}>
                      Marked Hidden
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('armsPublished')}>
                      ARMS
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('publishedMediaDonebyUploader')}>
                      Media Done By Uploader
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.handleFilter('publishedMediaDonebyExecutive')}
                    >
                      Media Done By Executive
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.handleFilter('publishedPropertiesEligibleForReward')}
                    >
                      Properties Eligible For Reward
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.handleFilter('publishedUsersEligibleForReward')}
                    >
                      Users Eligible For Reward
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('publishedExclusiveListings')}>
                      Exclusive Listings
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
                <UncontrolledButtonDropdown
                  style={{
                    marginRight: 5,
                    marginBottom: 5,
                  }}
                >
                  <Button
                    id="caret"
                    color={rejectedButtonColor}
                    onClick={() => this.handleFilter('rejected')}
                  >
                    Rejected
                    {activeButton === 'noImageRejected' ? noMedia : ''}
                    {activeButton === 'hasImageRejected' ? withMedia : ''}
                    {activeButton === 'armsRejected' ? ': ARMS' : ''}
                    {activeButton === 'rejectedUnpublished' ? markedHidden : ''}
                    {activeButton === 'rejectedMediaDonebyUploader' ? mediaUploaderOption : ''}
                    {activeButton === 'rejectedMediaDonebyExecutive' ? mediaExecutiveOption : ''}
                    {activeButton === 'rejectedPropertiesEligibleForReward'
                      ? propertiesEligibleForRewardOption
                      : ''}
                    {activeButton === 'rejectedUsersEligibleForReward'
                      ? userEligibleForRewardOption
                      : ''}
                    {activeButton === 'rejectedExclusiveListings' ? exclusiveListings : ''}
                  </Button>
                  <DropdownToggle caret color={rejectedButtonColor} />
                  <DropdownMenu>
                    <DropdownItem onClick={() => this.handleFilter('noImageRejected')}>
                      No Media
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('hasImageRejected')}>
                      With Media
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('armsRejected')}>
                      ARMS
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('rejectedUnpublished')}>
                      Marked Hidden
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('rejectedMediaDonebyUploader')}>
                      Media Done By Uploader
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('rejectedMediaDonebyExecutive')}>
                      Media Done By Executive
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.handleFilter('rejectedPropertiesEligibleForReward')}
                    >
                      Properties Eligible For Reward
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.handleFilter('rejectedUsersEligibleForReward')}
                    >
                      Users Eligible For Reward
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('rejectedExclusiveListings')}>
                      Exclusive Listings
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
                <UncontrolledButtonDropdown
                  style={{
                    marginRight: 5,
                    marginBottom: 5,
                  }}
                >
                  <Button
                    id="caret"
                    color={closedButtonColor}
                    onClick={() => this.handleFilter('closed')}
                  >
                    Closed
                    {activeButton === 'noImageClosed' ? noMedia : ''}
                    {activeButton === 'hasImageClosed' ? withMedia : ''}
                    {activeButton === 'armsClosed' ? ': ARMS' : ''}
                    {activeButton === 'closedUnpublished' ? markedHidden : ''}
                    {activeButton === 'closedMediaDonebyUploader' ? mediaUploaderOption : ''}
                    {activeButton === 'closedMediaDonebyExecutive' ? mediaExecutiveOption : ''}
                    {activeButton === 'closedPropertiesEligibleForReward'
                      ? propertiesEligibleForRewardOption
                      : ''}
                    {activeButton === 'closedUsersEligibleForReward'
                      ? userEligibleForRewardOption
                      : ''}
                    {activeButton === 'closedExclusiveListings' ? exclusiveListings : ''}
                  </Button>
                  <DropdownToggle caret color={closedButtonColor} />
                  <DropdownMenu>
                    <DropdownItem onClick={() => this.handleFilter('noImageClosed')}>
                      No Media
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('hasImageClosed')}>
                      With Media
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('closedUnpublished')}>
                      Marked Hidden
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('armsClosed')}>
                      ARMS
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('closedMediaDonebyUploader')}>
                      Media Done By Uploader
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('closedMediaDonebyExecutive')}>
                      Media Done By Executive
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.handleFilter('closedPropertiesEligibleForReward')}
                    >
                      Properties Eligible For Reward
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('closedUsersEligibleForReward')}>
                      Users Eligible For Reward
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('closedExclusiveListings')}>
                      Exclusive Listings
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
                <UncontrolledButtonDropdown
                  style={{
                    marginRight: 5,
                    marginBottom: 5,
                  }}
                >
                  <Button
                    id="caret"
                    color={expiredButtonColor}
                    onClick={() => this.handleFilter('expired')}
                  >
                    Expired
                    {activeButton === 'armsExpired' ? ': ARMS' : ''}
                    {activeButton === 'expiredUnpublished' ? markedHidden : ''}
                    {activeButton === 'expiredMediaDonebyUploader' ? mediaUploaderOption : ''}
                    {activeButton === 'expiredMediaDonebyExecutive' ? mediaExecutiveOption : ''}
                    {activeButton === 'expiredPropertiesEligibleForReward'
                      ? propertiesEligibleForRewardOption
                      : ''}
                    {activeButton === 'expiredUsersEligibleForReward'
                      ? userEligibleForRewardOption
                      : ''}
                    {activeButton === 'expiredExclusiveListings' ? exclusiveListings : ''}
                  </Button>
                  <DropdownToggle caret color={expiredButtonColor} />
                  <DropdownMenu>
                    <DropdownItem onClick={() => this.handleFilter('armsExpired')}>
                      ARMS
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('expiredUnpublished')}>
                      Marked Hidden
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('expiredMediaDonebyUploader')}>
                      Media Done By Uploader
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('expiredMediaDonebyExecutive')}>
                      Media Done By Executive
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.handleFilter('expiredPropertiesEligibleForReward')}
                    >
                      Properties Eligible For Reward
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.handleFilter('expiredUsersEligibleForReward')}
                    >
                      Users Eligible For Reward
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('expiredExclusiveListings')}>
                      Exclusive Listings
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
                <UncontrolledButtonDropdown
                  style={{
                    marginRight: 5,
                    marginBottom: 5,
                  }}
                >
                  <Button
                    id="caret"
                    color={editedButtonColor}
                    onClick={() => this.handleFilter('edited')}
                  >
                    Edited
                    {activeButton === 'noImageEdited' ? noMedia : ''}
                    {activeButton === 'hasImageEdited' ? withMedia : ''}
                    {activeButton === 'armsEdited' ? ': ARMS' : ''}
                    {activeButton === 'editedUnpublished' ? markedHidden : ''}
                    {activeButton === 'editedMediaDonebyUploader' ? mediaUploaderOption : ''}
                    {activeButton === 'editedMediaDonebyExecutive' ? mediaExecutiveOption : ''}
                    {activeButton === 'editedPropertiesEligibleForReward'
                      ? propertiesEligibleForRewardOption
                      : ''}
                    {activeButton === 'editedUsersEligibleForReward'
                      ? userEligibleForRewardOption
                      : ''}
                    {activeButton === 'editedExclusiveListings' ? exclusiveListings : ''}
                  </Button>
                  <DropdownToggle caret color={editedButtonColor} />
                  <DropdownMenu>
                    <DropdownItem onClick={() => this.handleFilter('noImageEdited')}>
                      No Media
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('hasImageEdited')}>
                      With Media
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('armsEdited')}>
                      ARMS
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('editedUnpublished')}>
                      Marked Hidden
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('editedMediaDonebyUploader')}>
                      Media Done By Uploader
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('editedMediaDonebyExecutive')}>
                      Media Done By Executive
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.handleFilter('editedPropertiesEligibleForReward')}
                    >
                      Properties Eligible For Reward
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('editedUsersEligibleForReward')}>
                      Users Eligible For Reward
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('editedExclusiveListings')}>
                      Exclusive Listings
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
                <UncontrolledButtonDropdown
                  style={{
                    marginRight: 5,
                    marginBottom: 5,
                  }}
                >
                  <Button
                    id="caret"
                    color={rentedButtonColor}
                    onClick={() => this.handleFilter('rented')}
                  >
                    Rented
                    {activeButton === 'armsRented' ? ': ARMS' : ''}
                    {activeButton === 'rentedUnpublished' ? markedHidden : ''}
                    {activeButton === 'rentedMediaDonebyUploader' ? mediaUploaderOption : ''}
                    {activeButton === 'rentedMediaDonebyExecutive' ? mediaExecutiveOption : ''}
                    {activeButton === 'rentedPropertiesEligibleForReward'
                      ? propertiesEligibleForRewardOption
                      : ''}
                    {activeButton === 'rentedUsersEligibleForReward'
                      ? userEligibleForRewardOption
                      : ''}
                    {activeButton === 'rentedExclusiveListings' ? exclusiveListings : ''}
                  </Button>
                  <DropdownToggle caret color={rentedButtonColor} />
                  <DropdownMenu>
                    <DropdownItem onClick={() => this.handleFilter('armsRented')}>
                      ARMS
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('rentedUnpublished')}>
                      Marked Hidden
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('rentedMediaDonebyUploader')}>
                      Media Done By Uploader
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('rentedMediaDonebyExecutive')}>
                      Media Done By Executive
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.handleFilter('rentedPropertiesEligibleForReward')}
                    >
                      Properties Eligible For Reward
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('rentedUsersEligibleForReward')}>
                      Users Eligible For Reward
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('rentedExclusiveListings')}>
                      Exclusive Listings
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
                <UncontrolledButtonDropdown
                  style={{
                    marginRight: 5,
                    marginBottom: 5,
                  }}
                >
                  <Button
                    id="caret"
                    color={soldButtonColor}
                    onClick={() => this.handleFilter('sold')}
                  >
                    Sold
                    {activeButton === 'armsSold' ? ': ARMS' : ''}
                    {activeButton === 'soldUnpublished' ? markedHidden : ''}
                    {activeButton === 'soldMediaDonebyUploader' ? mediaUploaderOption : ''}
                    {activeButton === 'soldMediaDonebyExecutive' ? mediaExecutiveOption : ''}
                    {activeButton === 'soldPropertiesEligibleForReward'
                      ? propertiesEligibleForRewardOption
                      : ''}
                    {activeButton === 'soldUsersEligibleForReward'
                      ? userEligibleForRewardOption
                      : ''}
                    {activeButton === 'soldExclusiveListings' ? exclusiveListings : ''}
                  </Button>
                  <DropdownToggle caret color={soldButtonColor} />
                  <DropdownMenu>
                    <DropdownItem onClick={() => this.handleFilter('armsSold')}>ARMS</DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('soldUnpublished')}>
                      Marked Hidden
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('soldMediaDonebyUploader')}>
                      Media Done By Uploader
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('soldMediaDonebyExecutive')}>
                      Media Done By Executive
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.handleFilter('soldPropertiesEligibleForReward')}
                    >
                      Properties Eligible For Reward
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('soldUsersEligibleForReward')}>
                      Users Eligible For Reward
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('soldExclusiveListings')}>
                      Exclusive Listings
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
                <UncontrolledButtonDropdown
                  style={{
                    marginRight: 5,
                    marginBottom: 5,
                  }}
                >
                  <Button
                    id="caret"
                    color={onholdButtonColor}
                    onClick={() => this.handleFilter('onhold')}
                  >
                    On Hold
                    {activeButton === 'armsOnHold' ? ': ARMS' : ''}
                    {activeButton === 'onholdUnpublished' ? markedHidden : ''}
                    {activeButton === 'onholdMediaDonebyUploader' ? mediaUploaderOption : ''}
                    {activeButton === 'onholdMediaDonebyExecutive' ? mediaExecutiveOption : ''}
                    {activeButton === 'onholdPropertiesEligibleForReward'
                      ? propertiesEligibleForRewardOption
                      : ''}
                    {activeButton === 'onholdUsersEligibleForReward'
                      ? userEligibleForRewardOption
                      : ''}
                    {activeButton === 'onholdExclusiveListings' ? exclusiveListings : ''}
                  </Button>
                  <DropdownToggle caret color={onholdButtonColor} />
                  <DropdownMenu>
                    <DropdownItem onClick={() => this.handleFilter('armsOnHold')}>
                      ARMS
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('onholdUnpublished')}>
                      Marked Hidden
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('onholdMediaDonebyUploader')}>
                      Media Done By Uploader
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('onholdMediaDonebyExecutive')}>
                      Media Done By Executive
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.handleFilter('onholdPropertiesEligibleForReward')}
                    >
                      Properties Eligible For Reward
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('onholdUsersEligibleForReward')}>
                      Users Eligible For Reward
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleFilter('onholdExclusiveListings')}>
                      Exclusive Listings
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
                <button
                  type="button"
                  style={{
                    marginRight: 5,
                    marginBottom: 5,
                  }}
                  className={`${activeButton === 'refresh' ? 'btn-primary' : ''} btn btn-default`}
                  onClick={() => this.handleRefresh('refresh')}
                >
                  Refreshed
                </button>
              </div>
            </div>
          )}
          {this.getMediaUploaderBanner(activeButton)}
          {this.getNoMediaBanner(activeButton)}
          {this.getWithMediaBanner(activeButton)}
          {this.getMediaExecutiveBanner(activeButton)}
          {this.getARMSBanner(activeButton)}
          {this.getUserEligibleBanner(activeButton)}
          {this.getCatalogingDoneBanner(activeButton)}
          {this.getPropertiesEligibleBanner(activeButton)}
          {this.getMarkedHiddenBanner(activeButton)}

          {dateStatus ? (
            <div className="row justify-content-between">
              <div className="col-12 text-center">
                <div className="alert alert-primary" role="alert">
                  {this.renderDateBanner(geoTagMessage)}
                </div>
              </div>
            </div>
          ) : null}
          {activeButton === 'expired' ? (
            <div className="row space-1 justify-content-between">
              <div className="col-12 text-center">
                <div className="alert alert-primary" role="alert">
                  {this.renderExpiredBanner(geoTagMessage)}
                </div>
              </div>
            </div>
          ) : null}
          {activeButton === 'pending' ? (
            <div className="row space-1 justify-content-between">
              <div className="col-12 text-center">
                <div className="alert alert-primary" role="alert">
                  {this.renderPendingBanner(geoTagMessage)}
                </div>
              </div>
            </div>
          ) : null}
          {activeButton === 'published' ? (
            <div className="row space-1 justify-content-between">
              <div className="col-12 text-center">
                <div className="alert alert-primary" role="alert">
                  {this.renderPublishedBanner(geoTagMessage)}
                </div>
              </div>
            </div>
          ) : null}
          {activeButton === 'rejected' ? (
            <div className="row space-1 justify-content-between">
              <div className="col-12 text-center">
                <div className="alert alert-primary" role="alert">
                  {this.renderRejectedBanner(geoTagMessage)}
                </div>
              </div>
            </div>
          ) : null}
          {activeButton === 'closed' ? (
            <div className="row space-1 justify-content-between">
              <div className="col-12 text-center">
                <div className="alert alert-primary" role="alert">
                  {this.renderClosedBanner(geoTagMessage)}
                </div>
              </div>
            </div>
          ) : null}
          {activeButton === 'refresh' ? (
            <div className="row space-1 justify-content-between">
              <div className="col-12 text-center">
                <div className="alert alert-primary" role="alert">
                  {this.renderRefreshedBanner(geoTagMessage)}
                </div>
              </div>
            </div>
          ) : null}
          {activeButton === 'edited' ? (
            <div className="row space-1 justify-content-between">
              <div className="col-12 text-center">
                <div className="alert alert-primary" role="alert">
                  {this.renderEditedBanner(geoTagMessage)}
                </div>
              </div>
            </div>
          ) : null}
          {activeButton === 'rented' ? (
            <div className="row space-1 justify-content-between">
              <div className="col-12 text-center">
                <div className="alert alert-primary" role="alert">
                  {this.renderRentedBanner(geoTagMessage)}
                </div>
              </div>
            </div>
          ) : null}
          {activeButton === 'sold' ? (
            <div className="row space-1 justify-content-between">
              <div className="col-12 text-center">
                <div className="alert alert-primary" role="alert">
                  {this.renderSoldBanner(geoTagMessage)}
                </div>
              </div>
            </div>
          ) : null}
          {activeButton === 'onhold' ? (
            <div className="row space-1 justify-content-between">
              <div className="col-12 text-center">
                <div className="alert alert-primary" role="alert">
                  {this.renderOnHoldBanner(geoTagMessage)}
                </div>
              </div>
            </div>
          ) : null}
          {activeButton === 'propsure' ||
          activeButton === 'propSure' ||
          activeButton === 'locate_manually' ? (
            <div className="row space-1 justify-content-between">
              <div className="col-12 text-center">
                <div className="alert alert-primary" role="alert">
                  {this.renderGeotaggingBanner(geoTagMessage)}
                </div>
              </div>
            </div>
          ) : null}
          {activeButton === 'outdated' && activePurpose === 'rent' ? (
            <div className="row space-1 justify-content-between">
              <div className="col-12 text-center">
                <div className="alert alert-primary" role="alert">
                  {this.renderOutdatedRentBanner(geoTagMessage)}
                </div>
              </div>
            </div>
          ) : null}
          {activeButton === 'outdated' && activePurpose === 'sale' ? (
            <div className="row space-1 justify-content-between">
              <div className="col-12 text-center">
                <div className="alert alert-primary" role="alert">
                  {type === 'platform' && q && geotagMsg
                    ? `Displaying properties outdated after two months for Sale ${geoTagMessage}.`
                    : `Displaying properties outdated after two months for Sale.`}
                </div>
              </div>
            </div>
          ) : null}
          {priorityUpdated ? (
            <div className="row space-1 justify-content-between">
              <div
                ref={(ref) => {
                  this.priorityUpdatedRef = ref;
                }}
                className="col-12"
              >
                <div className="alert alert-success text-center" role="alert">
                  Priority for selected property updated successfully.
                </div>
              </div>
            </div>
          ) : null}
          {priorityPropertiesUpdated ? (
            <div className="row space-1 justify-content-between">
              <div
                ref={(ref) => {
                  this.priorityPropertiesUpdatedRef = ref;
                }}
                className="col-12"
              >
                <div className="text-center alert alert-success" role="alert">
                  Priority for selected properties updated successfully.
                </div>
              </div>
            </div>
          ) : null}
          {statusUpdated ? (
            <div className="row space-1 justify-content-between">
              <div
                ref={(ref) => {
                  this.statusUpdatedRef = ref;
                }}
                className="col-12"
              >
                <div className="text-center alert alert-success" role="alert">
                  Status for selected property updated successfully.
                </div>
              </div>
            </div>
          ) : null}
          {statusPropertiesUpdated ? (
            <div className="row space-1 justify-content-between">
              <div
                ref={(ref) => {
                  this.statusPropertiesUpdatedRef = ref;
                }}
                className="col-12"
              >
                <div className="text-center alert alert-success" role="alert">
                  Status for selected properties updated successfully.
                </div>
              </div>
            </div>
          ) : null}
          {transferUpdated || transferUpdatedError ? (
            <div className="row space-1 justify-content-between">
              <div
                ref={(ref) => {
                  this.transferUpdatedRef = ref;
                }}
                className="col-12"
              >
                <div
                  className={`alert text-center${
                    transferUpdatedError ? 'alert-danger' : 'alert-success'
                  }`}
                  role="alert"
                >{`${
                  transferUpdatedError
                    ? transferErrorMessage
                    : 'Selected property&apos;s ownership transferred successfully'
                }`}</div>
              </div>
            </div>
          ) : null}
          {transferPropertiesUpdated || transferPropertiesUpdatedError ? (
            <div className="row space-1 justify-content-between">
              <div
                ref={(ref) => {
                  this.transferPropertiesUpdatedRef = ref;
                }}
                className="col-12"
              >
                <div
                  className={`text-center alert${
                    transferPropertiesUpdatedError ? 'alert-danger' : 'alert-success'
                  }`}
                  role="alert"
                >{`${
                  transferPropertiesUpdatedError
                    ? transferErrorMessage
                    : 'Selected properties&apos; ownership transferred successfully'
                }`}</div>
              </div>
            </div>
          ) : null}
          {this.renderLoading()}
          <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
            <Nav tabs style={{ borderBottom: 'none' }}>
              {activeButton !== 'outdated' ? (
                <NavItem>
                  <NavLink
                    className={`${activePurpose === null ? 'active' : ''}`}
                    onClick={() => {
                      this.changePurpose(null);
                    }}
                    style={{ color: '#ed9e3d', fontWeight: '600' }}
                  >
                    All
                  </NavLink>
                </NavItem>
              ) : null}
              <NavItem>
                <NavLink
                  className={`${activePurpose === 'sale' ? 'active' : ''}`}
                  onClick={() => {
                    this.changePurpose('sale');
                  }}
                  style={{ color: '#28a745', fontWeight: '600' }}
                >
                  Sale
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activePurpose === 'rent' ? 'active' : ''}`}
                  onClick={() => {
                    this.changePurpose('rent');
                  }}
                  style={{ color: '#28a745', fontWeight: '600' }}
                >
                  Rent
                </NavLink>
              </NavItem>
            </Nav>

            <div style={{ alignSelf: 'flex-end' }}>
              {(activeButton === 'rejected' ||
                activeButton === 'noImageRejected' ||
                activeButton === 'hasImageRejected' ||
                activeButton === 'armsRejected' ||
                activeButton === 'rejectedGeotagged' ||
                activeButton === 'rejectedManualGeotagged' ||
                activeButton === 'rejectedUnpublished' ||
                activeButton === 'rejectedMediaDonebyUploader' ||
                activeButton === 'rejectedMediaDonebyExecutive' ||
                activeButton === 'rejectedPropertiesEligibleForReward' ||
                activeButton === 'rejectedUsersEligibleForReward') &&
                showFilters && (
                  <UncontrolledButtonDropdown className={total ? 'custom-margin' : 'margin'}>
                    <Button
                      id="caret"
                      color={`${activeReason ? 'primary' : ''} default`}
                      onClick={() => this.handleReason(null)}
                    >
                      {reasonValue}
                    </Button>
                    <DropdownToggle caret color={`${activeReason ? 'primary' : ''} default`} />
                    <DropdownMenu>
                      <DropdownItem onClick={() => this.handleReason('Duplicate Ad')}>
                        Duplicate Ad
                      </DropdownItem>
                      <DropdownItem onClick={() => this.handleReason('Duplicate Media')}>
                        Duplicate Media
                      </DropdownItem>
                      <DropdownItem onClick={() => this.handleReason('Inappropriate Media')}>
                        Inappropriate Media
                      </DropdownItem>
                      <DropdownItem onClick={() => this.handleReason('Client Not Answering')}>
                        Client Unresponsive
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => this.handleReason('Rejected as per Client Request')}
                      >
                        Rejected for Client
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => this.handleReason('Images not Available/Provided')}
                      >
                        Images Unavailable
                      </DropdownItem>
                      <DropdownItem onClick={() => this.handleReason('3D Images are not allowed')}>
                        Images Prohibited
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          this.handleReason('Territory not falling under city boundary')
                        }
                      >
                        Out of city
                      </DropdownItem>
                      <DropdownItem onClick={() => this.handleReason('Others')}>
                        Others
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                )}
              {total
                ? `Showing ${(activePage - 1) * pageSize + 1} -
                ${activePage * pageSize >= total ? total : activePage * pageSize} / ${total}`
                : null}{' '}
            </div>
          </div>
          <div className="table-responsive">
            <table
              id="tableWanted"
              className="table "
              style={!loading ? { minHeight: '570px' } : null}
            >
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={
                        properties.length &&
                        properties.filter((prop) => prop.selected).length === properties.length
                      }
                      onChange={(e) => this.selectAllRecords(e)}
                    />
                  </th>
                  <th> </th>
                  <th>Id</th>
                  <th>Purpose</th>
                  <th>Type</th>
                  <th>Agency</th>
                  {activeButton !== 'refresh' ? <th>Address</th> : null}
                  {activeButton !== 'refresh' ? <th>Area</th> : null}
                  {activeButton !== 'refresh' ? <th>City</th> : null}

                  {activeButton !== 'refresh' ? <th>Size</th> : null}
                  {activeButton !== 'refresh' ? <th>Size Unit</th> : null}
                  {activeButton !== 'refresh' ? <th>Price</th> : null}
                  {activeButton === 'property' ? <th>Grade</th> : null}
                  <th>Posted By</th>
                  <th>Status</th>
                  {activeButton === 'rejected' ||
                  activeButton === 'rejectedUnpublished' ||
                  activeButton === 'noImageRejected' ||
                  activeButton === 'hasImageRejected' ||
                  activeButton === 'armsRejected' ||
                  activeButton === 'rejectedMediaDonebyExecutive' ||
                  activeButton === 'rejectedPropertiesEligibleForReward' ||
                  activeButton === 'rejectedMediaDonebyUploader' ||
                  activeButton === 'rejectedUsersEligibleForReward' ? (
                    <th>Reason</th>
                  ) : null}
                  {statusValue === 'pending' ? <th>Cataloger</th> : null}
                  {activeButton === 'refresh' ? <th>Refreshed</th> : null}
                  <th>Date added</th>
                  {statusValue === 'published' && activeButton !== 'refresh' ? (
                    <th>Date published</th>
                  ) : null}
                  {statusValue === 'published' ? <th>Published by</th> : null}
                  {statusValue === 'onhold' ? <th>Rider</th> : null}
                  {statusValue === 'edited' ? <th>Edited at</th> : null}
                  {statusValue === 'rejected' ? <th>Rejected at</th> : null}
                  {statusValue === 'closed' ? <th>Closed at</th> : null}
                  {statusValue === 'expired' ? <th>Expired at</th> : null}
                  {statusValue === 'rented' ? <th>Date Rented</th> : null}
                  {statusValue === 'sold' ? <th>Date Sold</th> : null}
                  {activeButton !== 'refresh' ? <th>Media</th> : null}
                  {activeButton !== 'refresh' && statusValue !== 'onhold' ? <th>Catalog</th> : null}
                  {activeButton !== 'refresh' ? <th>Do Not Publish</th> : null}
                  <th>Custom Title</th>
                </tr>
              </thead>
              <tbody>
                {properties && properties.length >= 1 ? (
                  properties.map((property, index) => {
                    let propertyImagesNames = '';
                    let publishDate = '';
                    let faSurveyor = false;
                    let refreshDate;
                    if (property.refresh_histories) {
                      const refreshLength = property.refresh_histories.length;
                      if (
                        property.refresh_histories[refreshLength - 1] &&
                        property.refresh_histories[refreshLength - 1].refresh_date
                      ) {
                        refreshDate = moment(
                          property.refresh_histories[refreshLength - 1].refresh_date
                        ).format(dateFormat);
                      }
                    }
                    const features = JSON.parse(property.features || '{}');
                    const images = property.property_images;
                    if (property.rider) {
                      if (property.arms) {
                        faSurveyor = true;
                      } else {
                        faSurveyor = false;
                      }
                    }
                    if (property.status_changed_at) {
                      publishDate = moment(property.status_changed_at).format(dateFormat);
                    } else {
                      publishDate = moment(property.updated_at).format(dateFormat);
                    }
                    if (images && images.length) {
                      images.map((image) => {
                        if (image.url) {
                          const arrayLength = image.url.split('/'); // returns length of array
                          const imageNameExtension = image.url.split('/')[arrayLength.length - 1]; // return name with extension
                          const imgName = imageNameExtension.split('.');
                          const imageName = imgName[0]; // returns name only without extension
                          propertyImagesNames =
                            propertyImagesNames === ''
                              ? `${imageName}`
                              : `${propertyImagesNames};${imageName}`;
                          return imageName;
                        }
                        return 'nullFileError';
                      });
                    }
                    let rowData = [];
                    rowData = propertiesAllRowData(property, index, features, propertyImagesNames);
                    if (property.selected) {
                      this.dataToExport.push(rowData);
                    }
                    if (propertyID && propertyID.id) {
                      propertyID.id = parseInt(propertyID.id, 10);
                    }
                    return (
                      <tr
                        ref={
                          propertyID && propertyID.id === property.id
                            ? (r) => {
                                this.myRef = r;
                              }
                            : null
                        }
                        key={property.id}
                        className={`hover__for__btns ${property.outDated ? 'disabled' : ''} ${
                          (property.type === 'plot' &&
                            activeButton === 'noImage' &&
                            property.property_images &&
                            property.property_images.length &&
                            property.property_images.length < 5) ||
                          (property.type === 'plot' &&
                            activeButton === 'noImage' &&
                            !property.property_images.length)
                            ? 'imageBankPlot'
                            : ''
                        } ${propertyID && propertyID.id === property.id ? 'rowFilter' : ''}`}
                      >
                        <td>
                          <input
                            type="checkbox"
                            name="selected_properties"
                            checked={property.selected ? property.selected : false}
                            onChange={(e) => this.selectRecord(e, property)}
                            style={{ marginRight: 15 }}
                          />
                        </td>
                        <td>
                          {property.propsure_verified ? (
                            <svg
                              style={{ height: '30px' }}
                              viewBox="0 0 440 495"
                              id={`P${index}verified_img`}
                            >
                              <use xlinkHref="/img/propsure_badge.svg#Capa_1" />
                            </svg>
                          ) : null}
                          {property.geotagged_date && !property.propsure_verified ? (
                            <svg
                              style={{ height: '30px', filter: 'grayscale(1)' }}
                              viewBox="0 0 440 495"
                              id={`P${index}verified_img`}
                            >
                              <use xlinkHref="/img/propsure_badge.svg#Capa_1" />
                            </svg>
                          ) : null}
                        </td>
                        <td>
                          {property.wanted_id ? (
                            <span className="badge rounded-pill bg-danger">Wanted</span>
                          ) : null}
                          {property.arms ? (
                            <span
                              style={{ marginLeft: '6px' }}
                              className="badge rounded-pill bg-primary"
                            >
                              ARMS
                            </span>
                          ) : null}
                          <a
                            href={`${this.getUrl()}${rowData.city
                              .toLowerCase()
                              .split(' ')
                              .join('-')}-${rowData.area
                              .toLowerCase()
                              .split(' ')
                              .join('-')
                              .split('/')
                              .join('~')}-${
                              property.custom_title
                                ? property.custom_title
                                    .toLowerCase()
                                    .split(' ')
                                    .join('-')
                                    .split('.')
                                    .join('_')
                                : property.title
                                    .toLowerCase()
                                    .split(' ')
                                    .join('-')
                                    .split('.')
                                    .join('_')
                            }-${rowData.property_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: 'blue', textDecoration: 'underline' }}
                          >
                            {activeButton === 'refresh'
                              ? property.id
                              : `           ${rowData.property_id}`}
                          </a>
                        </td>
                        <td>{activeButton === 'refresh' ? property.purpose : rowData.purpose}</td>
                        <td>{activeButton === 'refresh' ? property.subtype : rowData.subtype}</td>
                        {activeButton !== 'refresh' ? (
                          <td>{rowData.agency}</td>
                        ) : (
                          <td>{property.agency ? property.agency.name : ''}</td>
                        )}
                        {activeButton === 'refresh' ? (
                          <td>{property.user ? property.user.email : ''}</td>
                        ) : null}
                        {activeButton === 'refresh' ? null : (
                          <td style={{ textTransform: 'capitalize' }}>
                            {property.categorized_address && property.categorized_address.length > 0
                              ? property.categorized_address.map(
                                  (address) =>
                                    `${address.type.replace('_', ' ')} ${address.value}, `
                                )
                              : property.address}
                          </td>
                        )}
                        {activeButton === 'refresh' ? null : <td>{rowData.area}</td>}
                        {activeButton === 'refresh' ? null : <td>{rowData.city}</td>}
                        {activeButton === 'refresh' ? null : <td>{rowData.size}</td>}
                        {activeButton === 'refresh' ? null : <td>{rowData.size_unit}</td>}
                        {activeButton === 'refresh' ? null : <td>{convertPrice(rowData.price)}</td>}
                        {activeButton === 'property' ? <td>{rowData.grade}</td> : null}
                        {activeButton !== 'refresh' ? (
                          <td>
                            {property.user && property.user.disabled ? (
                              <span className="badge rounded-pill bg-danger">Deactivated</span>
                            ) : (
                              this.jazzUsersBadge(property)
                            )}
                            {rowData.posted_by}
                          </td>
                        ) : null}
                        <td>{activeButton === 'refresh' ? property.status : rowData.status}</td>
                        {activeButton === 'rejected' ||
                        activeButton === 'rejectedUnpublished' ||
                        activeButton === 'noImageRejected' ||
                        activeButton === 'hasImageRejected' ||
                        activeButton === 'armsRejected' ||
                        activeButton === 'rejectedMediaDonebyExecutive' ||
                        activeButton === 'rejectedPropertiesEligibleForReward' ||
                        activeButton === 'rejectedMediaDonebyUploader' ||
                        activeButton === 'rejectedUsersEligibleForReward' ? (
                          <td>{property.substatus}</td>
                        ) : null}

                        {statusValue === 'pending' ? (
                          <td>{property.system_user ? property.system_user.email : ''}</td>
                        ) : null}
                        {activeButton === 'refresh' ? (
                          <td style={{ whiteSpace: 'nowrap' }}>{refreshDate}</td>
                        ) : null}
                        {activeButton === 'refresh' ? (
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {moment(property.old_created_at).format(dateFormat)}
                          </td>
                        ) : null}
                        {activeButton !== 'refresh' ? (
                          <td style={{ whiteSpace: 'nowrap' }}>{rowData.date_added}</td>
                        ) : null}
                        {activeButton === 'pendin' ? (
                          <td>
                            <span>
                              <i
                                className="fa fa-info-circle graana-red"
                                id={`P${index}_dateTooltip`}
                                style={{
                                  marginLeft: '5px',
                                  cursor: 'pointer',
                                }}
                              />
                              <UncontrolledTooltip placement="top" target={`P${index}_dateTooltip`}>
                                <div className="text-end">
                                  {moment(property.createdAt).fromNow()}
                                </div>
                              </UncontrolledTooltip>
                            </span>
                          </td>
                        ) : null}
                        {statusValue === 'published' && activeButton !== 'refresh' ? (
                          <td style={{ whiteSpace: 'nowrap' }}>{publishDate}</td>
                        ) : null}
                        {statusValue === 'published' ? (
                          <td>
                            {property.system_user
                              ? `${property.system_user.first_name} ${property.system_user.last_name}`
                              : null}
                          </td>
                        ) : null}
                        {statusValue === 'onhold' ? <td>{rowData.rider}</td> : null}
                        {statusValue === 'edited' ? (
                          <td style={{ whiteSpace: 'nowrap' }}>{rowData.edited_at}</td>
                        ) : null}
                        {statusValue === 'rejected' ? (
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {property.status_changed_at
                              ? moment(property.status_changed_at).format(dateFormat)
                              : moment(property.updated_at).format(dateFormat)}
                          </td>
                        ) : null}
                        {statusValue === 'closed' ? (
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {property.status_changed_at
                              ? moment(property.status_changed_at).format(dateFormat)
                              : moment(property.updated_at).format(dateFormat)}
                          </td>
                        ) : null}
                        {statusValue === 'expired' ? (
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {property.expires_at
                              ? moment(property.expires_at).format(dateFormat)
                              : ''}
                          </td>
                        ) : null}
                        {statusValue === 'rented' ? (
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {property.status_changed_at
                              ? moment(property.status_changed_at).format(dateFormat)
                              : moment(property.updated_at).format(dateFormat)}
                          </td>
                        ) : null}
                        {statusValue === 'sold' ? (
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {property.status_changed_at
                              ? moment(property.status_changed_at).format(dateFormat)
                              : moment(property.updated_at).format(dateFormat)}
                          </td>
                        ) : null}
                        {activeButton !== 'refresh' ? (
                          <td style={{ textAlign: 'center' }}>{property.totalImages}</td>
                        ) : null}
                        {activeButton !== 'refresh' && statusValue !== 'onhold' ? (
                          <td style={{ textAlign: 'center' }}>
                            <div className="media_icon logo-wrapper">
                              <svg
                                className={property.catalogue ? 'verified' : statusNonVerified}
                                viewBox="0 0 630 630"
                                id={`P${index}verified_img`}
                              >
                                <use xlinkHref="/img/verified_checkmark.svg#Capa_1" />
                              </svg>
                            </div>
                          </td>
                        ) : null}
                        {activeButton !== 'refresh' ? (
                          <td style={{ textAlign: 'center' }}>
                            <div className="media_icon logo-wrapper">
                              <svg
                                className={
                                  property.await_publishing ? 'verified' : statusNonVerified
                                }
                                viewBox="0 0 630 630"
                                id={`P${index}verified_img`}
                              >
                                <use xlinkHref="/img/verified_checkmark.svg#Capa_1" />
                              </svg>
                            </div>
                          </td>
                        ) : null}
                        <td style={{ textAlign: 'center' }}>
                          <div className="media_icon logo-wrapper">
                            <UncontrolledTooltip placement="top" target={`title${index}`}>
                              <div>
                                {property.custom_title_generated
                                  ? 'Title added by End User.'
                                  : 'Title generated by System or Internal User.'}
                              </div>
                            </UncontrolledTooltip>
                            <svg
                              className={
                                property.custom_title && property.custom_title !== ''
                                  ? 'verified'
                                  : statusNonVerified
                              }
                              viewBox="0 0 630 630"
                              id={`title${index}`}
                            >
                              <use xlinkHref="/img/verified_checkmark.svg#Capa_1" />
                            </svg>
                          </div>
                        </td>
                        {activeButton !== 'refresh' ? (
                          <CanEdit>
                            <td colSpan="10" className="cus-z-index" style={{ padding: 0 }}>
                              <div
                                className={`action-cus ${
                                  activeButton === 'rejected'
                                    ? 'property-rejected-filter'
                                    : 'property-filter'
                                }`}
                              >
                                <CanView>
                                  <UncontrolledDropdown
                                    style={{
                                      marginLeft: 5,
                                      marginBottom: 5,
                                      display: 'inline-block',
                                    }}
                                  >
                                    <DropdownToggle caret color="primary">
                                      Action
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {activeButton !== 'refresh' && (
                                        <DropdownItem
                                          style={{ border: 'none' }}
                                          onClick={() => this.showLogs(property.id)}
                                        >
                                          Log History
                                        </DropdownItem>
                                      )}
                                      {property &&
                                        property.geotagged_date &&
                                        !property.propsure_verified && (
                                          <DropdownItem
                                            onClick={(e) => {
                                              this.setState(
                                                {
                                                  propsureId: [property.id],
                                                },
                                                () => {
                                                  this.verifyGeotag(e);
                                                }
                                              );
                                            }}
                                            style={{ border: 'none' }}
                                          >
                                            Verify Geotag
                                          </DropdownItem>
                                        )}
                                      {activeButton !== 'refresh' && (
                                        <Link
                                          to={{
                                            pathname: `/cataloging/property-details/${property.id}`,
                                            state: { url: '/cataloging' },
                                          }}
                                          style={{ textDecoration: 'none' }}
                                        >
                                          <DropdownItem style={{ border: 'none' }}>
                                            Property Details
                                          </DropdownItem>
                                        </Link>
                                      )}
                                      {((activeButton !== 'refresh' && !faSurveyor) ||
                                        (faSurveyor &&
                                          activeButton !== 'refresh' &&
                                          property.status !== 'onhold')) &&
                                        ((property.user && !property.user.disabled) ||
                                          !property.user) && (
                                          <Link
                                            style={{ textDecoration: 'none' }}
                                            to={`/cataloging/edit-property/${property.id}`}
                                          >
                                            <DropdownItem style={{ border: 'none' }}>
                                              Edit Property
                                            </DropdownItem>
                                          </Link>
                                        )}
                                      {property.status === 'pending' &&
                                      property.edited_at === null &&
                                      !property.catalogue ? (
                                        <DropdownItem
                                          style={{ border: 'none' }}
                                          onClick={() => this.handleCataloging(property.id)}
                                        >
                                          Cataloging Done
                                        </DropdownItem>
                                      ) : null}
                                      {(property.status === 'pending' &&
                                        property.edited_at === null) ||
                                      (property.edited_at &&
                                        property.status !== 'published' &&
                                        property.status !== 'closed' &&
                                        property.status !== 'sold') ? (
                                        <DropdownItem
                                          onClick={() =>
                                            this.handleStatus('published', property.id)
                                          }
                                          style={{ border: 'none' }}
                                        >
                                          Publish
                                        </DropdownItem>
                                      ) : null}
                                      {(property.edited_at &&
                                        property.status !== 'published' &&
                                        property.status !== 'closed' &&
                                        property.status !== 'sold') ||
                                      property.status === 'published' ? (
                                        <DropdownItem
                                          onClick={() => this.handleStatus('closed', property.id)}
                                          style={{ border: 'none' }}
                                        >
                                          Close
                                        </DropdownItem>
                                      ) : null}
                                      {(property.status === 'published' ||
                                        property.status === 'rejected' ||
                                        property.status === 'closed' ||
                                        property.status === 'onhold') &&
                                      activeButton !== 'armsOnHold' ? (
                                        <div id="pending">
                                          <DropdownItem
                                            id="pending"
                                            onClick={() =>
                                              this.handleStatus('pending', property.id)
                                            }
                                            style={{
                                              border: 'none',
                                              cursor:
                                                !property.purpose ||
                                                !property.user_id ||
                                                !property.area_id ||
                                                !property.city_id ||
                                                !property.phone ||
                                                !property.price ||
                                                !property.size
                                                  ? 'default'
                                                  : 'pointer',
                                            }}
                                            disabled={
                                              !!(
                                                !property.purpose ||
                                                !property.user_id ||
                                                !property.area_id ||
                                                !property.city_id ||
                                                !property.phone ||
                                                !property.price ||
                                                !property.size
                                              )
                                            }
                                          >
                                            Pending
                                            {!property.purpose ||
                                            !property.user_id ||
                                            !property.area_id ||
                                            !property.city_id ||
                                            !property.phone ||
                                            !property.price ||
                                            !property.size ? (
                                              <span>
                                                <i
                                                  className="fa fa-info-circle graana-red"
                                                  id={`P${index}_dateTooltip`}
                                                  style={{
                                                    marginLeft: '5px',
                                                    cursor: 'default',
                                                    color: 'red',
                                                  }}
                                                />
                                                <UncontrolledTooltip
                                                  placement="top"
                                                  target={`P${index}_dateTooltip`}
                                                >
                                                  <div>
                                                    Please edit property and fill mandatory fields.
                                                  </div>
                                                </UncontrolledTooltip>
                                              </span>
                                            ) : null}
                                          </DropdownItem>
                                        </div>
                                      ) : null}

                                      {property.status === 'published' ? (
                                        <DropdownItem
                                          onClick={() => this.handleRefreshStatus(property.id)}
                                          style={{ border: 'none' }}
                                        >
                                          Refresh
                                        </DropdownItem>
                                      ) : null}
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </CanView>
                                <CanView>
                                  {property.status === 'pending' ||
                                  property.status === 'published' ||
                                  property.status === 'onhold' ? (
                                    <Button
                                      onClick={() => this.handleReject('rejected', property.id)}
                                      style={{ marginLeft: 5 }}
                                      className="btn"
                                      color="danger"
                                    >
                                      Reject
                                    </Button>
                                  ) : null}
                                </CanView>
                              </div>
                            </td>
                          </CanEdit>
                        ) : null}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="15" className="text-center">
                      {responseMessage}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="text-center">
            <ReactPaginate
              previousLabel="‹"
              nextLabel="›"
              forcePage={activePage - 1}
              breakLabel={
                <span
                  role="presentation"
                  className="customPage"
                  tabIndex="-1"
                  onClick={(e) => e.preventDefault()}
                >
                  ...
                </span>
              }
              breakClassName="break-me"
              pageCount={pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageSelect}
              containerClassName="pagination"
              subContainerClassName="pages pagination"
              activeClassName="active"
            />
            {properties.some((prop) => prop.selected && prop.user && !prop.user.disabled) ? (
              <div className="page-bulk-actions">
                <div className="input-group">
                  <select
                    name="bulk_action"
                    className="form-select custom-select"
                    value={selectedBulkAction}
                    onChange={(e) =>
                      this.setState({
                        selectedBulkAction: e.target.value,
                        selectedPriority: undefined,
                        selectedStatus: undefined,
                      })
                    }
                  >
                    <option value="">Bulk Actions</option>
                    <option value="priority">Priority</option>
                    <option value="status">Status</option>
                    <option value="transfer">Transfer</option>
                    <option value="export">Export CSV</option>
                  </select>
                  {selectedBulkAction && selectedBulkAction === 'priority' ? (
                    <select
                      name="priority"
                      className="form-select custom-select"
                      value={selectedPriority}
                      onChange={(e) => this.setState({ selectedPriority: e.target.value })}
                    >
                      <option value="">Select Priority</option>
                      {PRIORITY_OPTIONS.map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                  ) : null}
                  {selectedBulkAction && selectedBulkAction === 'status' ? (
                    <select
                      name="status"
                      className="form-select custom-select"
                      value={selectedStatus}
                      onChange={(e) => this.setState({ selectedStatus: e.target.value })}
                    >
                      <option value="">Select Status</option>
                      {!activeButton ? window.alert('Select status tab') : null}
                      {(statusValue === 'published' ||
                        statusValue === 'rejected' ||
                        statusValue === 'closed' ||
                        statusValue === 'noImage' ||
                        statusValue === 'imageP' ||
                        statusValue === 'onhold') &&
                        activeButton !== 'refresh' &&
                        activeButton !== 'armsOnHold' && <option value="pending">Pending</option>}
                      {statusValue === 'pending' && activeButton !== 'refresh' && (
                        <option value="published">Publish</option>
                      )}
                      {(statusValue === 'pending' ||
                        statusValue === 'onhold' ||
                        statusValue === 'published') &&
                        activeButton !== 'refresh' && <option value="rejected">Reject</option>}
                      {statusValue === 'edited' && activeButton !== 'refresh' && (
                        <option value="published">Publish</option>
                      )}
                      {statusValue === 'edited' && activeButton !== 'refresh' && (
                        <option value="closed">Close</option>
                      )}
                      {statusValue === 'edited' && activeButton !== 'refresh' && (
                        <option value="rejected">Reject</option>
                      )}
                      {statusValue === 'published' && activeButton !== 'refresh' && (
                        <option value="closed">Close</option>
                      )}
                      {statusValue === 'published' && activeButton !== 'refresh' && (
                        <option value="refresh">Refresh</option>
                      )}
                      {statusValue === 'pending' &&
                      properties.find((prop) => !prop.catalogue && prop.selected) ? (
                        <option value="cataloging">Cataloging Done</option>
                      ) : null}
                    </select>
                  ) : null}
                  {selectedBulkAction && selectedBulkAction === 'transfer' ? (
                    <input
                      name="transferId"
                      className="form-control"
                      placeholder="User Id"
                      value={transferId}
                      onChange={(e) => this.setState({ transferId: e.target.value })}
                    />
                  ) : null}
                  {transferId ? (
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-md btn-success"
                        onClick={this.transferProperty}
                      >
                        Save
                      </button>
                    </div>
                  ) : null}
                  {selectedPriority || selectedStatus ? (
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-md btn-success"
                        onClick={selectedPriority ? this.savePriority : this.saveStatus}
                      >
                        Save
                      </button>
                    </div>
                  ) : null}
                  {selectedBulkAction && selectedBulkAction === 'export' ? (
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-md btn-success"
                        onClick={this.getCurrentCSV}
                      >
                        Download
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
            {(user && user.superadmin) ||
            (startDate && endDate && endDate.diff(startDate, 'days') < 32) ? (
              <button type="button" className="download-all btn btn-success" onClick={this.getCSV}>
                Download All&nbsp;
                <i className={`fa ${loadingBigData ? 'fa-spinner fa-pulse red' : 'fa-download'}`} />
              </button>
            ) : null}
            <select
              name="pageSize"
              className="form-select custom-select pagination-page-size"
              onChange={(event) => this.handlePageSize(event)}
              required
              value={pageSize}
            >
              {PAGE_SIZE_OPTIONS.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

Properties.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  propertyParams: PropTypes.instanceOf(Object).isRequired,
  propertyID: PropTypes.instanceOf(Object).isRequired,
  back: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  propertyParams: state.propertyParams.propertyParams,
  propertyID: state.propertyID.propertyID,
  back: state.back.back,
});

export default connect(mapStateToProps)(Properties);
