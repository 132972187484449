/** @format */

import React, { Component, Suspense, lazy } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import Cookie from 'js-cookie';
import ReactGA from 'react-ga4';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import axios from './utils/axiosInstance';

// ACTIONS
import { getUserWithRoutes } from './common/actions';

// Components
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Breadcrumb from './components/Breadcrumb';
import Aside from './components/Aside';
import Footer from './components/Footer';
import * as types from './static/_types';
import NotFound from './components/NotFound';
import Loader from './components/Loader';

// ACL HOC
import { RequireRole } from './hoc/RequireRole';

// Pages
import ErrorBoundary from './containers/Page500';

// Containers
import AiraLogs from './containers/AiraLogs';

// import SEODescription from './containers/SEODescription';

import AdvancedProjects from './containers/AdvancedProjects';
// import AdvancedProjectsForm from './containers/AdvancedProjectsForm';
// import AdvancedProjectsListings from './containers/AdvancedProjectsListings';
// import AdvancedProjectsDetails from './containers/AdvancedProjectsDetails';
// import AdvancedProjectsListingsForm from './containers/AdvancedProjectsListingsForm';
// import AdvancedProjectsListingsDetail from './containers/AdvancedProjectsListingsDetail';
// import GraanaGuru from './containers/GraanaGuru';
// import GraanaGuruForm from './containers/GraanaGuruForm';
// import PropertyVerification from './containers/PropertyVerification';
// import UserContactForm from './containers/UserContactForm';
// import WantedVoiceLeads from './containers/WantedVoiceLeads';
// import MetaInfo from './containers/MetaInfo';
// import MetaInfoListings from './containers/MetaInfoListings';
// import MetaInfoForm from './containers/MetaInfoForm';
// import RoiOffers from './containers/RoiOffers';
// import RoiForm from './containers/RoiForm';
// import HotListing from './containers/HotListing';
// import AddHotListing from './containers/AddHotListing';
// import LeadShiftManagementForm from './containers/LeadShiftManagementForm';
// import LeadShiftManagement from './containers/LeadShiftManagement';
// import Areas from './containers/Areas';
// import AreaGroup from './containers/AreaGroup';
// import AreaGroupForm from './containers/AreaGroupForm';
// import AreaForm from './containers/AreaForm';
// import Amenities from './containers/Amenities';
// import SavedProperties from './containers/SavedProperties';
// import MatchProperties from './containers/MatchProperties';
// import GeotaggedProperties from './containers/GeotaggedProperties';
// import Agencies from './containers/Agencies';
// import AgencyDashboard from './containers/AgencyDashboard';
// import AssignAgents from './containers/AssignAgents';
// import AgencyForm from './containers/AgencyForm';
// import AgencyCredit from './containers/AgencyCredit';
// import AreaPartners from './containers/AreaPartners';
// import Developers from './containers/Developers';
// import Projects from './containers/Projects';
// import SetProjectPriority from './containers/SetProjectPriority';
// import BookingDetail from './containers/BookingDetail';
import Properties from './containers/Properties';
// import SellRentoutProperties from './containers/SellRentoutProperties';
import AddProperties from './containers/AddProperties';
// import AddProperty from './pagesRevamp/property';
// import AreaTable from './pagesRevamp/area';

// import AreaHistory from './pagesRevamp/areaHistory';
// import AreaSeo from './componentsRevamp/Area/AreaSeo/AddSeo';
// import AreasMedia from './componentsRevamp/Area/AreasMedia/AreaMediaForm';
// import ChildAreas from './pagesRevamp/childAreas';
// import AddArea from './componentsRevamp/Area/AddAreaForm';
// import AreaDetail from './componentsRevamp/Area/AreaDetail';
// import AreaGuideForm from './componentsRevamp/Area/AreaGuide/AreaGuideForm';
// import DisputeTable from './pagesRevamp/dispute';
// import DisputeDetails from './pagesRevamp/disputeDetails';
import PropertyForm from './containers/PropertyForm';
// import PropertyDetail from './containers/PropertyDetail';
import UserList from './containers/UserList';
// import UserForm from './containers/UserForm';
// import UserCredit from './containers/UserCredit';
// import UserDetails from './containers/UserDetails';
// import UserPackageForm from './containers/UserPackageForm';
import AdSpace from './containers/AdSapce';
import Complaints from './containers/Complaints';
// import ComplaintDetails from './containers/ComplaintDetials';
// import CoverAndFooter from './containers/CoverAndFooter';
// import CoverForm from './containers/CoverForm';
// import AreaResource from './containers/AreaResource';
// import AgencyDetails from './containers/AgencyDetails';
// import AgencyAgents from './containers/AgencyAgents';
// import PropertyRequests from './containers/PropertyRequests';
// import RejectedCreditRequest from './containers/RejectedCreditRequest';
// import RejectPremiumRequest from './containers/RejectPremiumRequest';
// import CreditRequests from './containers/CreditRequests';
// import CreditAssign from './containers/CreditAssign';
// import CreditRequestDetail from './containers/CreditRequestDetail';
// import MopProperties from './containers/MopProperties';
// import EditPremium from './containers/EditPremium';
// import ProjectListingEdit from './containers/ProjectListingEdit';
// import ZonalStats from './containers/ZonalStats';
// import PackageDetail from './containers/PackageDetail';
// import AddActivityDetails from './containers/AddActivityDetails';
// import ActivitiesList from './containers/ActivitiesList';
// import Inquiries from './containers/Inquiries';
// import ProjectListing from './containers/ProjectListing';
// import ProjectListingResources from './containers/ProjectListingResources';
// import ProjectListingAmenities from './containers/ProjectListingAmenities';
import SystemUserList from './containers/SystemUserList';
// import SystemUserForm from './containers/SystemUserForm';
// import SystemUserProfileForm from './containers/SystemUserProfileForm';
// import RidersList from './containers/RidersList';
// import RidersForm from './containers/RidersForm';
import Wanted from './containers/Wanted';
// import WantedProjects from './containers/WantedProjects';
// import Feedback from './containers/Feedback';
// import Cities from './containers/Cities';
// import CityForm from './containers/CityForm';
// import CityResource from './containers/CityResource';
// import AreaImagesBank from './containers/AreaImagesBank';
// import Departments from './containers/Departments';
// import DepartmentForm from './containers/DepartmentForm';
// import BuyingGuide from './containers/BuyingGuide';
// import ComparisonPrice from './containers/ComparisonPrice';
// import Tartairy from './containers/Tartairy';
// import QaList from './containers/QaList';
// import QaForm from './containers/QaForm';
// import QaUsers from './containers/QaUsers';
// import QaEdit from './containers/QaEdit';
// import Regions from './containers/Regions';
// import regionsEdit from './containers/regionsEdit';
// import Zones from './containers/Zones';
// import zonesEdit from './containers/zonesEdit';
// import CVs from './containers/CVs';
// import Home from './containers/Home';
// import AddJobPosting from './containers/AddJobPosting';
// import Customers from './containers/Customers';
// import AddCustomer from './containers/AddCustomer';
// import EditCustomer from './containers/EditCustomer';
// import PushNotificationForm from './containers/PushNotificationForm';
// import JobPostings from './containers/JobPostings';
// import EditJobPostings from './containers/EditJobPostings';
// import RWRDashboard from './containers/RwrDashboard';
// import projectResources from './componentsRevamp/projectResources';

// const AdvancedProjects = lazy(() => import('./containers/AdvancedProjects'));
const AdvancedProjectsForm = lazy(() => import('./containers/AdvancedProjectsForm'));
const AdvancedProjectsListings = lazy(() => import('./containers/AdvancedProjectsListings'));
const AdvancedProjectsDetails = lazy(() => import('./containers/AdvancedProjectsDetails'));
const AdvancedProjectsListingsForm = lazy(() =>
  import('./containers/AdvancedProjectsListingsForm')
);
const AdvancedProjectsListingsDetail = lazy(() =>
  import('./containers/AdvancedProjectsListingsDetail')
);
const GraanaGuru = lazy(() => import('./containers/GraanaGuru'));
const GraanaGuruForm = lazy(() => import('./containers/GraanaGuruForm'));
const PropertyVerification = lazy(() => import('./containers/PropertyVerification'));
const UserContactForm = lazy(() => import('./containers/UserContactForm'));
const WantedVoiceLeads = lazy(() => import('./containers/WantedVoiceLeads'));
const MetaInfo = lazy(() => import('./containers/MetaInfo'));
const MetaInfoListings = lazy(() => import('./containers/MetaInfoListings'));
const MetaInfoForm = lazy(() => import('./containers/MetaInfoForm'));
const RoiOffers = lazy(() => import('./containers/RoiOffers'));
const RoiForm = lazy(() => import('./containers/RoiForm'));
const HotListing = lazy(() => import('./containers/HotListing'));
const AddHotListing = lazy(() => import('./containers/AddHotListing'));
const LeadShiftManagementForm = lazy(() => import('./containers/LeadShiftManagementForm'));
const LeadShiftManagement = lazy(() => import('./containers/LeadShiftManagement'));
const Areas = lazy(() => import('./containers/Areas'));
const AreaGroup = lazy(() => import('./containers/AreaGroup'));
const AreaGroupForm = lazy(() => import('./containers/AreaGroupForm'));
const AreaForm = lazy(() => import('./containers/AreaForm'));
const Amenities = lazy(() => import('./containers/Amenities'));
const SavedProperties = lazy(() => import('./containers/SavedProperties'));
const MatchProperties = lazy(() => import('./containers/MatchProperties'));
const GeotaggedProperties = lazy(() => import('./containers/GeotaggedProperties'));
const Agencies = lazy(() => import('./containers/Agencies'));
const AgencyDashboard = lazy(() => import('./containers/AgencyDashboard'));
const AssignAgents = lazy(() => import('./containers/AssignAgents'));
const AgencyForm = lazy(() => import('./containers/AgencyForm'));
const AgencyCredit = lazy(() => import('./containers/AgencyCredit'));
const AreaPartners = lazy(() => import('./containers/AreaPartners'));
const Developers = lazy(() => import('./containers/Developers'));
const Projects = lazy(() => import('./containers/Projects'));
const SetProjectPriority = lazy(() => import('./containers/SetProjectPriority'));
const ProjectLandingPage = lazy(() => import('./containers/ProjectLandingPage'));
const ProjectApprovers = lazy(() => import('./containers/ProjectApprovers'));
const BookingDetail = lazy(() => import('./containers/BookingDetail'));
const SellRentoutProperties = lazy(() => import('./containers/SellRentoutProperties'));
// const AddProperties = lazy(() => import('./containers/AddProperties'));
const DisputeTable = lazy(() => import('./pagesRevamp/dispute'));
const DisputeDetails = lazy(() => import('./pagesRevamp/disputeDetails'));
// const PropertyForm = lazy(() => import('./containers/PropertyForm'));
const PropertyDetail = lazy(() => import('./containers/PropertyDetail'));
// const UserList = lazy(() => import('./containers/UserList'));
const UserForm = lazy(() => import('./containers/UserForm'));
const UserCredit = lazy(() => import('./containers/UserCredit'));
const UserDetails = lazy(() => import('./containers/UserDetails'));
const UserPackageForm = lazy(() => import('./containers/UserPackageForm'));
// const AdSpace = lazy(() => import('./containers/AdSapce'));
// const Complaints = lazy(() => import('./containers/Complaints'));
const ComplaintDetails = lazy(() => import('./containers/ComplaintDetials'));
const CoverAndFooter = lazy(() => import('./containers/CoverAndFooter'));
const CoverForm = lazy(() => import('./containers/CoverForm'));
const AreaResource = lazy(() => import('./containers/AreaResource'));
const AgencyDetails = lazy(() => import('./containers/AgencyDetails'));
const AgencyAgents = lazy(() => import('./containers/AgencyAgents'));
const PropertyRequests = lazy(() => import('./containers/PropertyRequests'));
const RejectedCreditRequest = lazy(() => import('./containers/RejectedCreditRequest'));
const RejectPremiumRequest = lazy(() => import('./containers/RejectPremiumRequest'));
const CreditRequests = lazy(() => import('./containers/CreditRequests'));
const CreditAssign = lazy(() => import('./containers/CreditAssign'));
const CreditRequestDetail = lazy(() => import('./containers/CreditRequestDetail'));
// const AiraLogs = lazy(() => import('./containers/AiraLogs'));
const SEODescription = lazy(() => import('./containers/SEODescription'));
// const Wanted = lazy(() => import('./containers/Wanted'));
// const Properties = lazy(() => import('./containers/Properties'));
const AddProperty = lazy(() => import('./pagesRevamp/property'));
const RWRDashboard = lazy(() => import('./containers/RwrDashboard'));
const EditJobPostings = lazy(() => import('./containers/EditJobPostings'));
const JobPostings = lazy(() => import('./containers/JobPostings'));
const PushNotificationForm = lazy(() => import('./containers/PushNotificationForm'));
const EditCustomer = lazy(() => import('./containers/EditCustomer'));
const AddCustomer = lazy(() => import('./containers/AddCustomer'));
const Customers = lazy(() => import('./containers/Customers'));
const AddJobPosting = lazy(() => import('./containers/AddJobPosting'));
const Home = lazy(() => import('./containers/Home'));
const CVs = lazy(() => import('./containers/CVs'));
const zonesEdit = lazy(() => import('./containers/zonesEdit'));
const Zones = lazy(() => import('./containers/Zones'));
const regionsEdit = lazy(() => import('./containers/regionsEdit'));
const Regions = lazy(() => import('./containers/Regions'));
const QaEdit = lazy(() => import('./containers/QaEdit'));
const QaUsers = lazy(() => import('./containers/QaUsers'));
const QaForm = lazy(() => import('./containers/QaForm'));
const QaList = lazy(() => import('./containers/QaList'));
const Tartairy = lazy(() => import('./containers/Tartairy'));
const ComparisonPrice = lazy(() => import('./containers/ComparisonPrice'));
const BuyingGuide = lazy(() => import('./containers/BuyingGuide'));
const DepartmentForm = lazy(() => import('./containers/DepartmentForm'));
const Departments = lazy(() => import('./containers/Departments'));
const AreaImagesBank = lazy(() => import('./containers/AreaImagesBank'));
const CityResource = lazy(() => import('./containers/CityResource'));
const CityForm = lazy(() => import('./containers/CityForm'));
const Cities = lazy(() => import('./containers/Cities'));
const Feedback = lazy(() => import('./containers/Feedback'));
const WantedProjects = lazy(() => import('./containers/WantedProjects'));
const RidersForm = lazy(() => import('./containers/RidersForm'));
const RidersList = lazy(() => import('./containers/RidersList'));
const SystemUserProfileForm = lazy(() => import('./containers/SystemUserProfileForm'));
const SystemUserForm = lazy(() => import('./containers/SystemUserForm'));
// const SystemUserList = lazy(() => import('./containers/SystemUserList'));
const ProjectListingAmenities = lazy(() => import('./containers/ProjectListingAmenities'));
const ProjectListingResources = lazy(() => import('./containers/ProjectListingResources'));
const ProjectListing = lazy(() => import('./containers/ProjectListing'));
const Inquiries = lazy(() => import('./containers/Inquiries'));
const ActivitiesList = lazy(() => import('./containers/ActivitiesList'));
const AddActivityDetails = lazy(() => import('./containers/AddActivityDetails'));
const PackageDetail = lazy(() => import('./containers/PackageDetail'));
const ZonalStats = lazy(() => import('./containers/ZonalStats'));
const ProjectListingEdit = lazy(() => import('./containers/ProjectListingEdit'));
const EditPremium = lazy(() => import('./containers/EditPremium'));
const MopProperties = lazy(() => import('./containers/MopProperties'));

const SEODescriptionContainer = RequireRole(SEODescription);
const SavedPropertiesContainer = RequireRole(SavedProperties);
const GraanaGuruContainer = RequireRole(GraanaGuru);
const GraanaGuruFormContainer = RequireRole(GraanaGuruForm);
const AdvancedProjectsContainer = RequireRole(AdvancedProjects);
const AdvancedProjectsFormContainer = RequireRole(AdvancedProjectsForm);
const AdvancedProjectsListingsContainer = RequireRole(AdvancedProjectsListings);
const AdvancedProjectsDetailsContainer = RequireRole(AdvancedProjectsDetails);
const AdvancedProjectsListingsFormContainer = RequireRole(AdvancedProjectsListingsForm);
const AdvancedProjectsListingsDetailContainer = RequireRole(AdvancedProjectsListingsDetail);
const AiraLogsContainer = RequireRole(AiraLogs);
const HomeContainer = Home;
const PropertyVerificationContainer = RequireRole(PropertyVerification);
const WantedEditPropertyContainer = RequireRole(PropertyForm);
const MatchPropertiesContainer = RequireRole(MatchProperties);
const UserContactFormContainer = RequireRole(UserContactForm);
const WantedVoiceLeadsContainer = RequireRole(WantedVoiceLeads);
const MetaInfoContainer = RequireRole(MetaInfo);
const MetaInfoListingsContainer = RequireRole(MetaInfoListings);
const MetaInfoFormContainer = RequireRole(MetaInfoForm);
const RoiOffersContainer = RequireRole(RoiOffers);
const RoiFormContainer = RequireRole(RoiForm);
const HotListingContainer = RequireRole(HotListing);
const AddHotListingContainer = RequireRole(AddHotListing);
const LeadShiftManagementContainer = RequireRole(LeadShiftManagement);
const LeadShiftManagementFormContainer = RequireRole(LeadShiftManagementForm);
const GeotaggedPropertiesContainer = RequireRole(GeotaggedProperties);
const AreaGroupContainer = RequireRole(AreaGroup);
const AreaGroupFormContainer = RequireRole(AreaGroupForm);
const CVsContainer = RequireRole(CVs);
const JobPostingContainer = RequireRole(JobPostings);
const AddJobPostingContainer = RequireRole(AddJobPosting);
const EditJobPostingsContainer = RequireRole(EditJobPostings);
const CustomersContainer = RequireRole(Customers);
const AddCustomerContainer = RequireRole(AddCustomer);
const EditCustomerContainer = RequireRole(EditCustomer);
const PushNotificationFormContainer = RequireRole(PushNotificationForm);
const AreasContainer = RequireRole(Areas);
const MOPAreasContainer = RequireRole(Areas);
const AreaFormContainer = RequireRole(AreaForm);
const MOPAreaFormContainer = RequireRole(AreaForm);
const EditAreaFormContainer = RequireRole(AreaForm);
const MOPEditAreaFormContainer = RequireRole(AreaForm);
const ProjectFormContainer = RequireRole(AreaForm);
const BookingDetailContainer = RequireRole(BookingDetail);
const EditProjectFormContainer = RequireRole(AreaForm);
const AreaAmenitiesContainer = RequireRole(Amenities);
const MOPAreaAmenitiesContainer = RequireRole(Amenities);
const AreaResourceContainer = RequireRole(AreaResource);
const MOPAreaResourceContainer = RequireRole(AreaResource);
const ProjectResourceContainer = RequireRole(AreaResource);
const MopProjectResourceContainer = RequireRole(AreaResource);
const CitiesContainer = RequireRole(Cities);
const CityFormContainer = RequireRole(CityForm);
const EditCityFormContainer = RequireRole(CityForm);
const CityResourceContainer = RequireRole(CityResource);
const ProjectListingAmenitiesContainer = RequireRole(ProjectListingAmenities);
const ProjectListingContainer = RequireRole(ProjectListing);
const ProjectListingResourcesContainer = RequireRole(ProjectListingResources);
const ProjectsContainer = RequireRole(Projects);
const SetProjectPriorityContainer = RequireRole(SetProjectPriority);
const ProjectLandingPageContainer = RequireRole(ProjectLandingPage);
const ProjectApproversContainer = RequireRole(ProjectApprovers);
const ProjectAreaPartnersContainer = RequireRole(AreaPartners);
const MopProjectAreaPartnersContainer = RequireRole(AreaPartners);
const AgenciesContainer = RequireRole(Agencies);
const AgencyDashboardContainer = RequireRole(AgencyDashboard);
const AssignAgentsContainer = RequireRole(AssignAgents);
const AgencyFormContainer = RequireRole(AgencyForm);
const EditAgencyFormContainer = RequireRole(AgencyForm);
const DeveloperFormContainer = RequireRole(AgencyForm);
const EditDeveloperFormContainer = RequireRole(AgencyForm);
const AgencyCreditContainer = RequireRole(AgencyCredit);
const AgencyDetailsContainer = RequireRole(AgencyDetails);
const AgencyAgentsContainer = RequireRole(AgencyAgents);
const UserCreditContainer = RequireRole(UserCredit);
const UserPackageFormContainer = RequireRole(UserPackageForm);
const DevelopersContainer = RequireRole(Developers);
const PropertiesContainer = RequireRole(Properties);
const SellRentoutPropertiesContainer = RequireRole(SellRentoutProperties);
const AddPropertiesContainer = RequireRole(AddProperties);
const AddPropertyContainer = RequireRole(AddProperty);
const DisputeTableContainer = RequireRole(DisputeTable);
const DisputeDetailsContainer = RequireRole(DisputeDetails);
const AddLeadContainer = RequireRole(PropertyForm);
const PropertyFormContainer = RequireRole(PropertyForm);
const WantedProjectsContainer = RequireRole(WantedProjects);
const WantedPropertyFormContainer = RequireRole(PropertyForm);
const PropertyDetailContainer = RequireRole(PropertyDetail);
const UserListContainer = RequireRole(UserList);
const UserFormContainer = RequireRole(UserForm);
const UserDetailsContainer = RequireRole(UserDetails);
const CreditRequestsContainer = RequireRole(CreditRequests);
const RejectedCreditRequestContainer = RequireRole(RejectedCreditRequest);
const RejectPremiumRequestContainer = RequireRole(RejectPremiumRequest);
const CreditAssignContainer = RequireRole(CreditAssign);
const CreditRequestDetailContainer = RequireRole(CreditRequestDetail);
const AdSpaceContainer = RequireRole(AdSpace);
const CoverAndFooterContainer = RequireRole(CoverAndFooter);
const CoverAndFooterFormContainer = RequireRole(CoverForm);
const WantedPropertiesContainer = RequireRole(Wanted);
const AgencyWantedPropertiesContainer = RequireRole(Wanted);
const PropertyRequestsContainer = RequireRole(PropertyRequests);
const MopPropertiesContainer = RequireRole(MopProperties);
const EditStandardContainer = RequireRole(EditPremium);
const EditPremiumContainer = RequireRole(EditPremium);
const EditPremiumPlusContainer = RequireRole(EditPremium);
const ZonalStatsContainer = RequireRole(ZonalStats);
const ProjectListingEditContainer = RequireRole(ProjectListingEdit);
const PackageDetailContainer = RequireRole(PackageDetail);
const AddActivityDetailsContainer = RequireRole(AddActivityDetails);
const ActivitiesListContainer = RequireRole(ActivitiesList);
const InquiriesContainer = RequireRole(Inquiries);
const FeedbackContainer = RequireRole(Feedback);
const SystemUserListContainer = RequireRole(SystemUserList);
const SystemUserFormContainer = RequireRole(SystemUserForm);
const SystemUserProfileContainer = RequireRole(SystemUserProfileForm);
const DepartmentsContainer = RequireRole(Departments);
const DepartmentFormContainer = RequireRole(DepartmentForm);
const EditDepartmentFormContainer = RequireRole(DepartmentForm);
const AreaImagesBankContainer = RequireRole(AreaImagesBank);
const ComplaintsContainer = RequireRole(Complaints);
const ComplaintDetailContainer = RequireRole(ComplaintDetails);
const RidersListContainer = RequireRole(RidersList);
const RidersFormContainer = RequireRole(RidersForm);
const BuyingGuideContainer = RequireRole(BuyingGuide);
const ComparisonPriceContainer = RequireRole(ComparisonPrice);
const TartairyContainer = RequireRole(Tartairy);
const QaListContainer = RequireRole(QaList);
const QaFormContainer = RequireRole(QaForm);
const QaUsersContainer = RequireRole(QaUsers);
const QaEditContainer = RequireRole(QaEdit);
const RegionsContainer = RequireRole(Regions);
const regionsEditContainer = RequireRole(regionsEdit);
const ZonesContainer = RequireRole(Zones);
const zonesEditContainer = RequireRole(zonesEdit);
const PropsurePropertiesMediaContainer = RequireRole(EditPremium);
const RwrDashboard = RequireRole(RWRDashboard);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      isForceReset: false,
    };
  }

  componentDidMount() {
    const { dispatch, history } = this.props;
    let decoded;
    const token = Cookie.get('graana_admin_access_token');
    if (token) {
      decoded = jwtDecode(token);
      if (
        Date.parse(moment().format('DD-MM-YYYY hh:mm:ss')) >
        Date.parse(moment.unix(decoded.exp).format('DD-MM-YYYY hh:mm:ss'))
      ) {
        Cookie.remove('graana_admin_access_token');
        history.push('/login');
      }

      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      axios.post('/api/ga/info').then((response) => {
        if (response.data.GAmeasureId) {
          ReactGA.initialize(response.data.GAmeasureId);
        }
      });
      axios
        .get('/api/user/me')
        .then((response) => {
          if (response?.data?.forceReset) {
            this.setState({
              isForceReset: true,
            });
          }
          if (
            response.data &&
            response.data.superadmin !== true &&
            response.data.department &&
            typeof response.data.department === 'string'
          ) {
            window.alert('Please contact system administrator to update your access permissions.');
            Cookie.remove('graana_admin_access_token');
            history.push('/login');
          } else if (response.data) {
            dispatch({
              type: types.SET_USER_FROM_TOKEN,
              payload: getUserWithRoutes(response.data),
            });
            this.setState({
              loading: false,
            });
          }
        })
        .catch((/* error */) => {
          this.setState({ loading: false });
          Cookie.remove('graana_admin_access_token');
          history.push('/login');
        });
    } else {
      this.setState({ loading: false });
      history.push('/login');
    }
  }

  redirectUser = () => {
    const { history } = this.props;
    Cookie.set('graana_admin_access_token_reset', `${Cookie.get('graana_admin_access_token')}`, {
      expires: 14,
    });
    history.push({
      pathname: '/reset-password',
      state: { token: Cookie.get('graana_admin_access_token') },
    });
  };

  render() {
    const { loading, isForceReset } = this.state;
    return (
      <div className="app">
        <ErrorBoundary>
          <Loader loading={loading} position="fixed" transparent />
          <Header />
          <div className="app-body">
            <Sidebar {...this.props} />
            <div className="main">
              <Breadcrumb />
              <Container fluid>
                {isForceReset ? (
                  <div className="row justify-content-between">
                    <div className="col-12 text-center">
                      <div className="alert alert-warning" role="alert">
                        30 days have been passed since your last password change. Please{' '}
                        <button type="button" className="forgetLabel" onClick={this.redirectUser}>
                          reset
                        </button>{' '}
                        your password.
                      </div>
                    </div>
                  </div>
                ) : null}

                <Suspense
                  fallback={
                    <div className="text-center" style={{ color: '#7e7e7e' }}>
                      Loading...
                    </div>
                  }
                >
                  <Switch>
                    <Route exact path="/" component={HomeContainer} />
                    <Route exact path="/opvs-requests" component={PropertyVerificationContainer} />
                    <Route
                      exact
                      path="/wanted/wanted-edit-property/:propertyId"
                      component={WantedEditPropertyContainer}
                    />
                    <Route exact path="/advanced-projects" component={AdvancedProjectsContainer} />
                    <Route
                      exact
                      path="/advanced-projects/add-project-form"
                      component={AdvancedProjectsFormContainer}
                    />
                    <Route
                      exact
                      path="/advanced-projects/edit-project-form/:projectId"
                      component={AdvancedProjectsFormContainer}
                    />
                    <Route
                      exact
                      path="/advanced-projects/project-listings/:projectId"
                      component={AdvancedProjectsListingsContainer}
                    />
                    <Route
                      exact
                      path="/advanced-projects/project-listings/add-listing-form/:projectId"
                      component={AdvancedProjectsListingsFormContainer}
                    />
                    <Route
                      exact
                      path="/advanced-projects/project-listings/edit-listing-form/:projectId/:listingId"
                      component={AdvancedProjectsListingsFormContainer}
                    />
                    <Route
                      exact
                      path="/advanced-projects/project-listings/listing-details/:projectId/:listingId"
                      component={AdvancedProjectsListingsDetailContainer}
                    />
                    <Route
                      exact
                      path="/advanced-projects/project-details/:projectId"
                      component={AdvancedProjectsDetailsContainer}
                    />
                    <Route exact path="/activity-logs" component={AiraLogsContainer} />
                    <Route
                      exact
                      path="/geotagged-properties"
                      component={GeotaggedPropertiesContainer}
                    />
                    <Route
                      exact
                      path="/graana-users/edit-user/user-contact-info/:userId"
                      component={UserContactFormContainer}
                    />
                    <Route exact path="/projects/roi-offers" component={RoiOffersContainer} />
                    <Route
                      exact
                      path="/projects/roi-offers/addOffer"
                      component={RoiFormContainer}
                    />
                    <Route
                      exact
                      path="/projects/roi-offers/edit-offer/:offerId"
                      component={RoiFormContainer}
                    />
                    <Route exact path="/area-groups" component={AreaGroupContainer} />
                    <Route
                      exact
                      path="/area-groups/area-group-form"
                      component={AreaGroupFormContainer}
                    />
                    <Route
                      exact
                      path="/wanted/edit-property/match-properties/:wantedId"
                      component={MatchPropertiesContainer}
                    />
                    <Route
                      exact
                      path="/wanted/saved-properties/:wantedId"
                      component={SavedPropertiesContainer}
                    />
                    <Route exact path="/guru-management" component={GraanaGuruContainer} />
                    <Route
                      exact
                      path="/guru-management/add-graana-guru"
                      component={GraanaGuruFormContainer}
                    />
                    <Route
                      exact
                      path="/guru-management/edit-graana-guru/:guruId"
                      component={GraanaGuruFormContainer}
                    />
                    <Route
                      exact
                      path="/area-groups/area-group-form/:areaGroupId"
                      component={AreaGroupFormContainer}
                    />
                    <Route exact path="/fresh-listings" component={HotListingContainer} />
                    <Route
                      exact
                      path="/fresh-listings/add-fresh-listing"
                      component={AddHotListingContainer}
                    />
                    <Route exact path="/seo-areas" component={AreasContainer} />
                    <Route
                      exact
                      path="/seo-areas/description/:areaId"
                      component={SEODescriptionContainer}
                    />
                    <Route exact path="/areas" component={AreasContainer} />
                    <Route
                      exact
                      path="/lead-shift-management/lead-shift-management-form"
                      component={LeadShiftManagementFormContainer}
                    />
                    <Route
                      exact
                      path="/lead-shift-management"
                      component={LeadShiftManagementContainer}
                    />
                    <Route exact path="/areas/area-form" component={AreaFormContainer} />
                    <Route
                      exact
                      path="/areas/edit-area/:areaId"
                      component={EditAreaFormContainer}
                    />
                    <Route
                      exact
                      path="/cataloging/edit-property/edit-area/:areaId"
                      component={EditAreaFormContainer}
                    />
                    <Route
                      exact
                      path="/cataloging/add-property/edit-user/:userId"
                      component={UserFormContainer}
                    />
                    <Route
                      exact
                      path="/areas/area-images-bank/:areaId"
                      component={AreaImagesBankContainer}
                    />
                    <Route
                      exact
                      path="/project-booking/booking-detail/:bookingId"
                      component={BookingDetailContainer}
                    />
                    <Route
                      exact
                      path="/areas/amenities/:areaId"
                      component={AreaAmenitiesContainer}
                    />
                    <Route
                      exact
                      path="/areas/area-resource/:areaId"
                      component={AreaResourceContainer}
                    />
                    <Route exact path="/AreasMedia" component={MOPAreasContainer} />
                    <Route
                      exact
                      path="/AreasMedia/area-images-bank/:areaId"
                      component={AreaImagesBankContainer}
                    />
                    <Route exact path="/AreasMedia/area-form" component={MOPAreaFormContainer} />
                    <Route
                      exact
                      path="/AreasMedia/edit-area/:areaId"
                      component={MOPEditAreaFormContainer}
                    />
                    <Route
                      exact
                      path="/AreasMedia/amenities/:areaId"
                      component={MOPAreaAmenitiesContainer}
                    />
                    <Route
                      exact
                      path="/AreasMedia/area-resource/:areaId"
                      component={MOPAreaResourceContainer}
                    />
                    <Route exact path="/cities" component={CitiesContainer} />
                    <Route exact path="/cities/city-form" component={CityFormContainer} />
                    <Route
                      exact
                      path="/cities/edit-city/:cityId"
                      component={EditCityFormContainer}
                    />
                    <Route
                      exact
                      path="/cities/city-resource/:cityId"
                      component={CityResourceContainer}
                    />
                    <Route exact path="/seo-cities" component={CitiesContainer} />
                    <Route
                      exact
                      path="/seo-cities/description/:cityId"
                      component={SEODescriptionContainer}
                    />
                    <Route
                      exact
                      path="/projects/listing-amenities/:projectListingId"
                      component={ProjectListingAmenitiesContainer}
                    />
                    <Route
                      exact
                      path="/projects/listing-form/:projectId"
                      component={ProjectListingContainer}
                    />
                    <Route
                      exact
                      path="/projects/listing-resources/:projectListingId"
                      component={ProjectListingResourcesContainer}
                    />
                    <Route
                      exact
                      path="/projects/project-listing-edit/:projectId/:projectListingId"
                      component={ProjectListingEditContainer}
                    />
                    <Route exact path="/projects" component={ProjectsContainer} />
                    <Route exact path="/projects/meta-info" component={MetaInfoContainer} />
                    <Route
                      exact
                      path="/projects/meta-info/edit-project-meta-info/:projectId"
                      component={MetaInfoFormContainer}
                    />
                    <Route
                      exact
                      path="/projects/meta-info/add-project-meta-info/:projectId"
                      component={MetaInfoFormContainer}
                    />
                    <Route
                      exact
                      path="/projects/meta-info/meta-info-listings/:projectId"
                      component={MetaInfoListingsContainer}
                    />
                    <Route
                      exact
                      path="/projects/meta-info/add-meta-info"
                      component={MetaInfoFormContainer}
                    />
                    <Route
                      exact
                      path="/projects/meta-info/meta-info-listings/add-meta-info-listings/:listingId/:projectId"
                      component={MetaInfoFormContainer}
                    />
                    <Route
                      exact
                      path="/projects/meta-info/meta-info-listings/edit-meta-info-listings/:listingId/:projectId"
                      component={MetaInfoFormContainer}
                    />
                    <Route exact path="/projects/project-form" component={ProjectFormContainer} />
                    <Route
                      exact
                      path="/projects/area-partners/:areaId"
                      component={ProjectAreaPartnersContainer}
                    />
                    <Route
                      exact
                      path="/mop-projects/area-partners/:areaId"
                      component={MopProjectAreaPartnersContainer}
                    />
                    <Route
                      exact
                      path="/projects/edit-project/:projectId"
                      component={EditProjectFormContainer}
                    />
                    <Route exact path="/agencies-management" component={AgenciesContainer} />
                    <Route
                      exact
                      path="/sales-dashboard/agency-dashboard/:systemUserId"
                      component={AgencyDashboardContainer}
                    />
                    <Route
                      exact
                      path="/sales-dashboard/assign-agents/:systemUserId"
                      component={AssignAgentsContainer}
                    />
                    <Route
                      exact
                      path="/agencies-management/agency-form"
                      component={AgencyFormContainer}
                    />
                    <Route
                      exact
                      path="/agencies-management/agency-credit/:agencyId"
                      component={AgencyCreditContainer}
                    />
                    <Route
                      exact
                      path="/agencies-management/agency-details/:agencyId"
                      component={AgencyDetailsContainer}
                    />
                    <Route
                      exact
                      path="/agencies-management/agency-agents/:agencyId"
                      component={AgencyAgentsContainer}
                    />
                    <Route
                      exact
                      path="/graana-users/user-credit/:userId"
                      component={UserCreditContainer}
                    />
                    <Route
                      exact
                      path="/graana-users/user-package/:userId"
                      component={UserPackageFormContainer}
                    />
                    <Route
                      exact
                      path="/agencies-management/edit-agency/:agencyId"
                      component={EditAgencyFormContainer}
                    />
                    <Route exact path="/developers-management" component={DevelopersContainer} />
                    <Route
                      exact
                      path="/developers-management/developer-form"
                      component={DeveloperFormContainer}
                    />
                    <Route
                      exact
                      path="/developers-management/edit-developer/:developerId"
                      component={EditDeveloperFormContainer}
                    />
                    <Route exact path="/cataloging" component={PropertiesContainer} />
                    <Route
                      exact
                      path="/cataloging/sell-rentout-properties"
                      component={SellRentoutPropertiesContainer}
                    />
                    {/* <Route
                    exact
                    path="/cataloging/AddProperties"
                    component={AddPropertiesContainer}
                  /> */}
                    <Route exact path="/cataloging/add-property" component={AddPropertyContainer} />
                    <Route
                      exact
                      path="/cataloging/edit-property/:propertyId"
                      component={AddPropertyContainer}
                    />
                    <Route
                      exact
                      path="/wanted/add-property/:wantedId"
                      component={AddPropertyContainer}
                    />
                    <Route exact path="/wanted-voice-leads" component={WantedVoiceLeadsContainer} />
                    <Route exact path="/invest-leads-wanted" component={WantedProjectsContainer} />
                    <Route
                      exact
                      path="/wanted/AddProperties/:wantedId"
                      component={AddPropertiesContainer}
                    />
                    <Route exact path="/wanted/addLead" component={AddLeadContainer} />
                    <Route
                      exact
                      path="/invest-leads-wanted/addCMLead"
                      component={AddLeadContainer}
                    />

                    <Route exact path="/dispute" component={DisputeTableContainer} />
                    <Route
                      exact
                      path="/dispute/dispute-details/:disputeId"
                      component={DisputeDetailsContainer}
                    />
                    <Route
                      exact
                      path="/wanted-voice-leads/voice-lead-form/:leadId"
                      component={PropertyFormContainer}
                    />
                    <Route
                      exact
                      path="/wanted-voice-leads/edit-voice-lead-form/:propertyId"
                      component={PropertyFormContainer}
                    />
                    <Route
                      exact
                      path="/customers-management/edit-customer/:customerId"
                      component={EditCustomerContainer}
                    />
                    <Route
                      exact
                      path="/push-notification-form"
                      component={PushNotificationFormContainer}
                    />
                    <Route
                      exact
                      path="/propsure-properties-media/propsure-media/:propertyId"
                      component={PropsurePropertiesMediaContainer}
                    />
                    <Route
                      exact
                      path="/wanted/edit-property/:propertyId"
                      component={WantedPropertyFormContainer}
                    />
                    <Route
                      exact
                      path="/wanted/edit-invest/:propertyId"
                      component={WantedPropertyFormContainer}
                    />
                    <Route
                      exact
                      path="/cataloging/property-details/:propertyId"
                      component={PropertyDetailContainer}
                    />
                    <Route exact path="/graana-users" component={UserListContainer} />
                    <Route
                      exact
                      path="/graana-users/edit-user/:userId"
                      component={UserFormContainer}
                    />
                    <Route
                      exact
                      path="/graana-users/user-details/:userId"
                      component={UserDetailsContainer}
                    />
                    <Route
                      exact
                      path="/projects/area-resource/:areaId"
                      component={ProjectResourceContainer}
                    />
                    <Route
                      exact
                      path="/projects/set-project-priority"
                      component={SetProjectPriorityContainer}
                    />
                    <Route
                      exact
                      path="/projects/landing-page-config"
                      component={ProjectLandingPageContainer}
                    />
                    <Route exact path="/projects/approvers" component={ProjectApproversContainer} />
                    <Route
                      exact
                      path="/mop-projects/area-resource/:areaId"
                      component={MopProjectResourceContainer}
                    />
                    <Route exact path="/credit-requests" component={CreditRequestsContainer} />
                    <Route
                      exact
                      path="/credit-requests/reject-credit-request/:id"
                      component={RejectedCreditRequestContainer}
                    />
                    <Route
                      exact
                      path="/premium-requests/reject-premium-request/:id"
                      component={RejectPremiumRequestContainer}
                    />
                    <Route
                      exact
                      path="/premium-plus-requests/reject-premium-request/:id"
                      component={RejectPremiumRequestContainer}
                    />
                    <Route
                      exact
                      path="/credit-requests/assign-credit/:id"
                      component={CreditAssignContainer}
                    />
                    <Route
                      exact
                      path="/credit-requests/credit-request-detail/:id"
                      component={CreditRequestDetailContainer}
                    />
                    <Route exact path="/featured-mega" component={AdSpaceContainer} />
                    <Route
                      exact
                      path="/featured-mega/featured-agencies"
                      component={AdSpaceContainer}
                    />
                    <Route
                      exact
                      path="/featured-mega/featured-developers"
                      component={AdSpaceContainer}
                    />
                    <Route
                      exact
                      path="/featured-mega/featured-projects"
                      component={AdSpaceContainer}
                    />
                    <Route exact path="/featured-mega/mega-projects" component={AdSpaceContainer} />
                    <Route exact path="/cover-footer-ads" component={CoverAndFooterContainer} />
                    <Route
                      exact
                      path="/cover-footer-ads/edit-cover-footer-ads/:coverAndFooterId"
                      component={CoverAndFooterFormContainer}
                    />
                    <Route
                      exact
                      path="/cover-footer-ads/cover-footer-ad-form"
                      component={CoverAndFooterFormContainer}
                    />
                    <Route exact path="/wanted" component={WantedPropertiesContainer} />
                    <Route exact path="/media-requests" component={PropertyRequestsContainer} />
                    <Route
                      exact
                      path="/media-requests/edit-property/:propertyId"
                      component={PropertyFormContainer}
                    />
                    <Route
                      exact
                      path="/media-requests/edit-standard/:propertyId"
                      component={EditPremiumContainer}
                    />
                    <Route
                      exact
                      path="/media-requests/edit-premium/:propertyId"
                      component={EditPremiumContainer}
                    />
                    <Route
                      exact
                      path="/media-requests/edit-premium-plus/:propertyId"
                      component={EditPremiumContainer}
                    />
                    <Route
                      exact
                      path="/agencies-management/agency-wanted/:agencyId"
                      component={AgencyWantedPropertiesContainer}
                    />
                    <Route exact path="/properties-media" component={MopPropertiesContainer} />
                    <Route
                      exact
                      path="/properties-media/edit-standard/:propertyId"
                      component={EditStandardContainer}
                    />
                    <Route
                      exact
                      path="/properties-media/edit-premium/:propertyId"
                      component={EditPremiumContainer}
                    />
                    <Route
                      exact
                      path="/properties-media/edit-premium-plus/:propertyId"
                      component={EditPremiumPlusContainer}
                    />
                    <Route exact path="/rwr-management" component={RwrDashboard} />
                    <Route
                      exact
                      path="/rwr-management/edit-lead/:propertyId"
                      component={WantedPropertyFormContainer}
                    />
                    <Route exact path="/careers-cvs" render={() => <CVsContainer dept="cvs" />} />
                    <Route
                      exact
                      path="/jobs-dashboard/add-job"
                      component={AddJobPostingContainer}
                    />
                    <Route
                      exact
                      path="/jobs-dashboard/edit-job/:jobId"
                      component={EditJobPostingsContainer}
                    />
                    <Route exact path="/jobs-dashboard" component={JobPostingContainer} />
                    <Route exact path="/customers-management" component={CustomersContainer} />
                    <Route
                      exact
                      path="/customers-management/add-customer"
                      component={AddCustomerContainer}
                    />
                    <Route exact path="/zonal-dashboard" component={ZonalStatsContainer} />
                    <Route
                      exact
                      path="/dashboard/package-detail/:department/:packageId"
                      component={PackageDetailContainer}
                    />
                    <Route
                      exact
                      path="/marketing-dashboard/package-detail/:department/:packageId"
                      component={PackageDetailContainer}
                    />
                    <Route
                      exact
                      path="/mop-dashboard/package-detail/:department/:packageId"
                      component={PackageDetailContainer}
                    />
                    <Route
                      exact
                      path="/dashboard/activity-details/:department/:activityId"
                      component={AddActivityDetailsContainer}
                    />
                    <Route
                      exact
                      path="/marketing-dashboard/activity-details/:department/:activityId"
                      component={AddActivityDetailsContainer}
                    />
                    <Route
                      exact
                      path="/mop-dashboard/activity-details/:department/:activityId"
                      component={AddActivityDetailsContainer}
                    />
                    <Route
                      exact
                      path="/dashboard/activities/:department"
                      component={ActivitiesListContainer}
                    />
                    <Route
                      exact
                      path="/marketing-dashboard/activities/:department"
                      component={ActivitiesListContainer}
                    />
                    <Route
                      exact
                      path="/mop-dashboard/activities/:department"
                      component={ActivitiesListContainer}
                    />
                    <Route exact path="/inquiries" component={InquiriesContainer} />
                    <Route exact path="/feedback" component={FeedbackContainer} />
                    <Route exact path="/complaints" component={ComplaintsContainer} />
                    <Route
                      exact
                      path="/complaints/complaints-details/:complaintId"
                      component={ComplaintDetailContainer}
                    />
                    <Route exact path="/users" component={SystemUserListContainer} />
                    <Route
                      exact
                      path="/users/edit-system-user/:userId"
                      component={SystemUserFormContainer}
                    />
                    <Route
                      exact
                      path="/users/add-system-user"
                      component={SystemUserFormContainer}
                    />
                    <Route
                      exact
                      path="/users/edit-profile/:userId"
                      component={SystemUserProfileContainer}
                    />
                    <Route exact path="/field-force-management" component={RidersListContainer} />
                    <Route exact path="/users/edit-rider/:userId" component={RidersFormContainer} />
                    <Route exact path="/users/add-rider" component={RidersFormContainer} />
                    <Route exact path="/permission-groups" component={DepartmentsContainer} />
                    <Route
                      exact
                      path="/permission-groups/department-form"
                      component={DepartmentFormContainer}
                    />
                    <Route
                      exact
                      path="/permission-groups/edit-department/:departmentId"
                      component={EditDepartmentFormContainer}
                    />
                    <Route
                      exact
                      path="/areas/buying-guide/:areaId"
                      component={BuyingGuideContainer}
                    />
                    <Route
                      exact
                      path="/areas/comparison-price/:areaId"
                      component={ComparisonPriceContainer}
                    />
                    <Route exact path="/areas/tartairy/:areaId" component={TartairyContainer} />
                    <Route exact path="/qa-list" component={QaListContainer} />
                    <Route exact path="/qa-list/qa-form/:listId" component={QaFormContainer} />
                    <Route exact path="/qa-users" component={QaUsersContainer} />
                    <Route exact path="/qa-users/qa-edit/:userId" component={QaEditContainer} />
                    <Route exact path="/regions" component={RegionsContainer} />
                    <Route
                      exact
                      path="/regions/regionsEdit/:region_id"
                      component={regionsEditContainer}
                    />
                    <Route exact path="/zones" component={ZonesContainer} />
                    <Route exact path="/zones/zonesEdit/:zone_id" component={zonesEditContainer} />
                    <Route exact path="/*" name="Not Found" component={NotFound} />
                  </Switch>
                </Suspense>
              </Container>
            </div>
            <Aside />
          </div>
          <Footer />
        </ErrorBoundary>
      </div>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(connect()(App));
