/** @format */

// Pagination options

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100, 500];

export const DEFAULT_PAGE_SIZE = '10';

const securityDeposit = 'Security Deposit';
const maintenanceCharges = 'Maintenance Charges';

export const VERSION_NUMBER = 'v4.7.0';

export const BACK_MESSAGE =
  'The changes you made will not be saved. Are you sure you want to leave this page?';

export const PROJECT_MAIN_FEATURES = [
  { name: 'Secure Investment', value: 'secure_investment' },
  { name: 'Accessible Location', value: 'accessible_location' },
  { name: 'Luxury Apartment', value: 'luxury_apartment' },
  { name: 'Business District', value: 'business_district' },
  { name: 'Nature Views', value: 'nature_views' },
  { name: 'Family Friendly', value: 'family_friendly' },
];

export const FLOOR_PLAN_TITLE = [
  { name: 'Basement 2', value: 'basement_two' },
  { name: 'Basement 1', value: 'basement_one' },
  { name: 'Ground Floor', value: 'ground_floor' },
  { name: '1st Floor', value: 'first_floor' },
  { name: '2nd Floor', value: 'second_floor' },
  { name: '3rd Floor', value: 'third_floor' },
  { name: '4th Floor', value: 'fourth_floor' },
  { name: '5th Floor', value: 'fifth_floor' },
  { name: '6th Floor', value: 'sixth_floor' },
  { name: '7th Floor', value: 'seventh_floor' },
  { name: '8th Floor', value: 'eighth_floor' },
  { name: '9th Floor', value: 'ninth_floor' },
  { name: '10th Floor', value: 'tenth_floor' },
  { name: '11th Floor', value: 'eleventh_floor' },
  { name: '12th Floor', value: 'twelfth_floor' },
  { name: '13th Floor', value: 'thirteenth_floor' },
  { name: '14th Floor', value: 'fourteenth_floor' },
  { name: '15th Floor', value: 'fifteenth_floor' },
  { name: '16th Floor', value: 'sixteenth_floor' },
  { name: '17th Floor', value: 'seventeenth_floor' },
  { name: '18th Floor', value: 'eighteenth_floor' },
  { name: '19th Floor', value: 'nineteenth_floor' },
  { name: '20th Floor', value: 'twentieth_floor' },
  { name: '21st Floor', value: 'twenty_first_floor' },
  { name: '22nd Floor', value: 'twenty_second_floor' },
  { name: '23rd Floor', value: 'twenty_third_floor' },
  { name: '24th Floor', value: 'twenty_fourth_floor' },
  { name: '25th Floor', value: 'twenty_fifth_floor' },
  { name: '26th Floor', value: 'twenty_sixth_floor' },
  { name: '27th Floor', value: 'twenty_seventh_floor' },
  { name: '28th Floor', value: 'twenty_eighth_floor' },
  { name: '29th Floor', value: 'twenty_ninth_floor' },
  { name: '30th Floor', value: 'thirtieth_floor' },
  { name: 'Rooftop', value: 'rooftop' },
];

export const FLOOR_PLAN_CATEGORY = [
  { name: 'Entire Floor', value: 'entire_floor' },
  { name: 'Studio', value: 'studio' },
  { name: '1 Bed', value: 'first_bed' },
  { name: '2 Bed', value: 'second_bed' },
  { name: '3 Bed', value: 'third_bed' },
  { name: '4 Bed', value: 'fourth_bed' },
  { name: '5 Bed', value: 'fifth_bed' },
  { name: 'Shop', value: 'shop' },
];

export const PROJECT_FEATURES = [
  { name: 'Electricity', value: 'electricity' },
  { name: 'Internet', value: 'internet' },
  { name: 'Security/CCTV', value: 'security' },
  { name: 'School', value: 'school' },
  { name: 'Hospital', value: 'hospital' },
  { name: 'Water Supply', value: 'waterSupply' },
  { name: 'Gas', value: 'gas' },
  { name: 'Mosque', value: 'mosque' },
  { name: 'Park/Playground', value: 'park' },
  { name: 'Gymnasium', value: 'gymnasium' },
  { name: 'Shopping Mall', value: 'shoppingMall' },
  { name: 'Banks / Financial Institute', value: 'banks' },
  { name: 'Library', value: 'library' },
  { name: 'Restaurant', value: 'restaurant' },
  { name: 'Community Club', value: 'communityClub' },
  { name: 'Emergency and Rescue', value: 'emergency' },
  { name: 'Nearby Public Transport', value: 'nearbyPublicTransport' },
];

export const BEDS = [
  { name: 'Studio', value: -1 },
  { name: '1', value: 1 },
  { name: '2', value: 2 },
  { name: '3', value: 3 },
  { name: '4', value: 4 },
  { name: '5', value: 5 },
  { name: '6', value: 6 },
  { name: '7', value: 7 },
  { name: '8', value: 8 },
  { name: '9', value: 9 },
  { name: '10+', value: 10 },
];

export const BATHS = [
  { name: '1', value: 1 },
  { name: '2', value: 2 },
  { name: '3', value: 3 },
  { name: '4', value: 4 },
  { name: '5', value: 5 },
  { name: '6', value: 6 },
  { name: '7', value: 7 },
  { name: '8', value: 8 },
  { name: '9', value: 9 },
  { name: '10+', value: 10 },
];

export const PROPERTY_FEATURES = {
  residential: {
    general: {
      year_built: true,
      bed: true,
      bath: true,
      gas: true,
      electric: true,
      counter3: {
        infoText: 'Total',
        label: 'Parking Space',
        id: 'parking_space',
        containerClass: 'space-2',
        onChange: (count) => this.setFeature('parking_space', count),
      },
    },
    features: {
      title: 'Additional Features',
      options: [
        'central heating',
        'tv lounge',
        'dining room',
        'drawing room',
        'kitchen',
        'store room',
        'central cooling',
        'lawn',
        'swimming pool',
        'fully furnished',
        'semi furnished',
        'wifi',
        'balcony',
        'laundry room',
        'servant quarter',
        'dirty kitchen',
        'elevators',
        'study room',
        'powder room',
        'security staff',
        'accessible for specially abled persons',
        'nearby landmark',
        'corner house',
        'home theatre',
      ],
    },
    utilities: ['electricity', 'gas', 'maintenance', 'water'],
  },
  plot: {
    general: {
      gas: true,
      electric: true,
    },
    features: {
      title: 'Main Features',
      options: [
        'possession',
        'corner',
        'park facing',
        'boundary wall',
        'extra land',
        'main boulevard',
        'utilities paid',
      ],
    },
    utilities: ['electricity', 'gas', 'sewerage', 'water'],
  },
  commercial: {
    general: {
      year_built: true,
      gas: true,
      electric: true,
      counter: {
        infoText: 'No of',
        label: 'Floor',
        id: 'floors',
        onChange: (count) => this.setFeature('floors', count),
      },
    },
    features: {
      title: 'Additional Features',
      options: [
        'central heating',
        'central cooling',
        'elevator/lift',
        'public parking',
        'underground parking',
        'internet',
        'cctv camera',
        'backup generator',
        'accessing boulevard',
      ],
    },
    utilities: ['electricity', 'gas', 'maintenance', 'water'],
  },
  rentalOptions: [
    {
      name: 'downpayment',
      label: 'Downpayment',
      type: 'number',
      placeholder: 'Downpayment in PKR (if any)',
    },
    {
      name: 'security_deposit',
      label: securityDeposit,
      type: 'number',
      placeholder: 'Security Deposit in PKR (if any)',
    },
    {
      name: 'maintenance_charges',
      label: maintenanceCharges,
      type: 'number',
      placeholder: 'Maintenance Charges in PKR (if any)',
    },
  ],
};

export const PRICE_OBJECTS = [
  { value: null, name: 'Any' },
  { value: 10000, name: '10000' },
  { value: 20000, name: '20000' },
  { value: 30000, name: '30000' },
  { value: 40000, name: '40000' },
  { value: 50000, name: '50000' },
  { value: 70000, name: '70000' },
  { value: 90000, name: '90000' },
  { value: 100000, name: '1 lac' },
  { value: 150000, name: '1 lac 50 Thousands' },
  { value: 200000, name: '2 lac' },
  { value: 250000, name: '2 lac 50 Thousands' },
  { value: 300000, name: '3 lac' },
  { value: 400000, name: '4 lac' },
  { value: 500000, name: '5 lac' },
  { value: 600000, name: '6 lac' },
  { value: 700000, name: '7 lac' },
  { value: 1000000, name: '10 lac' },
  { value: 1500000, name: '15 lac' },
  { value: 2500000, name: '25 lac' },
  { value: 3000000, name: '30 lac' },
  { value: 5000000, name: '50 lac' },
  { value: 7000000, name: '70 lac' },
  { value: 10000000, name: '1 crore' },
  { value: 12500000, name: '1 crore 25 lakh' },
  { value: 15000000, name: '1 crore 50 lakh' },
  { value: 20000000, name: '2 crore' },
  { value: 25000000, name: '2 crore 50 lakh' },
  { value: 30000000, name: '3 crore' },
  { value: 35000000, name: '3 crore 50 lakh' },
  { value: 40000000, name: '4 crore' },
  { value: 45000000, name: '4 crore 50 lakh' },
  { value: 50000000, name: '5 crore' },
];

export const SIZES = {
  marla: {
    min: ['Any', 3, 5, 7, 10, 12, 15, 18, 20, 25, 30, 40, 50, 70, 100, 150, 200, 300, 500, 1000],
    max: [
      'Any',
      3,
      5,
      7,
      10,
      12,
      15,
      18,
      20,
      25,
      30,
      40,
      50,
      70,
      100,
      150,
      200,
      300,
      500,
      1000,
      5000,
    ],
  },
  kanal: {
    min: ['Any', 1, 2, 3, 4, 5, 6, 7, 8, 10, 12, 15, 20, 30, 50],
    max: ['Any', 1, 2, 3, 4, 5, 6, 7, 8, 10, 12, 15, 20, 30, 50, 200],
  },
  sqft: {
    min: [
      'Any',
      400,
      470,
      820,
      1400,
      1900,
      2700,
      3300,
      4100,
      4900,
      5400,
      6800,
      8200,
      11000,
      14000,
      19000,
      27000,
      41000,
      54000,
      82000,
      136000,
      272000,
    ],
    max: [
      'Any',
      400,
      470,
      820,
      1400,
      1900,
      2700,
      3300,
      4100,
      4900,
      5400,
      6800,
      8200,
      11000,
      14000,
      19000,
      27000,
      41000,
      54000,
      82000,
      136000,
      272000,
      1361000,
    ],
  },
  sqyd: {
    min: [
      'Any',
      40,
      80,
      90,
      150,
      210,
      300,
      360,
      450,
      540,
      610,
      760,
      910,
      1200,
      2100,
      3000,
      4500,
      6100,
      9100,
      15000,
      30000,
    ],
    max: [
      'Any',
      40,
      80,
      90,
      150,
      210,
      300,
      360,
      450,
      540,
      610,
      760,
      910,
      1200,
      2100,
      3000,
      4500,
      6100,
      9100,
      15000,
      30000,
      151000,
    ],
  },
  sqm: {
    min: [
      'Any',
      80,
      130,
      180,
      300,
      380,
      460,
      510,
      620,
      760,
      1000,
      1300,
      1800,
      2500,
      3800,
      5100,
      7600,
      13000,
      25000,
    ],
    max: [
      'Any',
      80,
      130,
      180,
      300,
      380,
      460,
      510,
      620,
      760,
      1000,
      1300,
      1800,
      2500,
      3800,
      5100,
      7600,
      13000,
      25000,
      126000,
    ],
  },
  acre: {
    min: ['Any', 1, 2, 3, 4, 5, 6, 7, 8, 10, 12, 14, 16, 18, 20, 25],
    max: ['Any', 1, 2, 3, 4, 5, 6, 7, 8, 10, 12, 14, 16, 18, 20, 25],
  },
  Any: {
    min: ['Any'],
    max: ['Any'],
  },
};

const priorityOptions = [];
for (let i = 1; i <= 100; i += 1) {
  priorityOptions.push(i);
}

export const PRIORITY_OPTIONS = priorityOptions;

export const PROPERTY_SUBTYPES = {
  null: [],
  residential: [
    { value: 'house', name: 'House' },
    { value: 'guest house', name: 'Guest House' },
    { value: 'apartment', name: 'Apartment' },
    { value: 'upper portion', name: 'Upper Portion' },
    { value: 'lower portion', name: 'Lower Portion' },
    { value: 'farm house', name: 'Farm House' },
    { value: 'room', name: 'Room' },
    { value: 'penthouse', name: 'Penthouse' },
    { value: 'hotel suites', name: 'Hotel Suites' },
    { value: 'basement', name: 'Basement' },
    { value: 'annexe', name: 'Annexe' },
    { value: 'hostel', name: 'Hostel' },
    { value: 'other', name: 'Other' },
  ],
  plot: [
    { value: 'residential plot', name: 'Residential Plot' },
    { value: 'commercial plot', name: 'Commercial Plot' },
    { value: 'agricultural land', name: 'Agricultural Land' },
    { value: 'industrial land', name: 'Industrial Land' },
    { value: 'plot file', name: 'Plot File' },
    { value: 'farmhouse plot', name: 'Farmhouse Plot' },
  ],
  commercial: [
    { value: 'office', name: 'Office' },
    { value: 'shop', name: 'Shop' },
    { value: 'warehouse', name: 'Warehouse' },
    { value: 'factory', name: 'Factory' },
    { value: 'building', name: 'Building' },
    { value: 'gym', name: 'Gym' },
    { value: 'theatre', name: 'Theatre' },
    { value: 'food court', name: 'Food Court' },
    { value: 'hall', name: 'Hall' },
    { value: 'land', name: 'Land' },
    { value: 'plaza', name: 'Plaza' },
    { value: 'other', name: 'Other' },
  ],
};

export const DEFAULT_ROLES = ['manager', 'data-entry', 'view-only'];
// Editor Configs
export const EDITOR_PLUGINS = [
  'advlist autolink lists link image charmap print preview anchor',
  'searchreplace visualblocks code fullscreen',
  'insertdatetime media table paste code help wordcount',
];
export const EDITOR_TOOLBAR =
  'undo redo | formatselect | ' +
  'table link bold italic backcolor | alignleft aligncenter ' +
  'alignright alignjustify | bullist numlist outdent indent | ' +
  'removeformat | help';
export const EDITOR_TOOLBAR_DESCRIPTION = 'undo redo   | bold italic underline | bullist numlist';
export const EDITOR_STYLE = 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }';

// REACT-RTE TOOLBAR CONFIG
export const RTE_TOOLBAR_CONFIG = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    {
      label: 'Bold',
      style: 'BOLD',
      className: 'custom-css-class',
    },
    {
      label: 'Italic',
      style: 'ITALIC',
    },
    {
      label: 'Underline',
      style: 'UNDERLINE',
    },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' },
  ],

  BLOCK_TYPE_BUTTONS: [
    {
      label: 'UL',
      style: 'unordered-list-item',
    },
    {
      label: 'OL',
      style: 'ordered-list-item',
    },
  ],
};
