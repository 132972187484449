/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from '../utils/axiosInstance';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody, Button, UncontrolledTooltip } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import PhoneInput from 'react-phone-input-2';
import { SingleDatePicker } from 'react-dates';
import * as types from '../static/_types';
import { CanView, CanAdd, CanEdit, CanDelete } from '../hoc/CanDo';
import { USERS_HEADER, usersRowData } from '../common/ExportCSVData';
import {
  generateCSV,
  filterExportData,
  ellipsis,
  cnicValidation,
  handleUnload,
  setHeader,
  logPageView,
} from '../common/actions';
import { PAGE_SIZE_OPTIONS, DEFAULT_PAGE_SIZE } from '../static/_constants';
import SearchBar from '../components/SearchBar';
import Loading from '../components/Loading';

const loadingMsg = 'Loading Users...';
const btnPrimary = 'btn-primary';

class UserList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      responseMessage: loadingMsg,
      isDataChanged: false,
      users: [],
      loading: true,
      loadingBigData: false,
      focusedValue: false,
      activePage: 1,
      pages: 1,
      q: '',
      modalVisible: false,
      pageSize: DEFAULT_PAGE_SIZE,
      user: {},
      total: '',
      phoneError: false,
      userParams: [],
      activeButton: null,
      deactiveButton: true,
      activeSystem: false,
      clearButton: false,
      setClearButton: 0,
    };
    this.endPoint = '/api/user';
    this.dataToExport = [];

    this.handlePageSelect = this.handlePageSelect.bind(this);
    this.getCSV = this.getCSV.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    setHeader(dispatch, 'Graana Users');
    logPageView('Graana Users');
    this.fetchUsers();
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    const { user } = this.state;
    if (name === 'cnic') {
      const test = cnicValidation(event);
      if (!test) {
        this.clearInput();
      }
      if (event.nativeEvent && event.nativeEvent.inputType === 'deleteContentBackward') {
        user.cnic = value.replace(/-/g, '');
      } else if (test) {
        user.cnic = value.replace(/(\d{5})(\d{7})(\d{1})/, '$1-$2-$3');
      }
    } else {
      user[name] = value;
    }
    this.setState({ user, isDataChanged: true });
  };

  handleCheckBox = (event) => {
    const { user } = this.state;
    user[event.target.name] = event.target.checked;
    this.setState({ user, isDataChanged: true });
  };

  handleFilter(status) {
    this.setState(
      {
        loading: true,
        activePage: 1,
        users: [],
        responseMessage: loadingMsg,
        activeButton: status,
        deactiveButton: null,
      },
      () => {
        this.fetchUsers();
      }
    );
  }

  handleSearchType(value) {
    const { setClearButton, clearButton, q } = this.state;
    this.setState(
      {
        type: value ? value.value : null,
        q: '',
        clearButton: false,
      },
      () => {
        if (!value) {
          if (clearButton || (setClearButton === 2 && !q)) {
            this.setState({
              loading: true,
              users: [],
              activePage: 1,
              responseMessage: loadingMsg,
            });
            this.fetchUsers();
          }
        }
      }
    );
  }

  handleSystemUser() {
    this.setState(
      (prevState) => ({
        loading: true,
        activePage: 1,
        users: [],
        responseMessage: loadingMsg,
        activeSystem: !prevState.activeSystem,
      }),
      () => {
        this.fetchUsers();
      }
    );
  }

  handleActive(status) {
    this.setState(
      {
        loading: true,
        activePage: 1,
        users: [],
        responseMessage: loadingMsg,
        deactiveButton: status,
        activeButton: null,
      },
      () => {
        this.fetchUsers();
      }
    );
  }

  handlePhoneProperty(phone) {
    const { user } = this.state;
    user.phone = phone;
    if (user.phone.length < 12) {
      this.setState({ phoneError: true });
    } else {
      this.setState({ phoneError: false });
    }
    this.setState({
      user: {
        ...user,
      },
    });
    this.setState({ isDataChanged: true });
  }

  handleJazzCash(phone) {
    const { user } = this.state;
    user.account = phone;
    this.setState({
      user: {
        ...user,
      },
    });
    this.setState({ isDataChanged: true });
  }

  handleSearch() {
    const { q, type, startDate, endDate } = this.state;
    if (q && type) {
      this.setState(
        {
          loading: true,
          activePage: 1,
          users: [],
          responseMessage: loadingMsg,
        },
        () => {
          this.fetchUsers();
        }
      );
    } else if (!q && !type) {
      this.setState(
        {
          type: null,
          loading: true,
          activePage: 1,
          users: [],
          responseMessage: loadingMsg,
        },
        () => {
          this.fetchUsers();
        }
      );
    }
    if (q && type) {
      this.setState({ clearButton: true });
    }
    if (!q && !type && (startDate || endDate)) {
      this.setState({ clearButton: false, setClearButton: 0 });
    } else {
      this.setState({ setClearButton: 2 });
    }
  }

  handlePageSize(event) {
    this.setState(
      {
        pageSize: event.target.value,
        activePage: 1,
      },
      () => {
        this.fetchUsers();
      }
    );
  }

  handlePageSelect(page) {
    this.setState(
      {
        activePage: page.selected + 1,
        loading: true,
        users: [],
        responseMessage: loadingMsg,
      },
      () => {
        this.fetchUsers();
      }
    );
  }

  onDateChange(date) {
    const { user } = this.state;
    user.cnicIssuanceDate = date;
    this.setState({ dateValue: user.cnicIssuanceDate });
    this.setState({ user, isDataChanged: true });
  }

  getCSV() {
    const { loadingBigData, deactiveButton, activeButton } = this.state;
    if (!loadingBigData) {
      this.setState({ loadingBigData: true });

      axios
        .get(this.endPoint, {
          params: {
            all: true,
            active: deactiveButton === true || deactiveButton === false ? deactiveButton : null,
            deleted: activeButton === 'deleted' ? true : null,
          },
        })
        .then((response) => {
          const users = response.data.items;
          const data = [];
          if (users && users.length) {
            users.map((user, index) => {
              const rowData = usersRowData(user, index);
              data.push(rowData);
              return rowData;
            });
          }

          let dataToExport = [USERS_HEADER, ...data];

          dataToExport = filterExportData(dataToExport);

          generateCSV('Users', dataToExport);
          this.setState({ loadingBigData: false });
        });
    }
  }

  getPrevParams() {
    const { userParams } = this.props;
    this.setState({
      activePage: userParams.activePage,
      pageSize: userParams.pageSize,
      q: userParams.q,
      type: userParams.type,
      activeButton: userParams.activeButton,
      activeSystem: userParams.activeSystem,
      deactiveButton: userParams.deactiveButton,
      clearButton: userParams.clearButton,
    });
    return {
      params: {
        page: userParams.activePage,
        pageSize: userParams.pageSize,
        q: userParams.type && userParams.clearButton ? userParams.q : null,
        searchby: userParams.q ? userParams.type : null,
        deleted: userParams.activeButton === 'deleted' ? true : null,
        active:
          userParams.deactiveButton === true || userParams.deactiveButton === false
            ? userParams.deactiveButton
            : null,
        internalUsers: userParams.activeSystem ? true : null,
      },
    };
  }

  getParams() {
    const {
      activePage,
      pageSize,
      q,
      type,
      activeButton,
      deactiveButton,
      activeSystem,
      clearButton,
    } = this.state;
    this.setState(
      {
        userParams: {
          activePage,
          pageSize,
          q,
          type,
          activeButton,
          activeSystem,
          deactiveButton,
          clearButton,
        },
      },
      () => {
        const { dispatch } = this.props;
        const { userParams } = this.state;
        dispatch({
          type: types.SET_USER,
          payload: userParams,
        });
      }
    );
    return {
      params: {
        page: activePage,
        pageSize,
        q: type && clearButton ? q : null,
        searchby: q ? type : null,
        deleted: activeButton === 'deleted' ? true : null,
        active: deactiveButton === true || deactiveButton === false ? deactiveButton : null,
        internalUsers: activeSystem ? true : null,
      },
    };
  }

  static getArray(length) {
    return [...Array(length).keys()];
  }

  getSearchTypes = () => {
    const id = { name: 'Id', value: 'id' };
    const name = { name: 'Name', value: 'name' };
    const email = { name: 'Email', value: 'email' };
    const phone = { name: 'Phone', value: 'phone' };
    const agencyDeveloper = { name: 'Agency/Developer', value: 'agencyOrdeveloper' };
    return [id, name, email, phone, agencyDeveloper];
  };

  clearInput = () => {
    this.inputTitle.value = '';
  };

  deleteUser = (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      this.deactivateUser(userId);
    }
  };

  clearSearch() {
    this.setState(
      {
        setClearButton: 1,
        clearButton: false,
        q: '',
        responseMessage: loadingMsg,
        loading: true,
        users: [],
        activePage: 1,
      },
      () => {
        this.fetchUsers();
      }
    );
  }

  deactivateUser(userId) {
    this.setState({ loading: true });
    axios
      .post(`/api/user/${userId}/delete`)
      .then(() => {
        window.alert('User deleted');
        this.fetchUsers();
      })
      .catch((error) => {
        window.alert(
          `${
            error.response.data === 'Agency found against user !'
              ? 'Unable to delete the User, since agency is found against User'
              : 'Unable to delete the User, since properties are found against User'
          }`
        );
        this.setState({ loading: false });
      });
  }

  createUser(event) {
    event.preventDefault();
    const { loading, user, phoneError } = this.state;
    if (user.accountType === '') {
      user.accountType = null;
    }
    if (user.email === '') {
      user.email = null;
    }
    if (user.phone && user.phone.charAt(0) !== '+' && user.phone.charAt(0) !== '0') {
      user.phone = `+${user.phone}`;
    }
    if (!loading) {
      this.setState({
        loading: true,
      });
      if (user.accountType && user.account && !user.cnic) {
        alert('Please enter CNIC.');
        this.setState({ loading: false });
      } else if (user.phone) {
        if (!phoneError) {
          axios
            .post('/api/user/', user)
            .then((response) => {
              if (response) {
                window.alert('User created successfully');
                window.location.reload();
              }
              this.setState({
                loading: false,
              });
            })
            .catch((error) => {
              if (error.response?.data === 'Email already exists') {
                window.alert('Email already exists');
              } else if (error.response?.data === 'Phone already exists') {
                window.alert('Number already exists');
              }
              this.setState({
                loading: false,
              });
            });
        } else {
          window.alert('Please enter valid phone number.');
          this.setState({ loading: false });
        }
      } else {
        window.alert('Please enter phone number.');
        this.setState({ loading: false });
      }
    }
    this.setState({ isDataChanged: false });
  }

  fetchUsers() {
    const { back, history, userParams, dispatch } = this.props;
    if (
      (back && history.action === 'POP' && userParams) ||
      (history.action !== 'POP' &&
        history.location.state &&
        history.location.state.message === 'submit' &&
        userParams) ||
      (history.action !== 'POP' &&
        history.location.state &&
        history.location.state.message === 'breadcrumbs' &&
        userParams)
    ) {
      if (history.location.state) {
        history.location.state.message = null;
      }
      dispatch({
        type: types.CLEAR_BACK,
      });
      axios.get(this.endPoint, this.getPrevParams()).then((response) => {
        this.saveResponseToState(response);
      });
    } else {
      axios.get(this.endPoint, this.getParams()).then((response) => {
        this.saveResponseToState(response);
      });
    }
  }

  saveResponseToState(response) {
    return this.setState((prevState) => ({
      loading: false,
      users: response.data.items,
      total: response.data.total,
      responseMessage: 'No Users Found',
      pages: Math.ceil(response.data.total / prevState.pageSize),
    }));
  }

  renderSearch() {
    const { type, q } = this.state;
    return (
      <input
        className="form-control"
        type={type === 'id' ? 'number' : 'text'}
        name="search"
        placeholder="Enter "
        value={q}
        onChange={(event) => this.setState({ q: event.target.value, clearButton: false })}
        onKeyPress={(event) => {
          if (event.key === 'Enter' && type && q) {
            this.handleSearch();
          }
        }}
      />
    );
  }

  renderLoading() {
    const { loading } = this.state;
    return <Loading loading={loading} />;
  }

  render() {
    const { userss } = this.props;
    const { user: userState } = this.state;
    const {
      modalVisible,
      loading,
      loadingBigData,
      type,
      q,
      users,
      activePage,
      pages,
      responseMessage,
      pageSize,
      total,
      activeButton,
      deactiveButton,

      focusedValue,
      dateValue,

      isDataChanged,
      activeSystem,
      clearButton,
    } = this.state;
    const searchTypes = this.getSearchTypes();
    return (
      <div className="row animated fadeIn">
        {window.removeEventListener('beforeunload', handleUnload)}
        {isDataChanged && modalVisible
          ? window.addEventListener('beforeunload', handleUnload)
          : window.removeEventListener('beforeunload', handleUnload)}

        <Modal
          size="md"
          isOpen={modalVisible}
          toggle={() => {
            if (
              !isDataChanged ||
              window.confirm(
                'The changes you made will not be saved. Are you sure you want to leave this page?'
              )
            ) {
              this.setState({ modalVisible: false, isDataChanged: false });
            } else {
              this.setState({ modalVisible: true });
            }
          }}
        >
          <ModalHeader
            toggle={() => {
              if (
                !isDataChanged ||
                window.confirm(
                  'The changes you made will not be saved. Are you sure you want to leave this page?'
                )
              ) {
                this.setState({ modalVisible: false, isDataChanged: false });
              } else {
                this.setState({ modalVisible: true });
              }
            }}
          >
            <div className="gray">Sign Up</div>
          </ModalHeader>
          <ModalBody>
            <form
              onSubmit={(event) => {
                this.createUser(event);
              }}
            >
              <div>
                <input
                  className="form-control"
                  style={{ borderColor: '#c2cfd6', borderRadius: '5px' }}
                  type="text"
                  name="first_name"
                  placeholder="Name*"
                  required
                  onChange={this.handleInputChange}
                  pattern="[a-zA-Z][a-zA-Z ]*"
                  title="This field cannot start with an empty space or include a number."
                />
                <input
                  className="form-control"
                  style={{
                    borderWidth: '1px',
                    borderColor: '#c2cfd6',
                    borderRadius: '5px',
                    marginTop: '10px',
                  }}
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  onChange={this.handleInputChange}
                  autoComplete="off"
                />
                <input
                  className="form-control"
                  style={{
                    borderWidth: '1px',
                    borderColor: '#c2cfd6',
                    borderRadius: '5px',
                    marginTop: '10px',
                  }}
                  type="password"
                  name="password"
                  placeholder="Password*"
                  required
                  onChange={this.handleInputChange}
                  autoComplete="new-password"
                />
                <input
                  className="form-control"
                  style={{
                    borderWidth: '1px',
                    borderColor: '#c2cfd6',
                    borderRadius: '5px',
                    marginTop: '10px',
                  }}
                  id="inputTitle"
                  ref={(el) => {
                    this.inputTitle = el;
                  }}
                  type="text"
                  name="cnic"
                  placeholder="CNIC"
                  value={userState.cnic}
                  maxLength={15}
                  pattern="[0-9]{5}-[0-9]{7}-[0-9]{1}"
                  title="Please Enter full CNIC"
                  onChange={this.handleInputChange}
                />
                <h6 style={{ marginTop: '10px' }}>CNIC Issuance Date</h6>
                <SingleDatePicker
                  date={dateValue}
                  onDateChange={(date) => this.onDateChange(date)}
                  focused={focusedValue}
                  onFocusChange={({ focused }) => this.setState({ focusedValue: focused })}
                  id="date_input"
                  numberOfMonths={1}
                  isOutsideRange={() => false}
                  displayFormat="DD-MM-YYYY"
                />
                <h6 style={{ marginTop: '10px' }}>Contact Number*</h6>
                <PhoneInput
                  name="phone"
                  country="pk"
                  countryCodeEditable={false}
                  inputStyle={{ width: '100%', borderColor: '#c2cfd6' }}
                  onChange={(phone) => this.handlePhoneProperty(phone)}
                  // required
                  inputProps={{
                    required: true,
                  }}
                />
                <h6 style={{ marginTop: '10px' }}>Account Type</h6>
                <select
                  name="accountType"
                  className="form-select custom-select"
                  style={{ borderColor: '#c2cfd6', borderRadius: '5px' }}
                  onChange={this.handleInputChange}
                >
                  <option value="">Select...</option>
                  <option value="Jazz">JazzCash</option>
                  <option value="EasyPaisa">EasyPaisa</option>
                </select>
                <h6 style={{ marginTop: '10px' }}>Account Number</h6>
                <PhoneInput
                  name="account"
                  country="pk"
                  inputStyle={{ width: '100%', borderColor: '#c2cfd6' }}
                  enableLongNumbers
                  disableDropdown
                  onChange={(phone) => this.handleJazzCash(phone)}
                  countryCodeEditable={false}
                />
                <label style={{ marginTop: '10px' }}>
                  <input type="checkbox" name="is_propsure_user" onChange={this.handleCheckBox} />{' '}
                  Propsure User
                </label>
                <h6 style={{ marginTop: '10px' }}>Birthday</h6>
                <div className="row space-2">
                  <div className="col-5">
                    <select
                      name="month"
                      className="form-select custom-select"
                      onChange={this.handleInputChange}
                      required
                    >
                      <option value="this.state.user.month">Month</option>
                      {[
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                      ].map((month, index) => (
                        <option key={month} value={index}>
                          {month}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-3 no-padding">
                    <select
                      name="day"
                      className="form-select custom-select"
                      onChange={this.handleInputChange}
                      required
                    >
                      <option value="user.day">Day</option>
                      {UserList.getArray(31).map((val, index) => (
                        <option key={val} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-4">
                    <select
                      name="year"
                      className="form-select custom-select"
                      onChange={this.handleInputChange}
                      required
                    >
                      <option value="this.state.user.year">Year</option>
                      {UserList.getArray(100).map((val, index) => (
                        <option key={val} value={2016 - index}>
                          {2016 - index}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <label>
                  <input type="checkbox" /> I’d like to receive coupons, promotions, surveys, and
                  updates via email about Graana and its partners.
                </label>
                <Button
                  style={{ color: 'white', width: '100%', marginTop: '10px' }}
                  className={`btn btn-block btn-xlg btn-graana-red space-1 ${
                    loading ? 'disabled' : ''
                  }`}
                >
                  <i className={`fa fa-spinner fa-pulse ${loading ? '' : 'd-none'}`} /> Sign up
                </Button>
              </div>
            </form>
          </ModalBody>
        </Modal>
        <div className="col-12">
          <div className="row space-1">
            <SearchBar
              type={type}
              searchTypes={searchTypes}
              q={q}
              clearButton={clearButton}
              handleSearchType={(value) => this.handleSearchType(value)}
              renderSearch={() => this.renderSearch()}
              handleSearch={() => this.handleSearch()}
              clearSearch={() => this.clearSearch()}
            />
            <div className="col-sm-8 text-end">
              <CanAdd>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    this.setState({ modalVisible: true });
                  }}
                >
                  Add new User
                </button>
              </CanAdd>
            </div>
          </div>
          <div className="row space-1">
            <div className="col-sm-6">
              <button
                type="button"
                style={{
                  marginRight: 5,
                  marginBottom: 5,
                }}
                className={`${deactiveButton === true ? btnPrimary : ''} btn btn-default`}
                onClick={() => this.handleActive(true)}
              >
                Active
              </button>
              <button
                type="button"
                style={{
                  marginRight: 5,
                  marginBottom: 5,
                }}
                className={`${deactiveButton === false ? btnPrimary : ''} btn btn-default`}
                onClick={() => this.handleActive(false)}
              >
                Deactivated
              </button>
              {userss.superadmin ? (
                <button
                  type="button"
                  style={{
                    marginRight: 5,
                    marginBottom: 5,
                  }}
                  className={`${activeButton === 'deleted' ? btnPrimary : ''} btn btn-default`}
                  onClick={() => this.handleFilter('deleted')}
                >
                  Deleted
                </button>
              ) : null}
              <button
                type="button"
                style={{ marginRight: 5, marginBottom: 5 }}
                className={`${activeSystem ? btnPrimary : ''} btn btn-default`}
                onClick={() => this.handleSystemUser()}
              >
                System Users
              </button>
            </div>
            <div className="col-12 text-center" style={{ marginTop: '15px' }}>
              <div className="alert alert-primary" role="alert">
                Showing all users of Graana.com Web/Mobile Responsive and Graana App.
              </div>
            </div>
          </div>
          {this.renderLoading()}
          {total ? (
            <div className="text-end row-sm-4" style={{ marginBottom: 5 }}>
              Showing {(activePage - 1) * pageSize + 1} -{' '}
              {activePage * pageSize >= total ? total : activePage * pageSize} / {total}
            </div>
          ) : null}
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th> </th>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Type</th>
                  <th>Agency</th>
                  <th>Developer</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {users && users.length >= 1 ? (
                  users.map((user, index) => {
                    const rowData = usersRowData(user, index);
                    this.dataToExport.push(rowData);
                    return (
                      <tr
                        key={user.id}
                        className={`${
                          user.agency && user.agency.developer !== true && user.agency.deleted_at
                            ? 'disabled'
                            : ''
                        } ${user.infoChanged ? 'user-disabled' : ''}`}
                      >
                        <td style={{ textAlign: 'center' }}>
                          {user.cnic &&
                          user.accountType &&
                          user.account &&
                          user.cnicIssuanceDate &&
                          user.account.length > 11 ? (
                            <>
                              <img
                                className={user.accountType === 'Jazz' ? 'jazz-cash' : 'easy-paisa'}
                                src={
                                  user.accountType === 'Jazz'
                                    ? '/img/jazzcash.png'
                                    : '/img/easypaisa.png'
                                }
                                alt="jazz_user"
                                id={`eligible${user.id}`}
                              />
                              <UncontrolledTooltip placement="bottom" target={`eligible${user.id}`}>
                                Eligible for reward program
                              </UncontrolledTooltip>
                            </>
                          ) : null}
                        </td>
                        <td>{rowData.user_id}</td>
                        <td>
                          {rowData.first_name} {rowData.last_name ? rowData.last_name : ''}
                        </td>
                        <td>
                          {(user.newEmail || rowData?.email) &&
                            (user.newEmail ? ellipsis(user.newEmail) : ellipsis(rowData?.email))}
                        </td>
                        <td>{user.newPhone ? user.newPhone : rowData.phone}</td>
                        <td>
                          {user.system_user && user.system_user.webUserId
                            ? 'Internal User'
                            : 'External User'}
                        </td>
                        <td>
                          {user.agency && user.agency.developer === true
                            ? null
                            : `${rowData.agency} `}
                          {user.agency &&
                          user.agency.developer !== true &&
                          user.agency.deleted_at ? (
                            <strong>
                              <em>(Disabled)</em>
                            </strong>
                          ) : (
                            ''
                          )}
                        </td>
                        <td>
                          {user.agency && user.agency.developer === true ? rowData.agency : null}
                        </td>
                        <td>{moment(user.createdAt).format('YYYY-MM-DD')}</td>
                        {((user.system_user &&
                          user.system_user.webUserId &&
                          (userss.superadmin || userss.department_id === 1)) ||
                          !user.system_user) &&
                        activeButton !== 'deleted' ? (
                          <CanEdit>
                            <td>
                              {user.agency &&
                              user.agency.developer !== true &&
                              user.agency.deleted_at ? null : (
                                <Link to={`/graana-users/edit-user/${user.id}`}>
                                  <span className="fa fa-edit" title="Edit" aria-hidden="true" />
                                </Link>
                              )}
                            </td>
                          </CanEdit>
                        ) : null}
                        <CanView>
                          <td>
                            <Link to={`/graana-users/user-details/${user.id}`}>
                              <span className="fa fa-tasks" title="Details" aria-hidden="true" />
                            </Link>
                          </td>
                        </CanView>
                        {userss.superadmin && activeButton !== 'deleted' ? (
                          <CanDelete>
                            <td>
                              <span
                                onClick={() => {
                                  this.deleteUser(user.id);
                                }}
                                className="fa fa-trash"
                                title="Delete"
                                style={{ cursor: 'pointer' }}
                                aria-hidden="true"
                              />
                            </td>
                          </CanDelete>
                        ) : null}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="15" className="text-center">
                      {responseMessage}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="text-center">
            <ReactPaginate
              previousLabel="‹"
              nextLabel="›"
              forcePage={activePage - 1}
              breakLabel={
                <span
                  role="presentation"
                  className="customPage"
                  tabIndex="-1"
                  onClick={(e) => e.preventDefault()}
                >
                  ...
                </span>
              }
              breakClassName="break-me"
              pageCount={pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageSelect}
              containerClassName="pagination"
              subContainerClassName="pages pagination"
              activeClassName="active"
            />
            {userss && userss.superadmin ? (
              <button type="button" className="download-all btn btn-success" onClick={this.getCSV}>
                Download All&nbsp;
                <i className={`fa ${loadingBigData ? 'fa-spinner fa-pulse red' : 'fa-download'}`} />
              </button>
            ) : null}
            <select
              name="pageSize"
              className="form-select custom-select pagination-page-size"
              onChange={(event) => this.handlePageSize(event)}
              required
              value={pageSize}
            >
              {PAGE_SIZE_OPTIONS.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
}
UserList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  userParams: PropTypes.instanceOf(Object).isRequired,
  back: PropTypes.instanceOf(Object).isRequired,
  userss: PropTypes.instanceOf(Object).isRequired,
};
const mapStateToProps = (state) => ({
  userParams: state.userParams.userParams,
  back: state.back.back,
  userss: state.user.user,
});

export default connect(mapStateToProps)(UserList);
