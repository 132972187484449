/** @format */

import { setRoute } from '../common/actions';

const areaGroupFormPath = '/area-groups/area-group-form';
const orderActivities = 'Order Activities';
const pkgDetails = 'Package Details';
const activityDetails = 'Activity Details';

const routes = [
  setRoute(
    'Lead Shift Management Form',
    '/lead-shift-management/lead-shift-management-form',
    '/lead-shift-management/lead-shift-management-form'
  ),
  setRoute('Guru Management', '/guru-management', '/guru-management'),
  setRoute(
    'Add Graana Guru',
    '/guru-management/add-graana-guru',
    '/guru-management/add-graana-guru'
  ),
  setRoute(
    'Edit Graana Guru',
    '/guru-management/edit-graana-guru',
    '/guru-management/edit-graana-guru/:guruId'
  ),
  setRoute('Advanced Projects', '/advanced-projects', '/advanced-projects'),
  setRoute('Add Project Form', '/advanced-projects/add-project-form', '/add-project-form'),
  setRoute(
    'Edit Project Form',
    '/advanced-projects/edit-project-form',
    '/advanced-projects/edit-project-form/:projectId'
  ),
  setRoute(
    'Project Listings',
    '/advanced-projects/project-listings',
    '/advanced-projects/project-listings/:projectId'
  ),
  setRoute('Project Priority', '/projects/set-project-priority', '/projects/set-project-priority'),
  setRoute(
    'Add Listing Form',
    '/advanced-projects/project-listings/add-listing-form',
    '/advanced-projects/project-listings/add-listing-form/:projectId'
  ),
  setRoute(
    'Edit Listing Form',
    '/advanced-projects/project-listings/edit-listing-form',
    '/advanced-projects/project-listings/edit-listing-form/:projectId/:listingId'
  ),
  setRoute(
    'Listing Details',
    '/advanced-projects/project-listings/listing-details',
    '/advanced-projects/project-listings/listing-details/:projectId/:listingId'
  ),
  setRoute(
    'Project Details',
    '/advanced-projects/project-details',
    '/advanced-projects/project-details/:projectId'
  ),
  setRoute('Lead Shift Management', '/lead-shift-management', '/lead-shift-management'),
  setRoute('Geotagged Properties', '/geotagged-properties', '/geotagged-properties'),
  setRoute('Area Groups', '/area-groups', '/area-groups'),
  setRoute('Add Area Group', areaGroupFormPath, '/area-groups/area-group-form'),
  setRoute('Edit Area Group', areaGroupFormPath, '/area-groups/area-group-form/:areaGroupId'),
  setRoute('Areas', '/areas', '/areas'),
  setRoute('Search Page SEO', '/seo-areas', '/seo-areas'),
  setRoute('SEO Description', '/seo-areas/description', '/seo-areas/description/:areaId'),
  setRoute('Regions', '/regions', '/regions'),
  setRoute('Edit Region', '/regions/regionsEdit', '/regions/regionsEdit/:region_id'),
  setRoute('Zones', '/zones', '/zones'),
  setRoute('Edit Zone', '/zones/zonesEdit', '/zones/zonesEdit/:zone_id'),
  setRoute('Add Area Form', '/areas/area-form', '/areas/area-form'),
  setRoute('Area Resource', '/areas/area-resource', '/areas/area-resource/:areaId'),
  setRoute('Edit Area Form', '/areas/edit-area', '/areas/edit-area/:areaId'),
  setRoute(
    'Edit Area Form',
    '/cataloging/edit-property/edit-area',
    '/cataloging/edit-property/edit-area/:areaId'
  ),
  setRoute(
    'Edit User Form',
    '/cataloging/add-property/edit-user',
    '/cataloging/add-property/edit-user/:userId'
  ),
  setRoute('Area Images Bank', '/areas/area-images-bank', '/areas/area-images-bank/:areaId'),
  setRoute('Area Amenities', '/areas/amenities', '/areas/amenities/:areaId'),
  setRoute('Areas Media', '/AreasMedia', '/AreasMedia'),
  setRoute('Add Area Form', '/AreasMedia/area-form', '/AreasMedia/area-form'),
  setRoute('Area Resource', '/AreasMedia/area-resource', '/AreasMedia/area-resource/:areaId'),
  setRoute('Edit Area Form', '/AreasMedia/edit-area', '/AreasMedia/edit-area/:areaId'),
  setRoute('Area Amenities', '/AreasMedia/amenities', '/AreasMedia/amenities/:areaId'),
  setRoute(
    'Area Images Bank',
    '/AreasMedia/area-images-bank',
    '/AreasMedia/area-images-bank/:areaId'
  ),
  setRoute('Cities', '/cities', '/cities'),
  setRoute('City Search Page SEO', '/seo-cities', '/seo-cities'),
  setRoute('City SEO Description', '/seo-cities/description', '/seo-cities/description/:cityId'),
  setRoute('Add City Form', '/cities/city-form', '/cities/city-form'),
  setRoute('City Resource', '/cities/city-resource', '/cities/city-resource/:cityId'),
  setRoute('Edit City Form', '/cities/edit-city', '/cities/edit-city/:cityId'),
  setRoute('Media Requests', '/media-requests', '/media-requests'),
  setRoute(
    'Edit Media Requests',
    '/media-requests/edit-property',
    '/media-requests/edit-property/:propertyId'
  ),
  setRoute(
    'Edit Standard Request',
    '/media-requests/edit-standard',
    '/media-requests/edit-standard/:propertyId'
  ),
  setRoute(
    'Edit Premium Request',
    '/media-requests/edit-premium',
    '/media-requests/edit-premium/:propertyId'
  ),
  setRoute(
    'Edit Premium Plus Request',
    '/media-requests/edit-premium-plus',
    '/media-requests/edit-premium-plus/:propertyId'
  ),
  setRoute('OPVS Requests', '/opvs-requests', '/opvs-requests'),
  setRoute('Credit Requests', '/credit-requests', '/credit-requests'),
  setRoute(
    'Credit Request Details',
    '/credit-requests/credit-request-detail',
    '/credit-requests/credit-request-detail/:id'
  ),
  setRoute('Assign Credit', '/credit-requests/assign-credit', '/credit-requests/assign-credit/:id'),
  setRoute(
    'Reject Credit Request',
    '/credit-requests/reject-credit-request',
    '/credit-requests/reject-credit-request/:id'
  ),
  setRoute('Projects', '/projects', '/projects'),
  setRoute('Meta Info', '/projects/meta-info', '/projects/meta-info'),
  setRoute(
    'Listings Meta Info',
    '/projects/meta-info/meta-info-listings',
    '/projects/meta-info/meta-info-listings/:projectId'
  ),
  setRoute(
    'Add Meta Info',
    '/projects/meta-info/add-meta-info',
    '/projects/meta-info/add-meta-info'
  ),
  setRoute(
    'Add Listings Meta Info',
    '/projects/meta-info/meta-info-listings/add-meta-info-listings',
    '/projects/meta-info/meta-info-listings/add-meta-info-listings/:listingId/:projectId'
  ),
  setRoute(
    'Edit Listings Meta Info',
    '/projects/meta-info/meta-info-listings/edit-meta-info-listings',
    '/projects/meta-info/meta-info-listings/edit-meta-info-listings/:listingId/:projectId'
  ),
  setRoute(
    'Add Project Meta Info',
    '/projects/meta-info/add-project-meta-info',
    '/projects/meta-info/add-project-meta-info/:projectId'
  ),
  setRoute(
    'Edit Project Meta Info',
    '/projects/meta-info/edit-project-meta-info',
    '/projects/meta-info/edit-project-meta-info/:projectId'
  ),
  setRoute('Add Project Form', '/projects/project-form', '/projects/project-form'),
  setRoute('Edit Project Form', '/projects/edit-project', '/projects/edit-project/:projectId'),
  setRoute('Area Partners', '/projects/area-partners', '/projects/area-partners/:areaId'),
  setRoute('Project Resource', '/projects/area-resource', '/projects/area-resource/:areaId'),
  setRoute('Listings Form', '/projects/listing-form', '/projects/listing-form/:projectId'),
  setRoute(
    'Listings Amenities',
    '/projects/listing-amenities',
    '/projects/listing-amenities/:projectListingId'
  ),
  setRoute(
    'Listings Resources',
    '/projects/listing-resources',
    '/projects/listing-resources/:projectListingId'
  ),
  setRoute(
    'Project Listing Edit',
    '/projects/project-listing-edit',
    '/projects/project-listing-edit/:projectId/:projectListingId'
  ),
  setRoute(
    'Booking Detail',
    '/project-booking/booking-detail',
    '/project-booking/booking-detail/:bookingId'
  ),
  setRoute('Agencies Management', '/agencies-management', '/agencies-management'),
  setRoute(
    'Add Agency Form',
    '/agencies-management/agency-form',
    '/agencies-management/agency-form'
  ),
  setRoute(
    'Edit Agency Form',
    '/agencies-management/edit-agency',
    '/agencies-management/edit-agency/:agencyId'
  ),
  setRoute(
    'Agency Credit',
    '/agencies-management/agency-credit',
    '/agencies-management/agency-credit/:agencyId'
  ),
  setRoute(
    'Agency Details',
    '/agencies-management/agency-details',
    '/agencies-management/agency-details/:agencyId'
  ),
  setRoute(
    'Agency Agents',
    '/agencies-management/agency-agents',
    '/agencies-management/agency-agents/:agencyId'
  ),
  setRoute(
    'User Contact Info',
    '/graana-users/edit-user/user-contact-info',
    '/graana-users/edit-user/user-contact-info/:userId'
  ),
  setRoute('ROI Offers', '/projects/roi-offers', '/projects/roi-offers'),
  setRoute('Add Offer', '/projects/roi-offers/addOffer', '/projects/roi-offers/addOffer'),
  setRoute(
    'Edit Offer',
    '/projects/roi-offers/edit-offer',
    '/projects/roi-offers/edit-offer/:offerId'
  ),
  setRoute('Fresh Listings', '/fresh-listings', '/fresh-listings'),
  setRoute(
    'Add Fresh Listing',
    '/fresh-listings/add-fresh-listing',
    '/fresh-listings/add-fresh-listing'
  ),
  setRoute(
    'Agency Wanted',
    '/agencies-management/agency-wanted',
    '/agencies-management/agency-wanted/:agencyId'
  ),
  setRoute('Wanted Voice Leads', '/wanted-voice-leads', '/wanted-voice-leads'),
  setRoute(
    'Create Wanted Form',
    '/wanted-voice-leads/voice-lead-form',
    '/wanted-voice-leads/voice-lead-form/:leadId'
  ),
  setRoute(
    'Edit Wanted Form',
    '/wanted-voice-leads/edit-voice-lead-form',
    '/wanted-voice-leads/edit-voice-lead-form/:propertyId'
  ),
  setRoute('Invest Leads (Wanted)', '/invest-leads-wanted', '/invest-leads-wanted'),
  setRoute('Developers Management', '/developers-management', '/developers-management'),
  setRoute(
    'Add Developer Form',
    '/developers-management/developer-form',
    '/developers-management/developer-form'
  ),
  setRoute(
    'Edit Developer Form',
    '/developers-management/edit-developer',
    '/developers-management/edit-developer/:developerId'
  ),
  setRoute('Cataloging', '/cataloging', '/cataloging'),
  setRoute(
    'Sell/Rentout Properties',
    '/cataloging/sell-rentout-properties',
    '/cataloging/sell-rentout-properties'
  ),
  // setRoute('Add Properties', '/cataloging/AddProperties', '/cataloging/AddProperties'),

  setRoute('Property Form', '/cataloging/add-property', '/cataloging/add-property'),
  setRoute('Dispute', '/dispute', '/dispute'),
  setRoute('Dispute Details', '/dispute/dispute-details', '/dispute/dispute-details/:disputeId'),
  setRoute(
    'Edit Property Form',
    '/cataloging/edit-property',
    '/cataloging/edit-property/:propertyId'
  ),
  setRoute('Add Property Form', '/wanted/add-property', '/wanted/add-property/:wantedId'),
  setRoute(
    'Add Sell/Rentout Properties',
    '/wanted/AddProperties',
    '/wanted/AddProperties/:wantedId'
  ),
  setRoute(
    'Matched Properties',
    '/wanted/edit-property/match-properties',
    '/wanted/edit-property/match-properties/:wantedId'
  ),
  setRoute('Saved Properties', '/wanted/saved-properties', '/wanted/saved-properties/:wantedId'),
  // setRoute(
  //   'Edit Property Form',
  //   '/cataloging/edit-property',
  //   '/cataloging/edit-property/:propertyId'
  // ),
  setRoute(
    'Edit Property Form',
    '/wanted/wanted-edit-property',
    '/wanted/wanted-edit-property/:propertyId'
  ),
  setRoute(
    'Property Details',
    '/cataloging/property-details',
    '/cataloging/property-details/:propertyId'
  ),
  setRoute(
    'Reject Premium Plus Requests',
    '/premium-plus-requests/reject-premium-request',
    '/premium-plus-requests/reject-premium-request/:id'
  ),
  setRoute(
    'Reject Premium Requests',
    '/premium-requests/reject-premium-request',
    '/premium-requests/reject-premium-request/:id'
  ),
  setRoute('Graana Users', '/graana-users', '/graana-users'),
  setRoute('Edit User Form', '/graana-users/edit-user', '/graana-users/edit-user/:userId'),
  setRoute('User Details', '/graana-users/user-details', '/graana-users/user-details/:userId'),
  setRoute('User Credit', '/graana-users/user-credit', '/graana-users/user-credit/:userId'),
  setRoute('Featured / Mega', '/featured-mega', '/featured-mega'),
  setRoute(
    'Featured Agencies',
    '/featured-mega/featured-agencies',
    '/featured-mega/featured-agencies'
  ),
  setRoute(
    'Featured Developers',
    '/featured-mega/featured-developers',
    '/featured-mega/featured-developers'
  ),
  setRoute(
    'Featured Projects',
    '/featured-mega/featured-projects',
    '/featured-mega/featured-projects'
  ),
  setRoute('Mega Projects', '/featured-mega/mega-projects', '/featured-mega/mega-projects'),
  setRoute('Cover / Footer Ads', '/cover-footer-ads', '/cover-footer-ads'),
  setRoute(
    'Edit Cover / Footer Ad Form',
    '/cover-footer-ads/edit-cover-footer-ads',
    '/cover-footer-ads/edit-cover-footer-ads/:coverAndFooterId'
  ),
  setRoute(
    'Add Cover / Footer Ad Form',
    '/cover-footer-ads/cover-footer-ad-form',
    '/cover-footer-ads/cover-footer-ad-form'
  ),
  setRoute('Wanted', '/wanted', '/wanted'),
  setRoute('Careers CVs', '/careers-cvs', '/careers-cvs'),
  setRoute('Jobs Dashboard', '/jobs-dashboard', '/jobs-dashboard'),
  setRoute('Add Job', '/jobs-dashboard/add-job', '/jobs-dashboard/add-job'),
  setRoute('Edit Job ', '/jobs-dashboard/edit-job', '/jobs-dashboard/edit-job/:jobId'),
  setRoute('Activity Logs', '/activity-logs', '/activity-logs'),
  setRoute('Cataloger Logs', '/cataloger-logs', '/cataloger-logs'),
  setRoute('Field Force Logs', '/field-force-logs', '/field-force-logs'),
  setRoute('Customers Management', '/customers-management', '/customers-management'),
  setRoute(
    'Add Customer',
    '/customers-management/add-customer',
    '/customers-management/add-customer'
  ),
  setRoute(
    'Edit Customer',
    '/customers-management/edit-customer',
    '/customers-management/edit-customer/:customerId'
  ),
  setRoute('Push Notification Form', '/push-notification-form', '/push-notification-form'),
  setRoute('Edit Wanted Lead Form', '/wanted/edit-property', '/wanted/edit-property/:propertyId'),
  setRoute('Edit Invest Lead Form', '/wanted/edit-invest', '/wanted/edit-invest/:propertyId'),
  setRoute('Properties Media', '/properties-media', '/properties-media'),
  setRoute(
    'Edit Premium Plus Details',
    '/properties-media/edit-premium-plus',
    '/properties-media/edit-premium-plus/:propertyId'
  ),
  setRoute(
    'Edit Premium Details',
    '/properties-media/edit-premium',
    '/properties-media/edit-premium/:propertyId'
  ),
  setRoute(
    'Edit Standard Details',
    '/properties-media/edit-standard',
    '/properties-media/edit-standard/:propertyId'
  ),
  setRoute('Dashboard', '/dashboard', '/dashboard'),
  setRoute('Listings in Zone', '/zonal-dashboard', '/zonal-dashboard'),
  setRoute(orderActivities, '/dashboard/activities', '/dashboard/activities/:department'),
  setRoute(orderActivities, '/mop-dashboard/activities', '/mop-dashboard/activities/:department'),
  setRoute(
    orderActivities,
    '/marketing-dashboard/activities',
    '/marketing-dashboard/activities/:department'
  ),
  setRoute(
    pkgDetails,
    '/dashboard/package-detail',
    '/dashboard/package-detail/:department/:packageId'
  ),
  setRoute(
    pkgDetails,
    '/mop-dashboard/package-detail',
    '/mop-dashboard/package-detail/:department/:packageId'
  ),
  setRoute(
    pkgDetails,
    '/marketing-dashboard/package-detail',
    '/marketing-dashboard/package-detail/:department/:packageId'
  ),
  setRoute(
    activityDetails,
    '/dashboard/activity-details',
    '/dashboard/activity-details/:department/:activityId'
  ),
  setRoute(
    activityDetails,
    '/mop-dashboard/activity-details',
    '/mop-dashboard/activity-details/:department/:activityId'
  ),
  setRoute(
    activityDetails,
    '/marketing-dashboard/activity-details',
    '/marketing-dashboard/activity-details/:department/:activityId'
  ),
  setRoute('Inquiries', '/inquiries', '/inquiries'),
  setRoute(' Users', '/users', '/users'),
  setRoute(' Edit System User ', '/users/edit-system-user', '/users/edit-system-user/:userId'),
  setRoute(' Add System User', '/users/add-system-user', '/users/add-system-user'),
  setRoute('System Users Profile Form', '/users/edit-profile', '/users/edit-profile/:userId'),
  setRoute('Field Force Management', '/field-force-management', '/field-force-management'),
  setRoute('Add Rider', '/users/add-rider', '/users/add-rider'),
  setRoute('Edit Rider', '/users/edit-rider', '/users/edit-rider/:userId'),
  setRoute('Feedback', '/feedback', '/feedback'),
  setRoute('Complaints', '/complaints', '/complaints'),
  setRoute('Landing Page Config', '/projects/landing-page-config', '/projects/landing-page-config'),
  setRoute('Project Approvers', '/projects/approvers', '/projects/approvers'),
  setRoute(
    'Complaints Details',
    '/complaints/complaints-details',
    '/complaints/complaints-details/:complaintId'
  ),
  setRoute('Permission Groups', '/permission-groups', '/permission-groups'),
  setRoute(
    'Add Department Form',
    '/permission-groups/department-form',
    '/permission-groups/department-form'
  ),
  setRoute(
    'Edit Department Form',
    '/permission-groups/edit-department',
    '/permission-groups/edit-department/:departmentId'
  ),
  setRoute('Buying Guide', '/areas/buying-guide', '/areas/buying-guide/:areaId'),
  setRoute('Price Comparison', '/areas/comparison-price', '/areas/comparison-price/:areaId'),
  setRoute('Tertiary', '/areas/tartairy', '/areas/tartairy/:areaId'),
  setRoute('QA Checklist', '/qa-list', '/qa-list'),
  setRoute('QA Form', '/qa-list/qa-form', '/qa-list/qa-form/:listId'),
  setRoute('QA Users', '/qa-users', '/qa-users'),
  setRoute('QA Edit', '/qa-users/qa-edit', '/qa-users/qa-edit/:userId'),

  setRoute('Add Leads', '/wanted/addLead', '/wanted/addLead'),
  setRoute('Propsure Properties', '/propsure-properties', '/propsure-properties'),
  setRoute(
    'Edit Propsure Property',
    '/propsure-properties/propsure-property',
    '/propsure-properties/propsure-property/:propsurePropertyId '
  ),
  setRoute(
    'Propsure Properties Media',
    '/propsure-properties-media/propsure-media',
    '/propsure-properties-media/propsure-media/:proeprtyId'
  ),
  setRoute('RWR Management', '/rwr-management', '/rwr-management'),
  setRoute('Edit Lead Form', '/rwr-management/edit-lead', '/rwr-management/edit-lead/:propertyId'),
];
export default routes;
